import React from 'react';
import { useParams } from 'react-router-dom';

const Lockbox = () => {
  const { LockboxId } = useParams();

  return (
    <div className="Lockbox-container">
      <h1>Lockbox ID: {LockboxId}</h1>
    </div>
  );
};

export default Lockbox;
