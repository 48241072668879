import React from 'react';

const LeaseSummary = ({ lease }) => {
  return (
    <div className="lease-summary">
      <h2>Lease Summary</h2>
      <p><strong>Lease Start Date:</strong> {new Date(lease.LeaseStartDate).toLocaleDateString()}</p>
      <p><strong>Lease End Date:</strong> {new Date(lease.LeaseEndDate).toLocaleDateString()}</p>
      <p><strong>Lease Type:</strong> {lease.LeaseType}</p>
      <p><strong>Notes:</strong> {lease.Notes || 'None'}</p>
    </div>
  );
};

export default LeaseSummary;
