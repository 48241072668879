import React from 'react';
import { useParams } from 'react-router-dom';

const EditApplicationTemplate = () => {
  const { EditApplicationTemplateId } = useParams();

  return (
    <div className="EditApplicationTemplate-container">
      <h1>EditApplicationTemplate ID: {EditApplicationTemplateId}</h1>
    </div>
  );
};

export default EditApplicationTemplate;
