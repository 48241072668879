import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI"; // Use secureFetch for API calls
import AddInvestorForm from "./AddInvestorForm";
import './AddInvestor.css';

const AddInvestor = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      // Construct the payload
      const payload = {
        FirstName: formData.FirstName || null,
        LastName: formData.LastName || null,
        IsCompany: formData.IsCompany,
        CompanyName: formData.CompanyName || '',
        PhoneNumber: formData.PhoneNumber || '',
        Email: formData.Email || '',
        AgreementStartDate: formData.AgreementStartDate || null,
        AgreementEndDate: formData.AgreementEndDate || null,
        TIN: formData.TIN || '',
        AddressLine1: formData.AddressLine1 || '',
        AddressLine2: formData.AddressLine2 || '',
        City: formData.City || '',
        State: formData.State || '',
        ZipCode: formData.ZipCode || '',
        Country: formData.Country || '',
        RealtorId: formData.RealtorId ? parseInt(formData.RealtorId) : null,
        AccountantId: formData.AccountantId ? parseInt(formData.AccountantId) : null,
        AttorneyId: formData.AttorneyId ? parseInt(formData.AttorneyId) : null,
        DateOfBirth: formData.DateOfBirth || null,
        Notes: formData.Notes || ''
      };

      // Make a POST request using secureFetch
      const response = await secureFetch('POST', API_ENDPOINTS.RentalInvestorAddInvestor, payload);

      if (response.error) {
        // Assuming secureFetch returns an object with error handling
        throw new Error(response.error);
      }

      // If response contains the investor data
      if (response.Id) {
        setSuccess('Investor added successfully!');
        navigate(`/rental/investor/${response.Id}`); // Redirect to the new investor page
      } else {
        throw new Error('Failed to add investor. No ID returned.');
      }
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-investor">
      <h1>Add Investor</h1>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <AddInvestorForm onSubmit={handleSubmit} loading={loading} />
    </div>
  );
};

export default AddInvestor;
