import React from 'react';
import { useParams } from 'react-router-dom';

const LeaseRenewals = () => {
  const { LeaseRenewalsId } = useParams();

  return (
    <div className="LeaseRenewals-container">
      <h1>LeaseRenewals ID: {LeaseRenewalsId}</h1>
    </div>
  );
};

export default LeaseRenewals;
