import React from 'react';
import { useParams } from 'react-router-dom';

const Reconciliation = () => {
  const { ReconciliationId } = useParams();

  return (
    <div className="Reconciliation-container">
      <h1>Reconciliation ID: {ReconciliationId}</h1>
    </div>
  );
};

export default Reconciliation;
