import React from 'react';
import { useParams } from 'react-router-dom';

const AddListing = () => {
  const { AddListingId } = useParams();

  return (
    <div className="AddListing-container">
      <h1>AddListing ID: {AddListingId}</h1>
    </div>
  );
};

export default AddListing;
