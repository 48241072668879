import React from 'react';
import { useParams } from 'react-router-dom';

const Projects = () => {
  const { ProjectsId } = useParams();

  return (
    <div className="Projects-container">
      <h1>Projects ID: {ProjectsId}</h1>
    </div>
  );
};

export default Projects;
