import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import BudgetsTable from "./BudgetsTable";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";
import './Budgets.css'; // Import the CSS file for styling
import { Button } from '@mui/material'; // Import Material UI components

const Budgets = () => {
  const [budgets, setBudgets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchBudgets = async () => {
      try {
        // Fetch data using secureFetch
        const response = await secureFetch('GET', API_ENDPOINTS.FinanceBudgetBudgets);
        const parsedData = Array.isArray(response) ? response : JSON.parse(response.body);

        if (Array.isArray(parsedData)) {
          setBudgets(parsedData);
        } else {
          console.error("Unexpected response format:", parsedData);
        }
      } catch (err) {
        console.error("Error fetching budgets:", err);
        setError("Failed to load budgets data.");
      } finally {
        setLoading(false);
      }
    };

    fetchBudgets();
  }, []);

  const handleAddBudget = () => {
    navigate('/finance/budget/add'); // Redirect to the AddBudget page
  };

  return (
    <div className="budgets-container">
      <div className="budgets-header">
        <h1>Budgets</h1>
        <Button variant="contained" color="primary" size='large' onClick={handleAddBudget}>
          Add Budget
        </Button>
      </div>

      {loading ? (
        <p>Loading budgets data...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <BudgetsTable budgets={budgets} />
      )}
    </div>
  );
};

export default Budgets;
