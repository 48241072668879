import React, { useEffect, useState } from "react";
import OwnershipAccountTable from "./OwnershipAccountTable";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";
import './OwnershipAccounts.css'; // Import the CSS file for styling

const OwnershipAccounts = () => {
  const [ownershipAccounts, setOwnershipAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOwnershipAccounts = async () => {
      try {
        // Fetch data using secureFetch
        const response = await secureFetch('GET', API_ENDPOINTS.AssociationOwnershipAccountOwnershipAccounts);
        const parsedData = Array.isArray(response) ? response : JSON.parse(response.body);

        if (Array.isArray(parsedData)) {
          setOwnershipAccounts(parsedData);
        } else {
          console.error("Unexpected response format:", parsedData);
        }
      } catch (err) {
        console.error("Error fetching ownership accounts:", err);
        setError("Failed to load ownership account data.");
      } finally {
        setLoading(false);
      }
    };

    fetchOwnershipAccounts();
  }, []);

  return (
    <div>
      <h1>Ownership Accounts</h1>

      {loading ? (
        <p>Loading ownership account data...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <OwnershipAccountTable ownershipAccounts={ownershipAccounts} />
      )}
    </div>
  );
};

export default OwnershipAccounts;
