import React from 'react';
import { useParams } from 'react-router-dom';
import './Budget.css';

const Budget = () => {
  const { budgetId } = useParams();

  return (
    <div className="Budget-container">
      <h1>Budget ID: {budgetId}</h1>
    </div>
  );
};

export default Budget;
