import React from 'react';
import { useParams } from 'react-router-dom';

const ServiceArea = () => {
  const { ServiceAreaId } = useParams();

  return (
    <div className="ServiceArea-container">
      <h1>ServiceArea ID: {ServiceAreaId}</h1>
    </div>
  );
};

export default ServiceArea;
