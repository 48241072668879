import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import API_ENDPOINTS from "../../../../APIEndPointURL"; // Import the API endpoints
import AddBankAccountForm from "./AddBankAccountForm"; // Separate form fields into AddBankAccountForm.js
import { secureFetch } from '../../../../AuthorizeAPI'; // Import secureFetch for authenticated API requests
import './AddBankAccount.css'; // Import the CSS file for styling

const AddBankAccount = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const navigate = useNavigate(); // Initialize navigate

  // Handle form submission
  const handleSubmit = async (formData) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      // Include IsActive set to true in the payload
      const payload = { ...formData, IsActive: true };

      // Use secureFetch to make the POST request to add a bank account
      const response = await secureFetch('POST', API_ENDPOINTS.FinanceBankAccountAddBankAccount, payload);

      if (!response.Id) {
        throw new Error('Failed to add bank account');
      }

      setSuccess("Bank account added successfully!");

      // Redirect the user to the new bank account page using the id from response
      navigate(`/finance/bank-account/${response.Id}`);

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-bank-account">
      <h1>Add Bank Account</h1>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <AddBankAccountForm onSubmit={handleSubmit} loading={loading} />
    </div>
  );
};

export default AddBankAccount;
