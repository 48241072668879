import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import Box from '@mui/material/Box'; // Import MUI Box for containers
import Table from '@mui/material/Table'; // Import MUI Table components
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const PropertyCases = ({ cases }) => {
  const theme = useTheme(); // Access the theme

  return (
    <Box sx={theme.containerSizes.xlarge}> {/* Apply the small container */}
      <h3>Property Cases</h3>
      {cases.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Case Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cases.map((caseItem) => (
              <TableRow key={caseItem.Id}>
                <TableCell>
                  <Link to={`/case/${caseItem.Id}`}>
                    {caseItem.Title}
                  </Link>
                </TableCell>
                <TableCell>{caseItem.Description}</TableCell>
                <TableCell>{caseItem.Status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p>No cases available for this property.</p>
      )}
    </Box>
  );
};

export default PropertyCases;
