import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import PropertyTable from "./PropertyTable";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";
import './Properties.css'; // Import the CSS file for styling
import { Button } from '@mui/material'; // Import Material UI components

const Properties = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await secureFetch('GET', API_ENDPOINTS.RentalPropertyProperties);

        // Directly set the response as the properties since it is already an array
        if (Array.isArray(response)) {
          setProperties(response);
        } else {
          console.error("Unexpected response format:", response);
          throw new Error("Invalid data format received.");
        }
      } catch (err) {
        console.error("Error fetching properties:", err);
        setError("Failed to load properties data.");
      } finally {
        setLoading(false); // Ensure loading state is updated
      }
    };

    fetchProperties(); // Fetch properties on component mount
  }, []);

  const handleAddProperty = () => {
    navigate('/rental/property/add'); // Navigate to the Add Property page
  };

  return (
    <div className="properties-container">
      <div className="properties-header">
        <h1>Rental Properties</h1>
        <Button variant="contained" color="primary" size='large' onClick={handleAddProperty}>
          Add Property
        </Button>
      </div>

      {loading ? (
        <p>Loading properties data...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <PropertyTable properties={properties} /> // Render PropertyTable if data is available
      )}
    </div>
  );
};

export default Properties;
