import React from 'react';
import { useParams } from 'react-router-dom';

const AddTime = () => {
  const { AddTimeId } = useParams();

  return (
    <div className="AddTime-container">
      <h1>AddTime ID: {AddTimeId}</h1>
    </div>
  );
};

export default AddTime;
