import React from 'react';
import { useParams } from 'react-router-dom';

const AddMeeting = () => {
  const { AddMeetingId } = useParams();

  return (
    <div className="AddMeeting-container">
      <h1>AddMeeting ID: {AddMeetingId}</h1>
    </div>
  );
};

export default AddMeeting;
