export const MuiInputLabel = {
    styleOverrides: {
        root: {
            color: "#333333", // Default label color
            fontWeight: "bold", // Make the label text bold
            fontSize: "1rem", // Slightly larger font size for readability
            marginBottom: "8px", // Add spacing below the label
            "&.Mui-focused": {
                color: "#007bff", // Change color when input is focused
            },
            "&.Mui-disabled": {
                color: "#a0a0a0", // Change color for disabled state
            },
        },
    },
};
