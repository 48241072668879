export const MuiDataGrid = {
    styleOverrides: {
      root: {
        border: "1px solid #e0e0e0", // Border for the entire DataGrid
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#d0d0d0", // Darker header background color
          color: "#333333", // Header text color for contrast
          fontWeight: "bold", // Bold header text
          fontSize: "1.05rem", // Slightly larger header text
          borderBottom: "2px solid #ccc", // Bottom border for the headers
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          color: "#333333", // Ensure the column header text color is set properly
        },
        "& .MuiDataGrid-columnSeparator--sideRight": {
          visibility: "visible", // Make sure the column separators are visible
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "1px solid #ddd", // Row bottom borders (adds gridline between rows)
          borderRight: "1px solid #ddd", // Column right borders (adds gridline between columns)
          padding: "10px 16px", // Padding for cells
          fontSize: "0.95rem", // Cell text size
          color: "#555555", // Cell text color
          verticalAlign: "middle", // Vertically align text in the middle
          display: "flex", // Flexbox to ensure content stays aligned
          alignItems: "center", // Center align vertically using Flexbox
        },
        "& .MuiDataGrid-row:nth-of-type(odd)": {
          backgroundColor: "#f9f9f9", // Light gray background for odd rows
        },
        "& .MuiDataGrid-row:nth-of-type(even)": {
          backgroundColor: "#ffffff", // White background for even rows
        },
        "& .MuiDataGrid-row:hover": {
          backgroundColor: "#f1f1f1", // Slightly darker gray on hover
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "1px solid #ddd", // Top border for the footer
          backgroundColor: "#fafafa", // Footer background color
        },
      },
      columnHeaders: {
        backgroundColor: "#f0f0f0", // Set a background color for the header
        fontWeight: "bold", // Make the header bold
        fontSize: "1rem", // Set the font size of the header
        borderBottom: "2px solid #ccc", // Add a bottom border to the header
      },
      columnHeaderTitle: {
        fontWeight: "bold", // Make the header title bold
        textTransform: "uppercase", // Transform header text to uppercase (optional)
      },
    },
  };
  