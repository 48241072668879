import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI"; // Import secureFetch for API calls
import AddBudgetForm from "./AddBudgetForm"; // Import the form component
import "./AddBudget.css";

const AddBudget = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = async (formData) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const payload = {
        Name: formData.Name,
        StartMonth: formData.StartMonth ? parseInt(formData.StartMonth) : null,
        FiscalYear: formData.FiscalYear ? parseInt(formData.FiscalYear) : null,
        ApprovalStatus: formData.ApprovalStatus || null,
        PropertyType: formData.PropertyType || null,
        PropertyId: formData.PropertyId ? parseInt(formData.PropertyId) : null,
      };

      const response = await secureFetch("POST", API_ENDPOINTS.FinanceBudgetBudget, payload);

      if (!response) {
        throw new Error("Failed to add budget");
      }

      setSuccess("Budget added successfully!");
      navigate(`/finance/budget/${response.Id}`);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-budget">
      <h1>Add Budget</h1>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <AddBudgetForm onSubmit={handleSubmit} loading={loading} />
    </div>
  );
};

export default AddBudget;
