import React, { useState } from "react";
import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";

// Helper function to format numbers as currency
const formatCurrency = (amount) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  if (amount < 0) {
    return `(${formatter.format(Math.abs(amount))})`;
  }

  return formatter.format(amount);
};

// Function to deduplicate transactions based on unique IDs
const deduplicateTransactions = (transactions) => {
  const uniqueTransactions = [];
  const ids = new Set();

  transactions.forEach((transaction) => {
    if (!ids.has(transaction.Id)) {
      uniqueTransactions.push(transaction);
      ids.add(transaction.Id);
    }
  });

  return uniqueTransactions;
};

const LeaseFinancialsLedger = ({ transactions }) => {
  const [selectedTransaction, setSelectedTransaction] = useState(null); // State to hold the selected transaction for the modal
  const theme = useTheme(); // Access the theme

  // Function to calculate the total amount of the transaction (sum of all line items)
  const calculateTotalForTransaction = (transaction) => {
    return transaction.LineItems.reduce(
      (total, lineItem) => total + parseFloat(lineItem.Amount),
      0
    ).toFixed(2);
  };

  // Deduplicate transactions to avoid rendering duplicate rows
  const uniqueTransactions = deduplicateTransactions(transactions);

  // Function to get the account name from the line item and transaction
  const getAccountName = (lineItem, transaction) => {
    return lineItem.AccountId === transaction.AccountId
      ? transaction.AccountName // Use the AccountName from transaction if AccountId matches
      : transactions.find((t) => t.AccountId === lineItem.AccountId)?.AccountName || "Unknown"; // Look up AccountName if not matched
  };

  // Columns for the DataGrid
  const columns = [
    { field: "Date", headerName: "Date", flex: 1 },
    { field: "Description", headerName: "Description", flex: 2 },
    { field: "Amount", headerName: "Amount", flex: 1 },
    { field: "TransactionType", headerName: "Type", flex: 1 },
  ];

  // Map the transactions into rows for the DataGrid
  const rows = uniqueTransactions.map((transaction) => ({
    id: transaction.Id,
    Date: new Date(transaction.ReceivedDate || transaction.DueDate).toLocaleDateString(),
    Description: transaction.Memo || transaction.Description,
    Amount: formatCurrency(calculateTotalForTransaction(transaction)),
    TransactionType: transaction.TransactionType,
    LineItems: transaction.LineItems, // Pass the LineItems for modal content
  }));

  // Close modal handler
  const handleClose = () => {
    setSelectedTransaction(null);
  };

  return (
    <Box sx={theme.containerSizes.large}>
      <div>
        <h3>Ledger DataGrid View</h3>
        <Box>
          <DataGrid
            rows={rows}
            hideFooter // Hide the footer, including pagination and row count
            rowHeight={40}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            autoHeight
            getRowId={(row) => row.id}
            onRowClick={(params) => setSelectedTransaction(params.row)} // Open modal on row click
            sx={{
              "& .MuiDataGrid-row": {
                "&:nth-of-type(even)": {
                  backgroundColor: "#f9f9f9",
                },
              },
            }}
          />
        </Box>

        {/* Modal to show expanded content */}
        {selectedTransaction && (
          <Modal
            open={!!selectedTransaction}
            onClose={handleClose}
            aria-labelledby="transaction-details-title"
            aria-describedby="transaction-details-description"
          >
            <Paper
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 600,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography id="transaction-details-title" variant="h6" component="h2">
                Transaction Details
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Account Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedTransaction.LineItems.map((lineItem, index) => (
                    <TableRow key={index}>
                      <TableCell>{lineItem.Description}</TableCell>
                      <TableCell>{formatCurrency(lineItem.Amount)}</TableCell>
                      <TableCell>{getAccountName(lineItem, selectedTransaction)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box sx={{ mt: 2 }}>
                <Typography>Total: {selectedTransaction.Amount}</Typography>
              </Box>
              <Box sx={{ textAlign: "right", mt: 2 }}>
                <button onClick={handleClose}>Close</button>
              </Box>
            </Paper>
          </Modal>
        )}
      </div>
    </Box>
  );
};

export default LeaseFinancialsLedger;
