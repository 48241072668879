import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

const PayablesTable = ({ payables }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="payables table">
        <TableHead>
          <TableRow>
            <TableCell>Due Date</TableCell>
            <TableCell>Vendor Name</TableCell>
            <TableCell>Case Title</TableCell>
            <TableCell>Bill Number</TableCell>
            <TableCell>Total Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payables.map((payable) => (
            <TableRow key={payable.Id}>
              <TableCell>
                {payable.DueDate
                  ? new Date(payable.DueDate).toLocaleDateString()
                  : "N/A"}
              </TableCell>
              <TableCell>{payable.vendorname ? payable.vendorname : "N/A"}</TableCell>
              <TableCell>{payable.casetitle ? payable.casetitle : "N/A"}</TableCell>
              <TableCell>{payable.BillNumber ? payable.BillNumber : "N/A"}</TableCell>
              <TableCell>
                <Link to={`/finance/payable/${payable.Id}`}>
                  {payable.totalamount ? `$${payable.totalamount}` : "$0.00"}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PayablesTable;
