import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AddViolation.css';

const AddViolation = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    ViolationType: '',
    addressLine1: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    owner: '',
    operatingAccount: '',
    ViolationReserve: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log('Form data submitted:', formData);
    // Redirect back to the properties page after submission
    navigate('/rental/properties');
  };

  return (
    <div className="add-violation-container">
      <h1>Add Violation</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Violation Type:</label>
          <select
            name="ViolationType"
            value={formData.ViolationType}
            onChange={handleChange}
            required
          >
            <option value="">Select Violation Type</option>
            <option value="CondoTownhome">Condo/Townhome</option>
            <option value="MultiFamily">Multi-Family</option>
            <option value="SingleFamily">Single-Family</option>
            <option value="Industrial">Industrial</option>
            <option value="Office">Office</option>
            <option value="Retail">Retail</option>
            <option value="ShoppingCenter">Shopping Center</option>
            <option value="Storage">Storage</option>
            <option value="ParkingSpace">Parking Space</option>
          </select>
        </div>
        <div className="form-group">
          <label>Address Line 1:</label>
          <input
            type="text"
            name="addressLine1"
            value={formData.addressLine1}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>City:</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>State:</label>
            <input
              type="text"
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Postal Code:</label>
            <input
              type="text"
              name="postalCode"
              value={formData.postalCode}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label>Country:</label>
          <input
            type="text"
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Owner:</label>
          <input
            type="text"
            name="owner"
            value={formData.owner}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Operating Account:</label>
          <input
            type="text"
            name="operatingAccount"
            value={formData.operatingAccount}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Violation Reserve:</label>
          <input
            type="text"
            name="ViolationReserve"
            value={formData.ViolationReserve}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="primary-button">
          Create Violation
        </button>
      </form>
    </div>
  );
};

export default AddViolation;
