// src/pages/OutstandingBalances.js
import React from 'react';
import TenantOutstandingBalancesTable from './TenantOutstandingBalancesTable';

const OutstandingBalances = () => {
  return (
    <div>
      <h1>Outstanding Balances</h1>
      <TenantOutstandingBalancesTable />
    </div>
  );
};

export default OutstandingBalances;
