import React from 'react';
import { useParams } from 'react-router-dom';

const Listings = () => {
  const { ListingsId } = useParams();

  return (
    <div className="Listings-container">
      <h1>Listings ID: {ListingsId}</h1>
    </div>
  );
};

export default Listings;
