export const MuiPaper = {
    styleOverrides: {
        root: {
            padding: "24px", // Standard padding for modal content
            backgroundColor: "#fff", // Modal background color
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.15)", // Custom shadow for depth effect
            borderRadius: "8px", // Consistent border-radius for modals
            maxWidth: "600px", // Standard width for modals
            width: "100%", // Ensure modal doesn't overflow on smaller screens
            outline: "none", // Remove the default focus outline for better appearance
        },
    },
};
