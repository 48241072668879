import React from 'react';
import { useParams } from 'react-router-dom';

const AllEquipment = () => {
  const { AllEquipmentId } = useParams();

  return (
    <div className="AllEquipment-container">
      <h1>AllEquipment ID: {AllEquipmentId}</h1>
    </div>
  );
};

export default AllEquipment;
