import React, { useState, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, InputLabel, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles"; // Import useTheme

const ViolationsTable = ({ violations }) => {
  const [filterText, setFilterText] = useState("");
  const [sortModel, setSortModel] = useState([
    { field: "Description", sort: "asc" },
  ]);
  const theme = useTheme(); // Access the theme

  // Remove duplicate violations by checking unique Ids
  const uniqueViolations = useMemo(() => {
    const seen = new Set();
    return violations.filter((violation) => {
      if (seen.has(violation.Id)) {
        return false;
      }
      seen.add(violation.Id);
      return true;
    });
  }, [violations]);

  // Filter the violations across all relevant fields
  const filteredViolations = useMemo(() => {
    const lowerCaseFilter = filterText.toLowerCase();
    return uniqueViolations.filter((violation) =>
      Object.keys(violation).some((key) =>
        String(violation[key]).toLowerCase().includes(lowerCaseFilter)
      )
    );
  }, [filterText, uniqueViolations]);

  // Event handler for sorting changes
  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  // Prepare columns for DataGrid using 'flex' for relative widths
  const columns = [
    {
      field: "Description",
      headerName: "Description",
      flex: 2,
      renderCell: (params) => (
        <Link
          to={`/association/violation/${params?.row?.Id}`}
          style={{
            color: "#007bff", // Blue color for the link
            textDecoration: "none", // Remove underline
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.textDecoration = "underline")
          }
          onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
        >
          {params?.row?.Description || "N/A"}
        </Link>
      ),
    },
    {
      field: "RequestedAction",
      headerName: "Requested Action",
      flex: 1.5,
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "DueDate",
      headerName: "Due Date",
      flex: 1,
      valueGetter: (params) => {
        const dueDate = params.row?.DueDate;
        return dueDate ? new Date(dueDate).toLocaleDateString() : "N/A";
      },
    },
  ];

  return (
    <Box sx={theme.containerSizes.xlarge}>
      <InputLabel htmlFor="filter-violations" className="search-label" />
      <TextField
        id="filter-violations"
        variant="outlined"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        fullWidth
        placeholder="Type to search..."
        className="search-bar"
      />
      <DataGrid
        rows={filteredViolations}
        columns={columns}
        rowHeight={40}
        sortingOrder={["asc", "desc"]}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        disableColumnFilter={false}
        disableColumnMenu={false}
        getRowId={(row) => row?.Id}
        autoHeight
        hideFooter
      />
    </Box>
  );
};

export default ViolationsTable;
