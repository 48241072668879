import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import BankAccountsTable from "./BankAccountsTable";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";
import './BankAccounts.css'; // Import the CSS file for styling
import { Button } from '@mui/material'; // Import Material UI components

const BankAccounts = () => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate for routing

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        // Fetch bank accounts data using secureFetch
        const response = await secureFetch('GET', API_ENDPOINTS.FinanceBankAccountBankAccounts);
        const data = typeof response === 'string' ? JSON.parse(response) : response; // Ensure response is parsed

        if (Array.isArray(data)) {
          setBankAccounts(data); // Set bank accounts data
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (err) {
        console.error("Error fetching bank accounts:", err);
        setError("Failed to load bank accounts data.");
      } finally {
        setLoading(false);
      }
    };

    fetchBankAccounts();
  }, []);

  return (
    <div className="bank-accounts-page">
      <div className="header-row">
        <h1>Bank Accounts</h1>
        <Button variant="contained" color="primary" size='large' onClick={() => navigate('/finance/bank-account/add')}>
          Add Bank Account
        </Button>

      </div>

      {loading ? (
        <p>Loading bank accounts data...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <BankAccountsTable bankAccounts={bankAccounts} />
      )}
    </div>
  );
};

export default BankAccounts;
