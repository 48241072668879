import React from 'react';
import { useParams } from 'react-router-dom';

const AddEstimate = () => {
  const { AddEstimateId } = useParams();

  return (
    <div className="AddEstimate-container">
      <h1>AddEstimate ID: {AddEstimateId}</h1>
    </div>
  );
};

export default AddEstimate;
