import React from 'react';
import { useParams } from 'react-router-dom';

const User = () => {
  const { UserId } = useParams();

  return (
    <div className="User-container">
      <h1>User ID: {UserId}</h1>
    </div>
  );
};

export default User;
