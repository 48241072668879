// src/Pages/Rental/Unit/Unit.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab, Box } from '@mui/material';
import API_ENDPOINTS from '../../../../APIEndPointURL';
import { secureFetch } from '../../../../AuthorizeAPI';
import UnitLeases from './UnitLeases';
import UnitSummary from './UnitSummary'; // Import the UnitSummary component

const Unit = () => {
  const { unitId } = useParams(); // Get unitId from the URL
  const [unitData, setUnitData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0); // State for handling active tab

  useEffect(() => {
    const fetchUnitData = async () => {
      try {
        const response = await secureFetch('GET', `${API_ENDPOINTS.RentalUnitUnit}?rentalUnitId=${unitId}`);

        // Set the data if the request was successful
        setUnitData(response);
      } catch (err) {
        // Handle error and set the error state
        console.error("Error fetching unit data:", err);
        setError(err.message || "An error occurred while fetching unit data.");
      } finally {
        setLoading(false);
      }
    };

    // Fetch unit data when the component mounts
    fetchUnitData();
  }, [unitId]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  if (loading) return <p>Loading unit data...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="unit-page">
      <div className="unit-header">
        <h1>Unit Overview</h1>
      </div>

      {/* Tabs for navigating different sections */}
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="unit tabs">
        <Tab label="Summary" disableRipple />
        <Tab label="Leases" disableRipple />
      </Tabs>

      {/* Tab Panels */}
      <TabPanel value={tabIndex} index={0}>
        <UnitSummary unit={unitData.rentalUnit} /> {/* Pass unit data to UnitSummary */}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <UnitLeases leases={unitData.leases} /> {/* Pass leases data to UnitLeases */}
      </TabPanel>
    </div>
  );
};

// TabPanel component
function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default Unit;
