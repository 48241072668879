// src/Pages/Association/AddAssociationForm.js
import React, { useState } from "react";
import { Button, Box } from '@mui/material'; // Import Material UI components
import { useTheme } from '@mui/material/styles'; // Import useTheme

const AddAssociationForm = ({ onSubmit, loading }) => {
    const theme = useTheme(); // Access the theme

    const [formData, setFormData] = useState({
        BankAccountId: '',
        Name: '',
        AddressLine1: '',
        AddressLine2: '',
        City: '',
        State: '',
        ZipCode: '',
        Country: '',
        Reserve: '',
        Description: '',
        YearBuilt: '',
        TIN: '',
        FiscalYearEndDate: '',
        AgreementStartDate: '',
        AgreementEndDate: '',
        PropertyManager: '',
        ManagementFeeMethod: '',
        ManagementFeeAmount: '',
        LateFeeDays: '',
        LateFeeMethod: '',
        LateFeeAmount: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData); // Pass form data to parent component's onSubmit function
    };

    return (
        <Box sx={theme.containerSizes.medium}> {/* Apply the medium container */}
            <form onSubmit={handleSubmit}>
                <label>
                    Bank Account ID (required):
                    <input
                        type="number"
                        name="BankAccountId"
                        value={formData.BankAccountId}
                        onChange={handleInputChange}
                        required
                    />
                </label>

                <label>
                    Association Name (required):
                    <input
                        type="text"
                        name="Name"
                        value={formData.Name}
                        onChange={handleInputChange}
                        required
                    />
                </label>

                <label>
                    Address Line 1 (required):
                    <input
                        type="text"
                        name="AddressLine1"
                        value={formData.AddressLine1}
                        onChange={handleInputChange}
                        required
                    />
                </label>

                <label>
                    Address Line 2 (optional):
                    <input
                        type="text"
                        name="AddressLine2"
                        value={formData.AddressLine2}
                        onChange={handleInputChange}
                    />
                </label>

                <label>
                    City (required):
                    <input
                        type="text"
                        name="City"
                        value={formData.City}
                        onChange={handleInputChange}
                        required
                    />
                </label>

                <label>
                    State (required):
                    <input
                        type="text"
                        name="State"
                        value={formData.State}
                        onChange={handleInputChange}
                        required
                    />
                </label>

                <label>
                    Zip Code (required):
                    <input
                        type="text"
                        name="ZipCode"
                        value={formData.ZipCode}
                        onChange={handleInputChange}
                        required
                    />
                </label>

                <label>
                    Country (required):
                    <input
                        type="text"
                        name="Country"
                        value={formData.Country}
                        onChange={handleInputChange}
                        required
                    />
                </label>

                <label>
                    Reserve (optional):
                    <input
                        type="number"
                        name="Reserve"
                        value={formData.Reserve}
                        onChange={handleInputChange}
                    />
                </label>

                <label>
                    Description (optional):
                    <textarea
                        name="Description"
                        value={formData.Description}
                        onChange={handleInputChange}
                    />
                </label>

                <label>
                    Year Built (optional):
                    <input
                        type="number"
                        name="YearBuilt"
                        value={formData.YearBuilt}
                        onChange={handleInputChange}
                    />
                </label>

                <label>
                    TIN (optional):
                    <input
                        type="text"
                        name="TIN"
                        value={formData.TIN}
                        onChange={handleInputChange}
                    />
                </label>

                <label>
                    Fiscal Year End Date (optional):
                    <input
                        type="date"
                        name="FiscalYearEndDate"
                        value={formData.FiscalYearEndDate}
                        onChange={handleInputChange}
                    />
                </label>

                <label>
                    Agreement Start Date (optional):
                    <input
                        type="date"
                        name="AgreementStartDate"
                        value={formData.AgreementStartDate}
                        onChange={handleInputChange}
                    />
                </label>

                <label>
                    Agreement End Date (optional):
                    <input
                        type="date"
                        name="AgreementEndDate"
                        value={formData.AgreementEndDate}
                        onChange={handleInputChange}
                    />
                </label>

                <label>
                    Property Manager (optional):
                    <input
                        type="number"
                        name="PropertyManager"
                        value={formData.PropertyManager}
                        onChange={handleInputChange}
                    />
                </label>

                <label>
                    Management Fee Method (optional):
                    <input
                        type="text"
                        name="ManagementFeeMethod"
                        value={formData.ManagementFeeMethod}
                        onChange={handleInputChange}
                    />
                </label>

                <label>
                    Management Fee Amount (optional):
                    <input
                        type="number"
                        name="ManagementFeeAmount"
                        value={formData.ManagementFeeAmount}
                        onChange={handleInputChange}
                    />
                </label>

                <label>
                    Late Fee Days (optional):
                    <input
                        type="number"
                        name="LateFeeDays"
                        value={formData.LateFeeDays}
                        onChange={handleInputChange}
                    />
                </label>

                <label>
                    Late Fee Method (optional):
                    <input
                        type="text"
                        name="LateFeeMethod"
                        value={formData.LateFeeMethod}
                        onChange={handleInputChange}
                    />
                </label>

                <label>
                    Late Fee Amount (optional):
                    <input
                        type="number"
                        name="LateFeeAmount"
                        value={formData.LateFeeAmount}
                        onChange={handleInputChange}
                    />
                </label>

                <Button variant="contained" color="primary" size='large' type="submit" disabled={loading}>
                    {loading ? 'Submitting...' : 'Add Association'}
                </Button>
            </form>
        </Box>
    );
};

export default AddAssociationForm;
