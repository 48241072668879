import React from 'react';
import { useParams } from 'react-router-dom';

const Week = () => {
  const { WeekId } = useParams();

  return (
    <div className="Week-container">
      <h1>Week ID: {WeekId}</h1>
    </div>
  );
};

export default Week;
