import React from 'react';
import { useParams } from 'react-router-dom';

const Day = () => {
  const { DayId } = useParams();

  return (
    <div className="Day-container">
      <h1>Day ID: {DayId}</h1>
    </div>
  );
};

export default Day;
