import React from 'react';
import './Dashboard.css'; // Import any CSS for the Dashboard page

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>
    </div>
  );
};

export default Dashboard;
