import React from 'react';
import { useParams } from 'react-router-dom';

const AddVendorStaff = () => {
  const { AddVendorStaffId } = useParams();

  return (
    <div className="AddVendorStaff-container">
      <h1>AddVendorStaff ID: {AddVendorStaffId}</h1>
    </div>
  );
};

export default AddVendorStaff;
