import React from 'react';
import { useParams } from 'react-router-dom';

const AddOffer = () => {
  const { AddOfferId } = useParams();

  return (
    <div className="AddOffer-container">
      <h1>AddOffer ID: {AddOfferId}</h1>
    </div>
  );
};

export default AddOffer;
