import React, { useState, useMemo, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, InputLabel, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ChartOfAccountsTable = ({ chartOfAccounts }) => {
  const [filterText, setFilterText] = useState("");
  const [sortModel, setSortModel] = useState([{ field: "AccountNumber", sort: "asc" }]);
  const theme = useTheme();

  // Log chartOfAccounts data to ensure it contains the expected values
  useEffect(() => {
    console.log("ChartOfAccounts data:", chartOfAccounts);
  }, [chartOfAccounts]);

  // Filter chart of accounts based on the input search text
  const filteredAccounts = useMemo(() => {
    const lowerCaseFilter = filterText.toLowerCase();
    return chartOfAccounts.filter((account) =>
      Object.keys(account).some((key) =>
        String(account[key]).toLowerCase().includes(lowerCaseFilter)
      )
    );
  }, [filterText, chartOfAccounts]);

  const columns = [
    {
      field: "AccountNumber",
      headerName: "Account Number",
      flex: 1.5,
      renderCell: (params) => params.row?.AccountNumber || "N/A",
    },
    {
      field: "AccountName",
      headerName: "Account Name",
      flex: 2,
      renderCell: (params) => params.row?.AccountName || "N/A",
    },
    {
      field: "AccountType",
      headerName: "Account Type",
      flex: 1.5,
      renderCell: (params) => params.row?.AccountType || "N/A",
    },
    {
      field: "Description",
      headerName: "Description",
      flex: 2,
      renderCell: (params) => params.row?.Description || "N/A",
    },
  ];

  return (
    <Box sx={theme.containerSizes.xlarge}>
      <InputLabel htmlFor="filter-accounts" className="search-label" />
      <TextField
        id="filter-accounts"
        variant="outlined"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        fullWidth
        placeholder="Type to search accounts..."
        className="search-bar"
      />
      <DataGrid
        rows={filteredAccounts}
        columns={columns}
        rowHeight={40}
        sortingOrder={["asc", "desc"]}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        disableColumnFilter={false}
        disableColumnMenu={false}
        getRowId={(row) => row?.AccountNumber || Math.random()}
        autoHeight
        hideFooter
      />
    </Box>
  );
};

export default ChartOfAccountsTable;
