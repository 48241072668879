import React from 'react';
import { useParams } from 'react-router-dom';

const Agent = () => {
  const { AgentId } = useParams();

  return (
    <div className="Agent-container">
      <h1>Agent ID: {AgentId}</h1>
    </div>
  );
};

export default Agent;
