const BASE_URL = 'https://kz94jgxkob.execute-api.us-east-2.amazonaws.com/dev';

const API_ENDPOINTS = {

    // Association
    AssociationAssociationAddAssociation: `${BASE_URL}/Association/Association/AddAssociation`,    
    AssociationAssociationAssociation: `${BASE_URL}/Association/Association/Association`,    
    AssociationAssociationAssociations: `${BASE_URL}/Association/Association/Associations`,    

    AssociationAssociationOwnersAndTenants: `${BASE_URL}/Association/AssociationOwnersAndTenants`,    

    AssociationMeetingAddMeeting: `${BASE_URL}/Association/Meeting/AddMeeting`,    
    AssociationMeetingMeeting: `${BASE_URL}/Association/Meeting/Meeting`,    
    AssociationMeetingMeetings: `${BASE_URL}/Association/Meeting/Meetings`,    

    AssociationOutstandingBalances: `${BASE_URL}/Association/OutstandingBalances`,

    AssociationOwnerAddOwner: `${BASE_URL}/Association/Owner/AddOwner`,    
    AssociationOwnerOwner: `${BASE_URL}/Association/Owner/Owner`,    

    AssociationOwnershipAccountAddOwnershipAccount: `${BASE_URL}/Association/OwnershipAccount/AddOwnershipAccount`,    
    AssociationOwnershipAccountOwnershipAccount: `${BASE_URL}/Association/OwnershipAccount/OwnershipAccount`,    
    AssociationOwnershipAccountOwnershipAccounts: `${BASE_URL}/Association/OwnershipAccount/OwnershipAccounts`,    

    AssociationTenantAddTenant: `${BASE_URL}/Association/Tenant/AddTenant`,    
    AssociationTenantTenant: `${BASE_URL}/Association/Tenant/AddTenant`,    

    AssociationUnitAddUnit: `${BASE_URL}/Association/Unit/AddUnit`,    
    AssociationUnitUnit: `${BASE_URL}/Association/Unit/Unit`,    

    AssociationViolationAddViolation: `${BASE_URL}/Association/Violation/AddViolation`,    
    AssociationViolationViolation: `${BASE_URL}/Association/Violation/Violation`,    
    AssociationViolationViolations: `${BASE_URL}/Association/Violation/Violations`,


    // Calendar


    // Case
    CaseCaseAddCase: `${BASE_URL}/Case/Case/AddCase`,
    CaseCaseCase: `${BASE_URL}/Case/Case/Case`,
    CaseCaseCases: `${BASE_URL}/Case/Case/Cases`,

    CaseCaseCategoryAddCaseCategories: `${BASE_URL}/Case/CaseCategory/AddCaseCategories`,
    CaseCaseCategoryCaseCategories: `${BASE_URL}/Case/CaseCategory/CaseCategories`,
    CaseCaseCategoryCaseCategory: `${BASE_URL}/Case/CaseCategory/CaseCategory`,

    CaseCaseDashboard: `${BASE_URL}/Case/CaseDashboard`,

    CaseEstimateAddEstimate: `${BASE_URL}/Case/Estimate/AddEstimate`,
    CaseEstimateEstimate: `${BASE_URL}/Case/Estimate/Estimate`,
    CaseEstimateEstimates: `${BASE_URL}/Case/Estimate/Estimates`,

    CaseTimeAddTime: `${BASE_URL}/Case/Time/AddTime`,
    CaseTimeTime: `${BASE_URL}/Case/Time/Time`,
    CaseTimeTimes: `${BASE_URL}/Case/Time/Times`,


    // Company
    CompanyEquipmentAddEquipment: `${BASE_URL}/Company/Equipment/AddEquipment`,
    CompanyEquipmentAllEquipment: `${BASE_URL}/Company/Equipment/AllEquipment`,
    CompanyEquipmentEquipment: `${BASE_URL}/Company/Equipment/Equipment`,

    CompanyEquipmentServiceRecordAddEquipmentServiceRecord: `${BASE_URL}/Company/EquipmentServiceRecord/AddEquipmentServiceRecord`,
    CompanyEquipmentServiceRecordEquipmentServiceRecord: `${BASE_URL}/Company/EquipmentServiceRecord/EquipmentServiceRecord`,

    CompanySettings: `${BASE_URL}/Company/Settings`,

    CompanyUserAddUser: `${BASE_URL}/Company/User/AddUser`,
    CompanyUserUser: `${BASE_URL}/Company/User/User`,
    CompanyUserUsers: `${BASE_URL}/Company/User/Users`,


    // Construction
    ConstructionMarketplace: `${BASE_URL}/Construction/Marketplace`,

    ConstructionProjects: `${BASE_URL}/Construction/Projects`,

    ConstructionServiceAddService: `${BASE_URL}/Construction/Service/AddService`,
    ConstructionServiceService: `${BASE_URL}/Construction/Service/Service`,
    ConstructionServiceServices: `${BASE_URL}/Construction/Service/Services`,

    ConstructionServiceAreaAddServiceArea: `${BASE_URL}/Construction/ServiceArea/AddServiceArea`,
    ConstructionServiceAreaServiceArea: `${BASE_URL}/Construction/ServiceArea/ServiceArea`,

    ConstructionVendorAddVendor: `${BASE_URL}/Construction/Vendor/AddVendor`,
    ConstructionVendorVendor: `${BASE_URL}/Construction/Vendor/Vendor`,
    ConstructionVendorVendors: `${BASE_URL}/Construction/Vendor/Vendors`,

    ConstructionVendorStaffAddVendorStaff: `${BASE_URL}/Construction/VendorStaff/AddVendorStaff`,
    ConstructionVendorStaffAllVendorStaff: `${BASE_URL}/Construction/VendorStaff/AllVendorStaff`,
    ConstructionVendorStaffVendorStaff: `${BASE_URL}/Construction/VendorStaff/VendorStaff`,


    // Dashboard
    Dashboard: `${BASE_URL}/Dashboard`,


    // Finance
    FinanceBankAccountAddBankAccount: `${BASE_URL}/Finance/BankAccount/AddBankAccount`,
    FinanceBankAccountBankAccount: `${BASE_URL}/Finance/BankAccount/BankAccount`,
    FinanceBankAccountBankAccounts: `${BASE_URL}/Finance/BankAccount/BankAccounts`,

    FinanceBudgetAddBudget: `${BASE_URL}/Finance/Budget/AddBudget`,
    FinanceBudgetBudget: `${BASE_URL}/Finance/Budget/Budget`,
    FinanceBudgetBudgets: `${BASE_URL}/Finance/Budget/Budgets`,

    FinanceChartOfAccountAddChartOfAccounts: `${BASE_URL}/Finance/ChartOfAccount/AddChartOfAccounts`,
    FinanceChartOfAccountChartOfAccounts: `${BASE_URL}/Finance/ChartOfAccount/ChartOfAccounts`,

    FinancePayableAddPayable: `${BASE_URL}/Finance/Payable/AddPayable`,
    FinancePayablePayable: `${BASE_URL}/Finance/Payable/Payable`,
    FinancePayablePayables: `${BASE_URL}/Finance/Payable/Payables`,

    FinanceProductAndServiceAddProductAndService: `${BASE_URL}/Finance/ProductAndService/AddProductAndService`,
    FinanceProductAndServiceProductAndService: `${BASE_URL}/Finance/ProductAndService/ProductAndService`,
    FinanceProductAndServiceProductAndServices: `${BASE_URL}/Finance/ProductAndService/ProductAndServices`,

    FinanceReceivableAddReceivable: `${BASE_URL}/Finance/Receivable/AddReceivable`,
    FinanceReceivableReceivable: `${BASE_URL}/Finance/Receivable/Receivable`,
    FinanceReceivableReceivables: `${BASE_URL}/Finance/Receivable/Receivables`,

    FinanceReconciliationAddReconciliation: `${BASE_URL}/Finance/Reconciliation/AddReconciliation`,
    FinanceReconciliationReconciliation: `${BASE_URL}/Finance/Reconciliation/Reconciliation`,
    FinanceReconciliationReconciliations: `${BASE_URL}/Finance/Reconciliation/Reconciliations`,


    // Marketing
    MarketingEmailCampaignAddEmailCampaign: `${BASE_URL}/Marketing/EmailCampaign/AddEmailCampaign`,
    MarketingEmailCampaignEmailCampaign: `${BASE_URL}/Marketing/EmailCampaign/EmailCampaign`,
    MarketingEmailCampaignEmailCampaigns: `${BASE_URL}/Marketing/EmailCampaign/EmailCampaigns`,

    MarketingLeadAddLead: `${BASE_URL}/Marketing/Lead/AddLead`,
    MarketingLeadLead: `${BASE_URL}/Marketing/Lead/Lead`,
    MarketingLeadLeads: `${BASE_URL}/Marketing/Lead/Leads`,

    MarketingLeadCategoryAddLeadCategory: `${BASE_URL}/Marketing/LeadCategory/AddLeadCategory`,
    MarketingLeadCategoryLeadCategories: `${BASE_URL}/Marketing/LeadCategory/LeadCategories`,


    // Misc
    MiscInspectionAddInspection: `${BASE_URL}/Misc/Inspection/AddInspection`,
    MiscInspectionInspection: `${BASE_URL}/Misc/Inspection/Inspection`,
    MiscInspectionInspections: `${BASE_URL}/Misc/Inspection/Inspections`,

    MiscInspectionTemplateEditInspectionTemplate: `${BASE_URL}/Misc/InspectionTemplate/EditInspectionTemplate`,
    MiscInspectionTemplateInspectionTemplate: `${BASE_URL}/Misc/InspectionTemplate/InspectionTemplate`,

    MiscLockboxAddLockbox: `${BASE_URL}/Misc/Lockbox/AddLockbox`,
    MiscLockboxLockbox: `${BASE_URL}/Misc/Lockbox/Lockbox`,
    MiscLockboxLockboxes: `${BASE_URL}/Misc/Lockbox/Lockboxes`,


    // Realty
    RealtyAgentAddAgent: `${BASE_URL}/Realty/Agent/AddAgent`,
    RealtyAgentAgent: `${BASE_URL}/Realty/Agent/Agent`,
    RealtyAgentAgents: `${BASE_URL}/Realty/Agent/Agents`,

    RealtyDealAddDeal: `${BASE_URL}/Realty/Deal/AddDeal`,
    RealtyDealDeal: `${BASE_URL}/Realty/Deal/Deal`,
    RealtyDealDeals: `${BASE_URL}/Realty/Deal/Deals`,

    RealtyListingAddListing: `${BASE_URL}/Realty/Listing/AddListing`,
    RealtyListingListing: `${BASE_URL}/Realty/Listing/Listing`,
    RealtyListingListings: `${BASE_URL}/Realty/Listing/Listings`,

    RealtyOfferAddOffer: `${BASE_URL}/Realty/Offer/AddOffer`,
    RealtyOfferOffer: `${BASE_URL}/Realty/Offer/Offer`,
    RealtyOfferOffers: `${BASE_URL}/Realty/Offer/Offers`,


    // Rental
    RentalApplicantAddApplicant: `${BASE_URL}/Rental/Applicant/AddApplicant`,
    RentalApplicantApplicant: `${BASE_URL}/Rental/Applicant/Applicant`,
    RentalApplicantApplicants: `${BASE_URL}/Rental/Applicant/Applicants`,

    RentalApplicantGroupAddApplicantGroup: `${BASE_URL}/Rental/ApplicantGroup/AddApplicantGroup`,
    RentalApplicantGroupApplicantGroup: `${BASE_URL}/Rental/ApplicantGroup/ApplicantGroup`,
    RentalApplicantGroupApplicantGroups: `${BASE_URL}/Rental/ApplicantGroup/ApplicantGroups`,

    RentalApplicationAddApplication: `${BASE_URL}/Rental/Application/AddApplication`,
    RentalApplicationApplication: `${BASE_URL}/Rental/Application/Application`,
    RentalApplicationApplications: `${BASE_URL}/Rental/Application/Applications`,

    RentalApplicationTemplateApplicationTemplate: `${BASE_URL}/Rental/ApplicationTemplate/ApplicationTemplate`,
    RentalApplicationTemplateEditApplicationTemplate: `${BASE_URL}/Rental/ApplicationTemplate/EditApplicationTemplate`,

    RentalCollectManagementFees: `${BASE_URL}/Rental/CollectManagementFees`,

    RentalInvestorAddInvestor: `${BASE_URL}/Rental/Investor/AddInvestor`,
    RentalInvestorInvestor: `${BASE_URL}/Rental/Investor/Investor`,
    RentalInvestorInvestors: `${BASE_URL}/Rental/Investor/Investors`,

    RentalInvestorBankAccountAddInvestorBankAccount: `${BASE_URL}/Rental/InvestorBankAccount/AddInvestorBankAccount`,
    RentalInvestorBankAccountInvestorBankAccount: `${BASE_URL}/Rental/InvestorBankAccount/InvestorBankAccount`,

    RentalLeaseAddLease: `${BASE_URL}/Rental/Lease/AddLease`,
    RentalLeaseLease: `${BASE_URL}/Rental/Lease/Lease`,
    RentalLeaseRentRoll: `${BASE_URL}/Rental/Lease/RentRoll`,

    RentalLeaseRenewals: `${BASE_URL}/Rental/LeaseRenewals`,

    RentalOutstandingBalances: `${BASE_URL}/Rental/OutstandingBalances`,

    RentalPropertyAddProperty: `${BASE_URL}/Rental/Property/AddProperty`,
    RentalPropertyProperties: `${BASE_URL}/Rental/Property/Properties`,
    RentalPropertyProperty: `${BASE_URL}/Rental/Property/Property`,

    RentalRentalListingAddRentalListing: `${BASE_URL}/Rental/RentalListing/AddRentalListing`,
    RentalRentalListingRentalListing: `${BASE_URL}/Rental/RentalListing/RentalListing`,
    RentalRentalListingRentalListings: `${BASE_URL}/Rental/RentalListing/RentalListings`,

    RentalTenantAddTenant: `${BASE_URL}/Rental/Tenant/AddTenant`,
    RentalTenantTenant: `${BASE_URL}/Rental/Tenant/Tenant`,
    RentalTenantTenants: `${BASE_URL}/Rental/Tenant/Tenants`,

    RentalUnitAddUnit: `${BASE_URL}/Rental/Unit/AddUnit`,
    RentalUnitUnit: `${BASE_URL}/Rental/Unit/Unit`,


    //Report
    Reports: `${BASE_URL}/Report`

};

export default API_ENDPOINTS;
