import React from 'react';
import { useParams } from 'react-router-dom';
import './Investor.css';

const Investor = () => {
  const { investorId } = useParams();

  return (
    <div className="investor-container">
      <h1>Investor ID: {investorId}</h1>
    </div>
  );
};

export default Investor;
