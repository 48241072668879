import React from 'react';
import { useParams } from 'react-router-dom';

const Marketplace = () => {
  const { MarketplaceId } = useParams();

  return (
    <div className="Marketplace-container">
      <h1>Marketplace ID: {MarketplaceId}</h1>
    </div>
  );
};

export default Marketplace;
