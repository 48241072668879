import React from 'react';
import Select from 'react-select';
import { Button, Box } from '@mui/material'; // Import Material UI components
import { useTheme } from '@mui/material/styles'; // Import useTheme

const AddUnitForm = ({ formData, setFormData, handleSubmit, loading, propertyId }) => {
  const theme = useTheme(); // Access the theme

  const amenityOptions = [
    { value: 'Air conditioner', label: 'Air conditioner' },
    { value: 'Cable', label: 'Cable' },
    { value: 'Carpet', label: 'Carpet' },
    { value: 'Ceiling fan', label: 'Ceiling fan' },
    { value: 'Dishwasher', label: 'Dishwasher' },
    { value: 'Furnished', label: 'Furnished' },
    { value: 'Garage parking', label: 'Garage parking' },
    { value: 'Hardwood floors', label: 'Hardwood floors' },
    { value: 'Laundry hookup', label: 'Laundry hookup' },
    { value: 'Pet friendly', label: 'Pet friendly' },
    { value: 'Private balcony', label: 'Private balcony' },
    { value: 'Washer', label: 'Washer' },
  ];

  const bedroomOptions = [
    { value: 'Studio', label: 'Studio' },
    { value: '1', label: '1 Bed' },
    { value: '2', label: '2 Bed' },
    { value: '3', label: '3 Bed' },
    { value: '4', label: '4 Bed' },
  ];

  const bathroomOptions = [
    { value: '1', label: '1 Bath' },
    { value: '1.5', label: '1.5 Bath' },
    { value: '2', label: '2 Bath' },
    { value: '2.5', label: '2.5 Bath' },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAmenityChange = (selectedOptions) => {
    const amenities = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setFormData({ ...formData, Amenities: amenities });
  };

  const handleBedroomsChange = (selectedOption) => {
    setFormData({ ...formData, Bedrooms: selectedOption.value });
  };

  const handleBathroomsChange = (selectedOption) => {
    setFormData({ ...formData, Bathrooms: selectedOption.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // Constructing the payload with propertyId and other form data
    const payload = {
      propertyId: parseInt(propertyId), // Ensure this is a number
      AddressLine1: formData.AddressLine1 || '',
      AddressLine2: formData.AddressLine2 || '',
      City: formData.City || '',
      State: formData.State || '',
      ZipCode: formData.ZipCode || '',
      Country: formData.Country || 'USA',
      Sqft: formData.Sqft ? parseInt(formData.Sqft) : null,
      MarketRent: formData.MarketRent ? parseFloat(formData.MarketRent) : null,
      Bedrooms: formData.Bedrooms ? parseInt(formData.Bedrooms) : null,
      Bathrooms: formData.Bathrooms ? parseFloat(formData.Bathrooms) : null,
      Description: formData.Description || '',
      Amenities: formData.Amenities || []
    };

    console.log('Payload for submission:', payload);

    handleSubmit(payload);
  };

  return (
    <Box sx={theme.containerSizes.medium}> {/* Apply the medium container */}

      <form onSubmit={onSubmit}>
        <div className="form-row">
          <label>
            Address Line 1 (required):
            <input type="text" name="AddressLine1" value={formData.AddressLine1} onChange={handleInputChange} required />
          </label>

          <label>
            Address Line 2 (optional):
            <input type="text" name="AddressLine2" value={formData.AddressLine2} onChange={handleInputChange} />
          </label>
        </div>

        <div className="form-row">
          <label>
            City (required):
            <input type="text" name="City" value={formData.City} onChange={handleInputChange} required />
          </label>

          <label>
            State (required):
            <input type="text" name="State" value={formData.State} onChange={handleInputChange} required />
          </label>

          <label>
            Zip Code (required):
            <input type="text" name="ZipCode" value={formData.ZipCode} onChange={handleInputChange} required />
          </label>
        </div>

        <div className="form-row">
          <label>
            Square Footage:
            <input type="number" name="Sqft" value={formData.Sqft} onChange={handleInputChange} />
          </label>

          <label>
            Market Rent:
            <input type="number" name="MarketRent" value={formData.MarketRent} onChange={handleInputChange} />
          </label>
        </div>

        <div className="form-row">
          <label>
            Bedrooms:
            <Select
              options={bedroomOptions}
              value={bedroomOptions.find(option => option.value === formData.Bedrooms)}
              onChange={handleBedroomsChange}
            />
          </label>

          <label>
            Bathrooms:
            <Select
              options={bathroomOptions}
              value={bathroomOptions.find(option => option.value === formData.Bathrooms)}
              onChange={handleBathroomsChange}
            />
          </label>
        </div>

        <label>
          Description:
          <textarea name="Description" value={formData.Description} onChange={handleInputChange} />
        </label>

        <label>
          Amenities:
          <Select
            isMulti
            options={amenityOptions}
            value={formData.Amenities.map(amenity => ({ value: amenity, label: amenity }))}
            onChange={handleAmenityChange}
            closeMenuOnSelect={false}
          />
        </label>

        <Button variant="contained" color="primary" size='large' type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Add Unit'}
        </Button>
      </form>
    </Box>
  );
};

export default AddUnitForm;
