import React from 'react';
import { useParams } from 'react-router-dom';

const Deals = () => {
  const { DealsId } = useParams();

  return (
    <div className="Deals-container">
      <h1>Deals ID: {DealsId}</h1>
    </div>
  );
};

export default Deals;
