import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { secureFetch } from '../../../../AuthorizeAPI'; // Import secureFetch
import API_ENDPOINTS from "../../../../APIEndPointURL";
import AddChartOfAccountsForm from "./AddChartOfAccountsForm"; // Split the form part
import './AddChartOfAccounts.css';

const AddChartOfAccounts = () => {
  const [formData, setFormData] = useState({
    AccountNumber: '',
    AccountName: '',
    AccountType: '',
    ParentAccountId: '',
    Description: ''
  });
  const [parentAccounts, setParentAccounts] = useState([]); // State to hold parent accounts
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch parent accounts using secureFetch
    const fetchParentAccounts = async () => {
      try {
        const response = await secureFetch('GET', API_ENDPOINTS.FinanceChartOfAccountAddChartOfAccounts); // Using secureFetch
        const data = Array.isArray(response) ? response : JSON.parse(response.body);
        setParentAccounts(data); // Set the parent accounts to state
      } catch (error) {
        console.error("Error fetching parent accounts:", error);
        setError("Failed to load parent accounts.");
      }
    };

    fetchParentAccounts();
  }, []);

  const handleSubmit = async (formData) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const payload = {
        AccountNumber: formData.AccountNumber || null,
        AccountName: formData.AccountName,
        AccountType: formData.AccountType,
        ParentAccountId: formData.ParentAccountId ? parseInt(formData.ParentAccountId) : null,
        Description: formData.Description || null,
        IsActive: true
      };

      const response = await secureFetch('POST', API_ENDPOINTS.FinanceChartOfAccountAddChartOfAccounts, payload); // Using secureFetch for POST

      if (!response || !response.Id) {
        throw new Error("Failed to add chart of accounts");
      }

      setSuccess('Chart of Accounts added successfully!');
      navigate(`/finance/chart-of-accounts`);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-chart-of-accounts">
      <h1>Add Chart of Accounts</h1>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <AddChartOfAccountsForm 
        formData={formData} 
        setFormData={setFormData} 
        parentAccounts={parentAccounts} 
        loading={loading}
        onSubmit={handleSubmit} // Passing the submit handler
      />
    </div>
  );
};

export default AddChartOfAccounts;
