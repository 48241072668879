import React, { useEffect, useState } from "react";
import PayablesTable from "./PayablesTable";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";
import './Payables.css'; // Optional CSS file for styling

const Payables = () => {
  const [payables, setPayables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPayables = async () => {
      try {
        const response = await secureFetch('GET', API_ENDPOINTS.FinancePayablePayables);

        // Ensure response is correctly parsed
        const parsedData = response.body ? JSON.parse(response.body) : response;

        if (Array.isArray(parsedData)) {
          setPayables(parsedData);
        } else {
          console.error("Unexpected response format:", parsedData);
          setError("Unexpected response format from server.");
        }
      } catch (err) {
        console.error("Error fetching payables:", err);
        setError("Failed to load payables data.");
      } finally {
        setLoading(false);
      }
    };

    fetchPayables();
  }, []);

  return (
    <div>
      <h1>Payables</h1>

      {loading ? (
        <p>Loading payables data...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <PayablesTable payables={payables} />
      )}
    </div>
  );
};

export default Payables;
