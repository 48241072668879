export const MuiTable = {
    styleOverrides: {
      root: {
        width: "100%",
        borderCollapse: "collapse", // To collapse borders
      },
    },
  };
  
  export const MuiTableCell = {
    styleOverrides: {
      root: {
        padding: "10px", // Padding for cells
        borderBottom: "1px solid #ddd", // Border for cells
        textAlign: "left", // Align text to the left
      },
      head: {
        backgroundColor: "#f1f1f1", // Background color for header cells
        fontWeight: "bold", // Bold font for header cells
      },
      body: {
        backgroundColor: "#fff", // Background color for body cells
      },
    },
  };
  
  export const MuiTableRow = {
    styleOverrides: {
      root: {
        "&:last-child td": {
          borderBottom: "none", // No border for the last row's cells
        },
      },
    },
  };
  