import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI"; // Use secureFetch instead of fetch
import AddPropertyForm from "./AddPropertyForm";
import './AddProperty.css';

const AddProperty = () => {
  const [propertyData, setPropertyData] = useState({
    bankAccounts: [],
    propertyManagers: [],
    investors: [],
    rentalTypes: [],
    managementFeeMethods: [],
    lateFeeMethods: [],
    propertyAmenitiesOptions: [],
    includedInRentOptions: []
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  // Fetch property-related data from API when component mounts
  useEffect(() => {
    const fetchPropertyData = async () => {
      try {
        // Using secureFetch for authorized requests
        const response = await secureFetch('GET', API_ENDPOINTS.RentalPropertyAddProperty);
        setPropertyData(response);
      } catch (error) {
        console.error("Error fetching property data:", error);
        setError("Failed to fetch property data. Please check your authorization.");
      }
    };
    fetchPropertyData();
  }, []);

  // Handle form submission
  const handleSubmit = async (formData) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const payload = {
        BankAccountId: parseInt(formData.BankAccountId),
        AddressLine1: formData.AddressLine1,
        AddressLine2: formData.AddressLine2 || null,
        City: formData.City,
        State: formData.State,
        ZipCode: formData.ZipCode,
        Country: formData.Country,
        Description: formData.Description || null,
        RentalType: formData.RentalType,
        PropertyManager: formData.PropertyManager ? parseInt(formData.PropertyManager) : null,
        Reserve: formData.Reserve ? parseFloat(formData.Reserve) : null,
        YearBuilt: formData.YearBuilt ? parseInt(formData.YearBuilt) : null,
        IncludedInRent: formData.IncludedInRent || [],
        PropertyAmenities: formData.PropertyAmenities || [],
        ManagementFeeMethod: formData.ManagementFeeMethod,
        ManagementFeeAmount: formData.ManagementFeeAmount ? parseFloat(formData.ManagementFeeAmount) : null,
        LateFeeDays: formData.LateFeeDays ? parseInt(formData.LateFeeDays) : null,
        LateFeeMethod: formData.LateFeeMethod,
        LateFeeAmount: formData.LateFeeAmount ? parseFloat(formData.LateFeeAmount) : null,
        investors: formData.investors.map((inv) => ({
          InvestorId: parseInt(inv.InvestorId),
          OwnershipPercentage: parseFloat(inv.OwnershipPercentage),
        })),
      };

      // Log the payload for debugging purposes
      console.log('Payload being sent to API:', payload);

      // Make API call using secureFetch to include Authorization header
      const response = await secureFetch('POST', API_ENDPOINTS.RentalPropertyAddProperty, payload);

      // Log the response for debugging
      console.log('Response from API:', response);

      // Check if the response is ok (status in range 200–299)
      if (response.Id) {
        const responseData = response; // Response is already parsed and contains {Id: 42}
        setSuccess('Property added successfully!');
        navigate(`/rental/property/${responseData.Id}`);
      } else {
        throw new Error('Failed to add property');
      }
    } catch (error) {
      // Log the error for debugging
      console.error('Error during form submission:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Add Property</h1>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <AddPropertyForm onSubmit={handleSubmit} propertyData={propertyData} loading={loading} />
    </div>
  );
};

export default AddProperty;
