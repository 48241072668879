import React from 'react';
import { useParams } from 'react-router-dom';

const Settings = () => {
  const { SettingsId } = useParams();

  return (
    <div className="Settings-container">
      <h1>Settings ID: {SettingsId}</h1>
    </div>
  );
};

export default Settings;
