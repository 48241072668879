import React from 'react';
import { useParams } from 'react-router-dom';

const Deal = () => {
  const { DealId } = useParams();

  return (
    <div className="Deal-container">
      <h1>Deal ID: {DealId}</h1>
    </div>
  );
};

export default Deal;
