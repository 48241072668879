export const containerSizes = {
    small: {
      width: "20vw",
      padding: "5px",
      margin: "0 auto",
      backgroundColor: "#8fafe3",
      border: "1px solid #ddd",
      borderRadius: "10px",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      transition: "box-shadow 0.3s ease",
      "&:hover": {
        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.15)",
      },
    },
    medium: {
      width: "40vw",
      padding: "5px",
      margin: "0 auto",
      backgroundColor: "#8fafe3",
      border: "1px solid #ddd",
      borderRadius: "10px",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      transition: "box-shadow 0.3s ease",
      "&:hover": {
        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.15)",
      },
    },
    large: {
      width: "60vw",
      padding: "5px",
      margin: "0 auto",
      backgroundColor: "#8fafe3",
      border: "1px solid #ddd",
      borderRadius: "10px",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      transition: "box-shadow 0.3s ease",
      "&:hover": {
        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.15)",
      },
    },
    xlarge: {
      width: "80vw",
      padding: "5px",
      margin: "0 auto",
      backgroundColor: "#8fafe3",
      border: "1px solid #ddd",
      borderRadius: "10px",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      transition: "box-shadow 0.3s ease",
      "&:hover": {
        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.15)",
      },
    },
  };
