import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Tab, Box } from "@mui/material";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";
import LeaseSummary from "./LeaseSummary";
import LeaseFinancials from "./LeaseFinancials";
import LeaseTenants from "./LeaseTenants";

const Lease = () => {
  const { leaseId } = useParams(); // Get leaseId from the URL
  const [leaseData, setLeaseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0); // State for handling active tab

  useEffect(() => {
    const fetchLeaseData = async () => {
      try {
        const response = await secureFetch(
          "GET",
          `${API_ENDPOINTS.RentalLeaseLease}?leaseId=${leaseId}`
        );
        setLeaseData(response); // Set the lease data from the API
      } catch (err) {
        console.error("Error fetching lease data:", err);
        setError(err.message || "An error occurred while fetching lease data.");
      } finally {
        setLoading(false);
      }
    };

    fetchLeaseData();
  }, [leaseId]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  if (loading) return <p>Loading lease data...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="lease-page">
      <h1>Lease Overview</h1>

      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="lease tabs">
        <Tab label="Summary" />
        <Tab label="Financials" />
        <Tab label="Tenants" />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <LeaseSummary lease={leaseData.lease} />
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <LeaseFinancials transactions={leaseData.leaseTransactions} />
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        <LeaseTenants tenants={leaseData.tenants} />
      </TabPanel>
    </div>
  );
};

function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default Lease;
