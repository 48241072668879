import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddVendorForm from './AddVendorForm';
import API_ENDPOINTS from '../../../../APIEndPointURL';
import { secureFetch } from '../../../../AuthorizeAPI';
import './AddVendor.css'; // Optional CSS for styling

const AddVendor = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    setLoading(true);
    setError(null);

    try {
      // POST vendor data using secureFetch
      const response = await secureFetch('POST', API_ENDPOINTS.ConstructionVendorAddVendor, formData);

      if (response.Id) {
        navigate('/construction/vendor/' + response.Id); // Redirect to the vendors page after successful addition
      } else {
        throw new Error('Failed to add vendor');
      }
    } catch (err) {
      console.error('Error adding vendor:', err);
      setError('Failed to add vendor.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-vendor-container">
      <h1>Add New Vendor</h1>
      {error && <p className="error">{error}</p>}
      <AddVendorForm onSubmit={handleSubmit} loading={loading} />
    </div>
  );
};

export default AddVendor;
