import React from 'react';
import { useParams } from 'react-router-dom';

const AddEmailCampaign = () => {
  const { AddEmailCampaignId } = useParams();

  return (
    <div className="AddEmailCampaign-container">
      <h1>AddEmailCampaign ID: {AddEmailCampaignId}</h1>
    </div>
  );
};

export default AddEmailCampaign;
