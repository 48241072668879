import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import AddLeadCategoryForm from "./AddLeadCategoryForm";
import API_ENDPOINTS from '../../../../APIEndPointURL';
import { secureFetch } from "../../../../AuthorizeAPI";
import './AddLeadCategory.css'; // Optional CSS file for styling

const AddLeadCategory = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook for navigation

  const handleSubmit = async (formData) => {
    setLoading(true);
    setError(null);

    try {
      // Make API request to create a new lead category
      const response = await secureFetch('POST', API_ENDPOINTS.MarketingLeadCategoryAddLeadCategories, JSON.stringify(formData));
      if (response.Id) {
        // Navigate back to the Lead Categories list after successful submission
        navigate('/marketing/lead-categories');
      } else {
        throw new Error("Failed to add lead category");
      }
    } catch (err) {
      setError(err.message || "Failed to add lead category");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-lead-category-container">
      <h1>Add Lead Category</h1>
      {error && <p className="error">{error}</p>}
      <AddLeadCategoryForm onSubmit={handleSubmit} loading={loading} />
    </div>
  );
};

export default AddLeadCategory;
