import React from 'react';
import { useParams } from 'react-router-dom';

const Applications = () => {
  const { ApplicationsId } = useParams();

  return (
    <div className="Applications-container">
      <h1>Applications ID: {ApplicationsId}</h1>
    </div>
  );
};

export default Applications;
