import React from 'react';

const LeaseFinancialsRent = ({ lease }) => {
  return (
    <div>
      <h3>Rent Information</h3>
      {/* Render any relevant rent data */}
      <p>Rent data goes here.</p>
    </div>
  );
};

export default LeaseFinancialsRent;
