import React, { useState } from "react";
import { Tabs, Tab, Box, Button } from "@mui/material";
import LeaseFinancialsLedger from "./LeaseFinancialsLedger";
import LeaseFinancialsDeposits from "./LeaseFinancialsDeposits";
import LeaseFinancialsRent from "./LeaseFinancialsRent";
import LeaseFinancialsSettings from "./LeaseFinancialsSettings";
import { useParams, useNavigate } from "react-router-dom";

const LeaseFinancials = ({ transactions }) => {
  const [subTabIndex, setSubTabIndex] = useState(0); // State for handling active sub-tab
  const navigate = useNavigate(); // Use navigate for redirecting to the add unit page
  const { leaseId } = useParams(); // Get leaseId from the URL

  const handleSubTabChange = (event, newValue) => {
    setSubTabIndex(newValue);
  };

  return (
    <div className="lease-financials">
      <Tabs
        value={subTabIndex}
        onChange={handleSubTabChange}
        aria-label="financials sub-tabs"
      >
        <Tab label="Ledger" />
        <Tab label="Deposits" />
        <Tab label="Rent" />
        <Tab label="Settings" />
      </Tabs>

      {/* Ledger Tab */}
      <TabPanel value={subTabIndex} index={0}>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate(`/rental/lease/${leaseId}/charge/add`)}
            sx={{ alignSelf: "flex-start" }} // Align the button to the top
          >
            Add Charge
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate(`/rental/lease/${leaseId}/payment/receive`)}
            sx={{ alignSelf: "flex-start" }} // Align the button to the top
          >
            Receive Payment
          </Button>
        </div>
        <LeaseFinancialsLedger transactions={transactions} />
      </TabPanel>

      {/* Deposits Tab */}
      <TabPanel value={subTabIndex} index={1}>
        <LeaseFinancialsDeposits transactions={transactions} />
      </TabPanel>
      
      {/* Rent Tab */}
      <TabPanel value={subTabIndex} index={2}>
        <LeaseFinancialsRent transactions={transactions} />
      </TabPanel>

      {/* Settings Tab */}
      <TabPanel value={subTabIndex} index={3}>
        <LeaseFinancialsSettings leaseId={leaseId} />
      </TabPanel>
    </div>
  );
};

// The TabPanel function
function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default LeaseFinancials;
