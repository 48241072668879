import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AddLeaseForm from './AddLeaseForm';
import { secureFetch } from '../../../../AuthorizeAPI';  // Assuming you have a secureFetch for API requests
import API_ENDPOINTS from '../../../../APIEndPointURL';  // Adjust as necessary
import './AddLease.css';

const AddLease = () => {
  const { unitId } = useParams();  // Get the unitId from the URL
  const [leaseData, setLeaseData] = useState({
    tenants: [],
    rentalUnits: [],
    leaseTerms: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();  // For navigation after successful submission

  useEffect(() => {
    const fetchLeaseData = async () => {
      setLoading(true);
      try {
        // Fetch tenants and other necessary data (you can modify the API endpoint as per your need)
        const response = await secureFetch('GET', `${API_ENDPOINTS.LeaseData}?unitId=${unitId}`);
        const parsedData = response; // Assume API returns the data in correct format

        setLeaseData(parsedData);
      } catch (error) {
        console.error('Error fetching lease data:', error);
        setError('Failed to load lease data.');
      } finally {
        setLoading(false);
      }
    };

    fetchLeaseData();
  }, [unitId]);

  const handleSubmit = async (formData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await secureFetch('POST', API_ENDPOINTS.RentalLeaseAddLease, formData);
      
      if (response.Id) {
        navigate(`/lease/${response.Id}`);  // Redirect to the newly created lease page
      } else {
        setError('Failed to add lease.');
      }
    } catch (error) {
      setError('Error submitting lease data.');
      console.error('Submission error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="AddLease-container">
      <h1>Add Lease for Unit {unitId}</h1>
      {error && <p className="error">{error}</p>}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <AddLeaseForm leaseData={leaseData} handleSubmit={handleSubmit} />
      )}
    </div>
  );
};

export default AddLease;
