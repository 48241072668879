import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles"; // Import useTheme

// Helper function to format currency
const formatCurrency = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(amount);
};

const LeaseFinancialsDeposits = ({ transactions = [] }) => {
  const theme = useTheme(); // Access the theme

  // Ensure that transactions are properly initialized, even if empty
  const liabilityTransactions = transactions?.filter(transaction => transaction.AccountType === "Liability") || [];

  // Function to calculate the total amount for a transaction (from LineItems)
  const getTransactionAmount = (transaction) => {
    // Check if transaction has LineItems (it seems the amount is sometimes in LineItems)
    if (transaction.LineItems && Array.isArray(transaction.LineItems)) {
      // Sum the amounts from the line items
      return transaction.LineItems.reduce((total, lineItem) => total + parseFloat(lineItem.Amount), 0);
    }
    // Fallback to transaction amount if LineItems is missing or empty
    return parseFloat(transaction.Amount || transaction.LineItemAmount || 0);
  };

  return (
    <Box sx={theme.containerSizes.large}>
      <h3>Deposit Liabilities</h3>
      {liabilityTransactions.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Account Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {liabilityTransactions.map((transaction) => (
              <TableRow key={transaction.Id}>
                <TableCell>{new Date(transaction.ReceivedDate || transaction.DueDate).toLocaleDateString()}</TableCell>
                <TableCell>{transaction.Description}</TableCell>
                {/* Use getTransactionAmount to ensure the correct amount is displayed */}
                <TableCell>{formatCurrency(getTransactionAmount(transaction))}</TableCell>
                <TableCell>{transaction.AccountName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p>No liability-related transactions found.</p>
      )}
    </Box>
  );
};

export default LeaseFinancialsDeposits;
