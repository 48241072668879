export const MuiTabs = {
    styleOverrides: {
        root: {
            borderBottom: "1px solid #e0e0e0",
        },
        indicator: {
            display: "none", // Hide the default indicator
        },
    },
};

export const MuiTab = {
    defaultProps: {
        disableRipple: true, // Disable the ripple effect
    },
    styleOverrides: {
        root: {
            maxWidth: "75px",
            fontSize: "0.875rem",
            padding: "6px 12px",
            backgroundColor: "transparent",
            transition: "none", // Disable the transition completely
            color: "#333333",
            "&:hover": {
                fontWeight: "bold",
                backgroundColor: "transparent", // No background color change on hover
                transition: "none", // Ensure no transition on hover as well
            },
            "&:focus": {
                backgroundColor: "transparent", // Remove any focus background color
                transition: "none", // No transition effect on focus
                outline: "none", // Remove any focus outline or effect
            },
            "&:active": {
                backgroundColor: "transparent", // No background color change on click/active
                transition: "none", // Ensure no transition on active
            },
            "&.Mui-selected": {
                fontWeight: "bold",
                color: "#008ffd", // Use the primary color when selected
                backgroundColor: "transparent", // No background color change on selection
                transition: "none", // Ensure no transition on selection
            },
        },
    },
};