import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CaseCategoriesTable from './CaseCategoriesTable';  // Import the table component
import API_ENDPOINTS from '../../../../APIEndPointURL';      // Import API endpoint constants
import { secureFetch } from '../../../../AuthorizeAPI';      // Import secureFetch function

const CaseCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // Fetch data using the secureFetch function
        const response = await secureFetch('GET', API_ENDPOINTS.CaseCaseCategoryCaseCategories);
        const parsedData = Array.isArray(response) ? response : JSON.parse(response.body);

        if (Array.isArray(parsedData)) {
          setCategories(parsedData);
        } else {
          console.error('Unexpected response format:', parsedData);
          setError('Invalid data format received.');
        }
      } catch (err) {
        console.error('Error fetching case categories:', err);
        setError('Failed to load case categories.');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories(); // Call the function to load categories
  }, []);

  return (
    <div className="case-categories-page">
      <div className="header">
        <h1>Case Categories</h1>
        <button className="add-case-category" onClick={() => navigate('/case/categories/add')}>
          Add Case Category
        </button>
      </div>

      {loading ? (
        <p>Loading case categories...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <CaseCategoriesTable categories={categories} />
      )}
    </div>
  );
};

export default CaseCategories;
