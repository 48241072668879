// src/pages/OwnershipAccounts.js
import React from 'react';
import OutstandingBalancesTable from './OutstandingBalancesTable';

const OutstandingBalances = () => {
  return (
    <div>
      <h1>Outstanding Balances</h1>
      <OutstandingBalancesTable />
    </div>
  );
};

export default OutstandingBalances;
