import React, { useState, useMemo, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, InputLabel, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const InvestorTable = ({ investors }) => {
  const [filterText, setFilterText] = useState("");
  const [sortModel, setSortModel] = useState([{ field: "InvestorName", sort: "asc" }]);
  const theme = useTheme();

  // Log investors data to ensure it contains the expected values
  useEffect(() => {
    console.log("Investors data:", investors);
  }, [investors]);

  // Filter investors based on the input search text
  const filteredInvestors = useMemo(() => {
    const lowerCaseFilter = filterText.toLowerCase();
    return investors.filter((investor) =>
      Object.keys(investor).some((key) =>
        String(investor[key]).toLowerCase().includes(lowerCaseFilter)
      )
    );
  }, [filterText, investors]);

  const columns = [
    {
      field: "InvestorName",
      headerName: "Investor Name",
      flex: 1.5,
      renderCell: (params) => {
        const investorName = `${params.row?.FirstName || ""} ${params.row?.LastName || ""}`.trim();
        return (
          <a
            href={`/rental/investor/${params?.row?.Id}`}
            style={{ color: "#007bff", textDecoration: "none" }}
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
          >
            {investorName || "N/A"}
          </a>
        );
      },
    },
    {
      field: "PhoneNumber",
      headerName: "Phone Number",
      flex: 1,
      renderCell: (params) => params.row?.PhoneNumber || "N/A",
    },
    {
      field: "Email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => params.row?.Email || "N/A",
    },
    {
      field: "AgreementPeriod",
      headerName: "Agreement Period",
      flex: 1.5,
      renderCell: (params) => {
        const startDate = params.row?.AgreementStartDate
          ? new Date(params.row?.AgreementStartDate).toLocaleDateString()
          : "N/A";
        const endDate = params.row?.AgreementEndDate
          ? new Date(params.row?.AgreementEndDate).toLocaleDateString()
          : "N/A";
        return startDate !== "N/A" && endDate !== "N/A" ? `${startDate} - ${endDate}` : "N/A";
      },
    },
    {
      field: "Address",
      headerName: "Address",
      flex: 2,
      renderCell: (params) => {
        const addressLine1 = params.row?.AddressLine1 || "N/A";
        const addressLine2 = params.row?.AddressLine2 ? ` ${params.row?.AddressLine2}` : "";
        return `${addressLine1}${addressLine2}`;
      },
    },
  ];

  return (
    <Box sx={theme.containerSizes.xlarge}>
      <InputLabel htmlFor="filter-investors" className="search-label" />
      <TextField
        id="filter-investors"
        variant="outlined"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        fullWidth
        placeholder="Type to search investors..."
        className="search-bar"
      />
      <DataGrid
        rows={filteredInvestors}
        columns={columns}
        rowHeight={40}
        sortingOrder={["asc", "desc"]}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        disableColumnFilter={false}
        disableColumnMenu={false}
        getRowId={(row) => row?.Id || Math.random()}
        autoHeight
        hideFooter
      />
    </Box>
  );
};

export default InvestorTable;
