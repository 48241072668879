import React from 'react';
import { useParams } from 'react-router-dom';

const Estimates = () => {
  const { EstimatesId } = useParams();

  return (
    <div className="Estimates-container">
      <h1>Estimates ID: {EstimatesId}</h1>
    </div>
  );
};

export default Estimates;
