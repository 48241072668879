import React from 'react';
import { useParams } from 'react-router-dom';

const AddUser = () => {
  const { AddUserId } = useParams();

  return (
    <div className="AddUser-container">
      <h1>AddUser ID: {AddUserId}</h1>
    </div>
  );
};

export default AddUser;
