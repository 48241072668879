import React from 'react';

// URL Routing
import { RoutesFile } from './RoutesFile'

// Cognito
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

// Styling
import './App.css';

// Used to call auth
Amplify.configure(awsconfig);

function App() {
  return (
    <div>
      <RoutesFile />
    </div>
  );
}

export default withAuthenticator(App);