import React from 'react';
import { useParams } from 'react-router-dom';

const ApplicationTemplate = () => {
  const { ApplicationTemplateId } = useParams();

  return (
    <div className="ApplicationTemplate-container">
      <h1>ApplicationTemplate ID: {ApplicationTemplateId}</h1>
    </div>
  );
};

export default ApplicationTemplate;
