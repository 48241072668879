import React from 'react';
import './Footer.css';
import OmniForceLogo from './OmniForceLogo.svg'; // Adjust the path based on your file structure

const Footer = () => {
  return (
    <footer className="footer">
      <img src={OmniForceLogo} alt="OmniForce Logo" className="footer-logo" />
      <p>&copy; {new Date().getFullYear()} OmniForce, Inc. All rights reserved. | Privacy | Security | Terms of Use</p>
    </footer>
  );
};

export default Footer;
