import React from 'react';
import { useParams } from 'react-router-dom';
import './Receivable.css';

const Receivable = () => {
  const { id } = useParams();

  return (
    <div className="Receivable-container">
      <h1>Receivable ID: {id}</h1>
    </div>
  );
};

export default Receivable;
