import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CaseChat from "./CaseChat";

const CaseSummary = ({ caseData, assigneeName, companyUserId }) => {
  const theme = useTheme();

  return (
    <div>
      <Box sx={theme.containerSizes.small}>
        <h2>Case Summary</h2>
        <p>
          <strong>Title:</strong> {caseData.Title}
        </p>
        <p>
          <strong>Description:</strong> {caseData.Description || "N/A"}
        </p>
        <p>
          <strong>Due Date:</strong>{" "}
          {caseData.DueDate
            ? new Date(caseData.DueDate).toLocaleDateString()
            : "N/A"}
        </p>
        <p>
          <strong>Assignee:</strong> {assigneeName || "Unassigned"}
        </p>
        <p>
          <strong>Permission to Enter:</strong>{" "}
          {caseData.TenantPermissionToEnter ? "Yes" : "No"}
        </p>
        <p>
          <strong>Pets:</strong> {caseData.Pets ? "Yes" : "No"}
        </p>
        <p>
          <strong>Completed:</strong> {caseData.IsCompleted ? "Yes" : "No"}
        </p>
        <p>
          <strong>Emergency:</strong> {caseData.IsEmergency ? "Yes" : "No"}
        </p>
        <p>
          <strong>Status:</strong> {caseData.Status}
        </p>
      </Box>


    </div>
  );
};

export default CaseSummary;
