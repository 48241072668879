import React from 'react';
import { useParams } from 'react-router-dom';  // Import useNavigate for navigation
import './Vendor.css';

const Vendor = () => {
  const { vendorId } = useParams();

  return (
    <div className="Vendor-container">
      <h1>Vendor ID: {vendorId}</h1>
    </div>
  );
};

export default Vendor;
