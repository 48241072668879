import React, { useState, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, InputLabel, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const AssociationTable = ({ data }) => {
  const [filterText, setFilterText] = useState("");
  const [sortModel, setSortModel] = useState([{ field: "Name", sort: "asc" }]);
  const theme = useTheme(); // Access the theme

  // Remove duplicate associations by checking unique Ids
  const uniqueAssociations = useMemo(() => {
    const seen = new Set();
    return data.filter((association) => {
      if (seen.has(association.Id)) {
        return false;
      }
      seen.add(association.Id);
      return true;
    });
  }, [data]);

  // Filter the associations across all relevant fields
  const filteredAssociations = useMemo(() => {
    const lowerCaseFilter = filterText.toLowerCase();
    return uniqueAssociations.filter((association) =>
      Object.keys(association).some((key) =>
        String(association[key]).toLowerCase().includes(lowerCaseFilter)
      )
    );
  }, [filterText, uniqueAssociations]);

  // Event handler for sorting changes
  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  // Prepare columns for DataGrid using 'flex' for relative widths
  const columns = [
    {
      field: "Name",
      headerName: "Association Name",
      flex: 2,
      renderCell: (params) => (
        <Link
          to={`/association/${params?.row?.Id}`}
          style={{
            color: "#007bff", // Blue color for the link
            textDecoration: "none", // Remove underline
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.textDecoration = "underline")
          }
          onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
        >
          {params?.row?.Name || "N/A"}
        </Link>
      ),
    },
    {
      field: "AddressLine1",
      headerName: "Address",
      flex: 2,
    },
    {
      field: "CityStateZip",
      headerName: "City, State",
      flex: 1.5, // Relative width smaller than the address field
      renderCell: (params) => {
        const city = params.row?.City || "N/A";
        const state = params.row?.State || "N/A";
        return `${city}, ${state}`;
      },
    },
    {
      field: "ZipCode",
      headerName: "Zip Code",
      flex: 1,
    },
    {
      field: "Reserve",
      headerName: "Reserve",
      flex: 1,
      valueGetter: (params) => {
        return params?.row?.Reserve ? `$${params.row.Reserve}` : "N/A";
      },
    },
    {
      field: "PropertyManager",
      headerName: "Property Manager",
      flex: 1,
      valueGetter: (params) => {
        const firstName = params?.row?.FirstName || "";
        const lastName = params?.row?.LastName || "";
        return firstName && lastName ? `${firstName} ${lastName}` : "N/A";
      },
    },
  ];

  return (
    <Box sx={theme.containerSizes.xlarge}>
      <InputLabel htmlFor="filter-associations" className="search-label" />
      <TextField
        id="filter-associations"
        variant="outlined"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        fullWidth
        placeholder="Type to search..."
        className="search-bar"
      />
      <DataGrid
        rows={filteredAssociations}
        columns={columns}
        rowHeight={40}
        sortingOrder={["asc", "desc"]}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        disableColumnFilter={false}
        disableColumnMenu={false}
        getRowId={(row) => row?.Id}
        autoHeight
        hideFooter
      />
    </Box>
  );
};

export default AssociationTable;
