import React, { useState } from 'react';
import Select from 'react-select';
import './AddCaseForm.css';

const AddCaseForm = ({ dropdownData, onSubmit, loading }) => {
  const [formData, setFormData] = useState({
    CaseCategoryId: '',
    ParentId: '',
    ParentType: '',
    Title: '',
    Description: '',
    DueDate: '',
    Assignee: '',
    TenantPermissionToEnter: 'false',
    Pets: 'false',
    IsEmergency: 'false',
    Status: '',
    CustomFields: {}
  });
  const [statusOptions, setStatusOptions] = useState([]);

  const trueFalseOptions = [
    { value: 'true', label: 'True' },
    { value: 'false', label: 'False' }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      ParentId: selectedOption.value,
      ParentType: selectedOption.ParentType,
    });
  };

  const handleCaseCategoryChange = (selectedOption) => {
    setFormData({ ...formData, CaseCategoryId: selectedOption.value });
    setStatusOptions(selectedOption.StatusOptions.map(option => ({ value: option, label: option })));
  };

  const handleAssigneeChange = (selectedOption) => {
    setFormData({ ...formData, Assignee: selectedOption.value });
  };

  const handleStatusChange = (selectedOption) => {
    setFormData({ ...formData, Status: selectedOption.value });
  };

  const handleTrueFalseChange = (selectedOption, name) => {
    setFormData({ ...formData, [name]: selectedOption.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData); // Call the onSubmit function passed from AddCase.js
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <label>
          Case Category (required):
          <Select
            options={dropdownData.caseCategories}
            onChange={handleCaseCategoryChange}
            placeholder="Select Case Category"
          />
        </label>
      </div>

      {statusOptions.length > 0 && (
        <div className="form-row">
          <label>
            Status:
            <Select
              options={statusOptions}
              onChange={handleStatusChange}
              placeholder="Select Status"
            />
          </label>
        </div>
      )}

      <div className="form-row">
        <label>
          Parent (Property or Association) (required):
          <Select
            options={[
              {
                label: <strong>Rental Properties</strong>,
                options: dropdownData.rentalProperties
              },
              {
                label: <strong>Associations</strong>,
                options: dropdownData.associations
              }
            ]}
            onChange={handleSelectChange}
            placeholder="Select Rental Property or Association"
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Title (required):
          <input type="text" name="Title" value={formData.Title} onChange={handleInputChange} required />
        </label>

        <label>
          Due Date:
          <input type="date" name="DueDate" value={formData.DueDate} onChange={handleInputChange} />
        </label>
      </div>

      <label>
        Description:
        <textarea name="Description" value={formData.Description} onChange={handleInputChange} />
      </label>

      <div className="form-row">
        <label>
          Assignee:
          <Select
            options={dropdownData.companyUsers}
            onChange={handleAssigneeChange}
            placeholder="Select Assignee"
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Tenant Permission to Enter:
          <Select
            options={trueFalseOptions}
            value={trueFalseOptions.find(option => option.value === formData.TenantPermissionToEnter)}
            onChange={(option) => handleTrueFalseChange(option, 'TenantPermissionToEnter')}
          />
        </label>

        <label>
          Pets:
          <Select
            options={trueFalseOptions}
            value={trueFalseOptions.find(option => option.value === formData.Pets)}
            onChange={(option) => handleTrueFalseChange(option, 'Pets')}
          />
        </label>

        <label>
          Emergency:
          <Select
            options={trueFalseOptions}
            value={trueFalseOptions.find(option => option.value === formData.IsEmergency)}
            onChange={(option) => handleTrueFalseChange(option, 'IsEmergency')}
          />
        </label>
      </div>

      <button type="submit" disabled={loading}>
        {loading ? 'Submitting...' : 'Add Case'}
      </button>
    </form>
  );
};

export default AddCaseForm;
