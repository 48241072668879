// src/Pages/Rental/Property/Property/Property.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs, Tab, Box, Typography, Grid, Button } from '@mui/material'; // Import Material UI components
import PropertyCases from './PropertyCases';
import PropertyInvestors from './PropertyInvestors';
import PropertyUnits from './PropertyUnits';
import API_ENDPOINTS from '../../../../APIEndPointURL';
import { secureFetch } from '../../../../AuthorizeAPI';
import './Property.css';

const Property = () => {
  const { propertyId } = useParams(); // Get propertyId from the URL
  const navigate = useNavigate(); // Use navigate for redirecting to the add unit page
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0); // State for handling active tab

  useEffect(() => {
    const fetchPropertyData = async () => {
      try {
        const response = await secureFetch('GET', `${API_ENDPOINTS.RentalPropertyProperty}?propertyId=${propertyId}`);

        if (response && response.property) {
          setPropertyData(response);
        } else {
          throw new Error('Invalid response format');
        }
      } catch (err) {
        console.error("Error fetching property data:", err);
        setError("Failed to load property data.");
      } finally {
        setLoading(false);
      }
    };

    fetchPropertyData();
  }, [propertyId]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  if (loading) return <p>Loading property data...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="property-page">
      <div className="property-header">
        <h1>Property Overview</h1>
      </div>

      {/* Tabs for navigating different sections */}
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="property tabs">
        <Tab label="Summary" disableRipple />
        <Tab label="Units" disableRipple />
        <Tab label="Cases" disableRipple />
      </Tabs>

      {/* Tab Panels */}
      <TabPanel value={tabIndex} index={0}>
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Property Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                Address
              </Typography>
              <Typography variant="body1">
                {propertyData.property.AddressLine1}, {propertyData.property.City}, {propertyData.property.State} {propertyData.property.ZipCode}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                Description
              </Typography>
              <Typography variant="body1">
                {propertyData.property.Description || "No description provided."}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6" color="primary">
                Amenities
              </Typography>
              <Typography variant="body1">
                {propertyData.property.PropertyAmenities || "No amenities listed."}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6" color="primary">
                Included in Rent
              </Typography>
              <Typography variant="body1">
                {propertyData.property.IncludedInRent || "No utilities included."}
              </Typography>
            </Grid>
          </Grid>

          <Box mt={4} mb={2}>
            <hr />
          </Box>

          <PropertyInvestors investors={propertyData.investors} />
        </Box>
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Box sx={{ flexGrow: 1 }}>
            <PropertyUnits units={propertyData.units} />
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate(`/rental/property/${propertyData.property.Id}/unit/add`)}
            sx={{ alignSelf: 'flex-start' }} // Align the button to the top
          >
            Add Unit
          </Button>
        </Box>
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        <PropertyCases cases={propertyData.cases} />
      </TabPanel>
    </div>
  );
};

function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default Property;
