import React from 'react';
import { useParams } from 'react-router-dom';

const AddApplicant = () => {
  const { AddApplicantId } = useParams();

  return (
    <div className="AddApplicant-container">
      <h1>AddApplicant ID: {AddApplicantId}</h1>
    </div>
  );
};

export default AddApplicant;
