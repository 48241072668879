import React from 'react';
import { useParams } from 'react-router-dom';

const AddEquipmentServiceRecord = () => {
  const { AddEquipmentServiceRecordId } = useParams();

  return (
    <div className="AddEquipmentServiceRecord-container">
      <h1>AddEquipmentServiceRecord ID: {AddEquipmentServiceRecordId}</h1>
    </div>
  );
};

export default AddEquipmentServiceRecord;
