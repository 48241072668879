// src/Pages/Association/AddAssociation.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import API_ENDPOINTS from "../../../../APIEndPointURL"; // Import the API endpoint
import { secureFetch } from '../../../../AuthorizeAPI'; // Import secureFetch for authenticated requests
import AddAssociationForm from "./AddAssociationForm"; // Import the form

const AddAssociation = () => {
  const navigate = useNavigate(); // Initialize navigate

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (formData) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const payload = {
        BankAccountId: parseInt(formData.BankAccountId),
        Name: formData.Name,
        AddressLine1: formData.AddressLine1,
        AddressLine2: formData.AddressLine2 || '',
        City: formData.City,
        State: formData.State,
        ZipCode: formData.ZipCode,
        Country: formData.Country,
        IsActive: true,
        Reserve: formData.Reserve ? parseFloat(formData.Reserve) : null,
        Description: formData.Description || '',
        YearBuilt: formData.YearBuilt ? parseInt(formData.YearBuilt) : null,
        TIN: formData.TIN || '',
        FiscalYearEndDate: formData.FiscalYearEndDate || null,
        AgreementStartDate: formData.AgreementStartDate || null,
        AgreementEndDate: formData.AgreementEndDate || null,
        PropertyManager: formData.PropertyManager ? parseInt(formData.PropertyManager) : null,
        ManagementFeeMethod: formData.ManagementFeeMethod || '',
        ManagementFeeAmount: formData.ManagementFeeAmount ? parseFloat(formData.ManagementFeeAmount) : null,
        LateFeeDays: formData.LateFeeDays ? parseInt(formData.LateFeeDays) : null,
        LateFeeMethod: formData.LateFeeMethod || '',
        LateFeeAmount: formData.LateFeeAmount ? parseFloat(formData.LateFeeAmount) : null,
      };

      const response = await secureFetch('POST', API_ENDPOINTS.AssociationAssociationAddAssociation, payload);

      // Handle successful response
      setSuccess('Association added successfully!');
      navigate(`/association/${response.Id}`); // Navigate to the newly created association page
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
      <div>
        <h1>Add Association</h1>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <AddAssociationForm onSubmit={handleSubmit} loading={loading} />
      </div>
  );
};

export default AddAssociation;
