import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGaugeHigh,
  faHome,
  faBuilding,
  faTools,
  faHouseFlag,
  faFileInvoiceDollar,
  faFolderOpen,
  faChartSimple,
  faRightFromBracket,
  faBullhorn,
  faBriefcase,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import OmniForceFullLogo from "./OmniForceFullLogo.png";
import OmniForceLogo from "./OmniForceLogo.svg";

function Navbar() {
  const { signOut } = useAuthenticator((context) => [context.signOut]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [openSubmenus, setOpenSubmenus] = useState({});

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const toggleSubmenu = (e, menu) => {
    e.preventDefault();
    setOpenSubmenus((prev) => {
      const newOpenSubmenus = { [menu]: !prev[menu] };
      return newOpenSubmenus;
    });
  };

  const handleMouseMove = (e) => {
    const distance = Math.abs(e.clientX);
    if (distance > 550) {
      setOpenSubmenus({});
    }
  };

  useEffect(() => {
    const events = [
      { event: "resize", handler: handleResize, target: window },
      { event: "mousemove", handler: handleMouseMove, target: document },
      { event: "click", handler: handleDocumentClick, target: document },
    ];
  
    events.forEach(({ event, handler, target }) => target.addEventListener(event, handler));
  
    return () => {
      events.forEach(({ event, handler, target }) => target.removeEventListener(event, handler));
    };
  }, []);
  
  const handleDocumentClick = (e) => {
    if (!e.target.closest(".navbar")) {
      setOpenSubmenus({});
    }
  };

  const handleSubmenuClick = (e) => {
    e.stopPropagation();
  };

  const logoSrc = isMobile ? OmniForceLogo : OmniForceFullLogo;

  return (
    <nav className="navbar">
      <img src={logoSrc} alt="Logo" className="navbar-logo" />
      <div className="logo-divider"></div>
      <button className="menu-toggle-button" onClick={toggleMenu}>
        ☰
      </button>
      <ul className={`navbar-links ${isMenuOpen ? "open" : ""}`}>

        {/*Dashboard*/}
        <li>
          <a href="/dashboard">
            <span className="icon-container">
              <FontAwesomeIcon icon={faGaugeHigh} />
            </span>{" "}
            Dashboard
          </a>
        </li>

        {/*Calendar*/}
        <li>
          <a href="/calendar">
            <span className="icon-container">
              <FontAwesomeIcon icon={faCalendarAlt} />
            </span>{" "}
            Calendar
          </a>
        </li>

        {/*Divider*/}
        <div className="navbar-divider"></div>

        {/*Rentals*/}
        <li
          onClick={(e) => toggleSubmenu(e, "rentals")}
          onMouseMove={(e) => handleMouseMove(e, "rentals")}
        >
          <a href="#rentals">
            <span className="icon-container">
              <FontAwesomeIcon icon={faHome} />
            </span>{" "}
            Rentals
          </a>
          <ul
            className={`submenu ${openSubmenus.rentals ? "open" : ""}`}
            onClick={handleSubmenuClick}
          >
            <li>
              <a href="/rental/properties">Properties</a>
              <a href="/rental/rent-roll">Rent Roll</a>
              <a href="/rental/tenants">Tenants</a>
              <a href="/rental/investors">Investors</a>
              <a href="/rental/outstanding-balances">Outstanding Balances</a>
              <a href="/rental/applications">Applications</a>
            </li>
          </ul>
        </li>

        {/*Association*/}
        <li
          onClick={(e) => toggleSubmenu(e, "associations")}
          onMouseMove={(e) => handleMouseMove(e, "associations")}
        >
          <a href="#associations">
            <span className="icon-container">
              <FontAwesomeIcon icon={faBuilding} />
            </span>{" "}
            Associations
          </a>
          <ul
            className={`submenu ${openSubmenus.associations ? "open" : ""}`}
            onClick={handleSubmenuClick}
          >
            <li>
              <a href="/association/associations">Associations</a>
              <a href="/association/ownership-accounts">Ownership Accounts</a>
              <a href="/association/owners-and-tenants">Association Owners and Tenants</a>
              <a href="/association/outstanding-balances">Outstanding Balances</a>
              <a href="/association/violations">Violations</a>
              <a href="/association/meetings">Meetings</a>
            </li>
          </ul>
        </li>

        {/*Realty*/}
        <li
          onClick={(e) => toggleSubmenu(e, "realty")}
          onMouseMove={(e) => handleMouseMove(e, "realty")}
        >
          <a href="#realty">
            <span className="icon-container">
              <FontAwesomeIcon icon={faHouseFlag} />
            </span>{" "}
            Realty
          </a>
          <ul
            className={`submenu ${openSubmenus.realty ? "open" : ""}`}
            onClick={handleSubmenuClick}
          >
            <li>
              <a href="/realty/listings">Listings</a>
              <a href="/realty/agents">Agents</a>
              <a href="/realty/deals">Deals</a>
              <a href="/realty/offers">Offers</a>
            </li>
          </ul>
        </li>

        {/*Construction*/}
        <li
          onClick={(e) => toggleSubmenu(e, "construction")}
          onMouseMove={(e) => handleMouseMove(e, "construction")}
        >
          <a href="#construction">
            <span className="icon-container">
              <FontAwesomeIcon icon={faTools} />
            </span>{" "}
            Construction
          </a>
          <ul
            className={`submenu ${openSubmenus.construction ? "open" : ""}`}
            onClick={handleSubmenuClick}
          >
            <li>
              <a href="/construction/projects">Projects</a>
              <a href="/construction/vendors">Vendors</a>
              <a href="/construction/marketplace">Marketplace</a>
            </li>
          </ul>
        </li>

        {/*Divider*/}
        <div className="navbar-divider"></div>

        {/*Case*/}
        <li
          onClick={(e) => toggleSubmenu(e, "cases")}
          onMouseMove={(e) => handleMouseMove(e, "cases")}
        >
          <a href="#cases">
            <span className="icon-container">
              <FontAwesomeIcon icon={faFolderOpen} />
            </span>{" "}
            Cases
          </a>
          <ul
            className={`submenu ${openSubmenus.cases ? "open" : ""}`}
            onClick={handleSubmenuClick}
          >
            <li>
              <a href="/case/dashboard">Dashboard</a>
              <a href="/case/cases">Cases</a>
              <a href="/case/time">Time</a>
            </li>
          </ul>
        </li>

        {/*Marketing*/}
        <li
          onClick={(e) => toggleSubmenu(e, "marketing")}
          onMouseMove={(e) => handleMouseMove(e, "marketing")}
        >
          <a href="#marketing">
            <span className="icon-container">
              <FontAwesomeIcon icon={faBullhorn} />
            </span>{" "}
            Marketing
          </a>
          <ul
            className={`submenu ${openSubmenus.marketing ? "open" : ""}`}
            onClick={handleSubmenuClick}
          >
            <li>
              <a href="/marketing/leads">Leads</a>
              <a href="/marketing/email-campaigns">Email Campaigns</a>
            </li>
          </ul>
        </li>

        {/*Finance*/}
        <li
          onClick={(e) => toggleSubmenu(e, "finance")}
          onMouseMove={(e) => handleMouseMove(e, "finance")}
        >
          <a href="#finance">
            <span className="icon-container">
              <FontAwesomeIcon icon={faFileInvoiceDollar} />
            </span>{" "}
            Finance
          </a>
          <ul
            className={`submenu ${openSubmenus.finance ? "open" : ""}`}
            onClick={handleSubmenuClick}
          >
            <li>
              <a href="/finance/bank-accounts">Bank Accounts</a>
              <a href="/finance/payables">Payables</a>
              <a href="/finance/receivables">Receivables</a>
              <a href="/finance/budgets">Budgets</a>
              <a href="/finance/chart-of-accounts">Chart of Accounts</a>
              <a href="/finance/services">Services</a>
            </li>
          </ul>
        </li>

        {/*Divider*/}
        <div className="navbar-divider"></div>

        {/*Company*/}
        <li
          onClick={(e) => toggleSubmenu(e, "company")}
          onMouseMove={(e) => handleMouseMove(e, "company")}
        >
          <a href="#company">
            <span className="icon-container">
              <FontAwesomeIcon icon={faBriefcase} />
            </span>{" "}
            Company
          </a>
          <ul
            className={`submenu ${openSubmenus.company ? "open" : ""}`}
            onClick={handleSubmenuClick}
          >
            <li>
              <a href="/company/equipment">Equipment</a>
              <a href="/company/users">Users</a>
              <a href="/company/user-roles">User Roles</a>
              <a href="/company/settings">Settings</a>
            </li>
          </ul>
        </li>

        {/*Reports*/}
        <li>
          <a href="/reports">
            <span className="icon-container">
              <FontAwesomeIcon icon={faChartSimple} />
            </span>{" "}
            Reports
          </a>
        </li>

        {/*Sign-Out*/}
        <li className="sign-out-container">
          <button className="sign-out-button" onClick={signOut}>
            <span className="icon-container">
              <FontAwesomeIcon icon={faRightFromBracket} />
            </span>{" "}
            Sign Out
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
