import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from '../../../../AuthorizeAPI'; // Import secureFetch
import AddCaseForm from './AddCaseForm'; // Import the form component
import './AddCase.css';

const AddCase = () => {
  const navigate = useNavigate();
  const [dropdownData, setDropdownData] = useState({
    rentalProperties: [],
    associations: [],
    caseCategories: [],
    companyUsers: []
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch dropdown data using secureFetch
    const fetchDropdownData = async () => {
      try {
        const response = await secureFetch('GET', API_ENDPOINTS.CaseCaseAddCase);
        const data = typeof response === 'string' ? JSON.parse(response) : response; // Ensure response is parsed
        
        setDropdownData({
          rentalProperties: data.rentalProperties.map(item => ({
            value: item.Id,
            label: item.AddressLine1,
            ParentType: 'RentalProperty'
          })),
          associations: data.associations.map(item => ({
            value: item.Id,
            label: item.Name,
            ParentType: 'Association'
          })),
          caseCategories: data.caseCategories.map(category => ({
            value: category.Id,
            label: category.Name,
            StatusOptions: category.StatusOptions
          })),
          companyUsers: data.companyUsers.map(user => ({
            value: user.Id,
            label: `${user.FirstName} ${user.LastName}`
          }))
        });
      } catch (err) {
        console.error("Error fetching dropdown data:", err);
        setError("Failed to load dropdown data.");
      }
    };

    fetchDropdownData();
  }, []);

  // Handle case submission
  const handleSubmit = async (formData) => {
    setLoading(true);
    try {
      const payload = {
        ...formData,
        CaseCategoryId: parseInt(formData.CaseCategoryId),
        ParentId: parseInt(formData.ParentId),
        Assignee: formData.Assignee ? parseInt(formData.Assignee) : null,
        TenantPermissionToEnter: formData.TenantPermissionToEnter === 'true',
        Pets: formData.Pets === 'true',
        IsEmergency: formData.IsEmergency === 'true',
        CreatedDateTime: new Date().toISOString(),
        CustomFields: formData.CustomFields || {},
      };

      const response = await secureFetch('POST', API_ENDPOINTS.CaseCaseAddCase, payload);

      if (!response) {
        throw new Error('Failed to add case');
      }

      // On success, redirect to all cases
      navigate('/case/cases');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-case-container">
      <h1>Add New Case</h1>
      {error && <p className="error">{error}</p>}
      <AddCaseForm dropdownData={dropdownData} onSubmit={handleSubmit} loading={loading} />
    </div>
  );
};

export default AddCase;
