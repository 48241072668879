import React, { useState, useMemo, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, InputLabel, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const AllCasesTable = ({ cases }) => {
  const [filterText, setFilterText] = useState("");
  const [sortModel, setSortModel] = useState([{ field: "Title", sort: "asc" }]);
  const theme = useTheme();

  // Log cases data to ensure it contains the expected values
  useEffect(() => {
    console.log("Cases data:", cases);
  }, [cases]);

  // Filter cases based on the input search text
  const filteredCases = useMemo(() => {
    const lowerCaseFilter = filterText.toLowerCase();
    return cases.filter((caseItem) =>
      Object.keys(caseItem).some((key) =>
        String(caseItem[key]).toLowerCase().includes(lowerCaseFilter)
      )
    );
  }, [filterText, cases]);

  const columns = [
    {
      field: "Title",
      headerName: "Case Title",
      flex: 2,
      renderCell: (params) => (
        <a
          href={`/case/${params?.row?.Id}`}
          style={{ color: "#007bff", textDecoration: "none" }}
          onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
          onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
        >
          {params?.row?.Title || "N/A"}
        </a>
      ),
    },
    {
      field: "ownername",
      headerName: "Owner Name",
      flex: 1,
      renderCell: (params) => {
        console.log("Owner Name Params:", params.row?.ownername);
        return params.row?.ownername || "N/A";
      },
    },
    {
      field: "totalincome",
      headerName: "Income",
      flex: 1,
      renderCell: (params) => {
        console.log("Income Params:", params.row?.totalincome);
        const income = parseFloat(params.row?.totalincome);
        return !isNaN(income) ? `$${income.toFixed(0)}` : "$0.00";
      },
    },
    {
      field: "totalexpenses",
      headerName: "Expense",
      flex: 1,
      renderCell: (params) => {
        console.log("Expense Params:", params.row?.totalexpenses);
        const expense = parseFloat(params.row?.totalexpenses);
        return !isNaN(expense) ? `$${expense.toFixed(0)}` : "$0.00";
      },
    },
    {
      field: "Description",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "ParentType",
      headerName: "Parent Type",
      flex: 1,
    },
  ];

  return (
    <Box sx={theme.containerSizes.xlarge}>
      <InputLabel htmlFor="filter-cases" className="search-label" />
      <TextField
        id="filter-cases"
        variant="outlined"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        fullWidth
        placeholder="Type to search cases..."
        className="search-bar"
      />
      <DataGrid
        rows={filteredCases}
        columns={columns}
        rowHeight={40}
        sortingOrder={["asc", "desc"]}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        disableColumnFilter={false}
        disableColumnMenu={false}
        getRowId={(row) => row?.Id || Math.random()}
        autoHeight
        hideFooter
      />
    </Box>
  );
};

export default AllCasesTable;
