import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddLeadForm from "./AddLeadForm";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";

const AddLead = () => {
  const [leadData, setLeadData] = useState({
    leadCategories: [],
    companyUsers: []
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLeadData = async () => {
      setLoading(true);
      try {
        // Fetch the data from the API
        const response = await secureFetch("GET", API_ENDPOINTS.MarketingLeadAddLead);

        // Check if the response is valid
        if (response && response.leadCategories && response.companyUsers) {
          setLeadData({
            leadCategories: response.leadCategories || [],
            companyUsers: response.companyUsers || []
          });
        } else {
          console.error("Invalid response from API:", response);
          throw new Error("Invalid response from API");
        }
      } catch (error) {
        console.error("Error fetching lead data:", error);
        setError("Failed to load lead data.");
      } finally {
        setLoading(false);
      }
    };

    fetchLeadData();
  }, []);

  const handleSubmit = async (formData) => {
    setLoading(true);
    setError(null);

    try {
      const payload = {
        ...formData
      };

      const response = await secureFetch("POST", API_ENDPOINTS.MarketingLeadAddLead, JSON.stringify(payload));

      if (response.Id) {
        navigate("/marketing/lead/" + response.Id);
      } else {
        throw new Error("Failed to add lead");
      }
    } catch (error) {
      console.error("Error submitting lead:", error);
      setError("Failed to add lead.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Add Lead</h1>
      {error && <p className="error">{error}</p>}
      <AddLeadForm
        onSubmit={handleSubmit}
        leadData={leadData}
        loading={loading}
      />
    </div>
  );
};

export default AddLead;
