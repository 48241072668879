import React from 'react';
import { useParams } from 'react-router-dom';

const EquipmentServiceRecord = () => {
  const { EquipmentServiceRecordId } = useParams();

  return (
    <div className="EquipmentServiceRecord-container">
      <h1>EquipmentServiceRecord ID: {EquipmentServiceRecordId}</h1>
    </div>
  );
};

export default EquipmentServiceRecord;
