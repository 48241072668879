import React, { useState } from "react";
import "./AddBudgetForm.css";
import { Button } from '@mui/material'; // Import Material UI components

const AddBudgetForm = ({ onSubmit, loading }) => {
  const [formData, setFormData] = useState({
    Name: "",
    StartMonth: "",
    FiscalYear: "",
    ApprovalStatus: "",
    PropertyType: "",
    PropertyId: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Budget Name (required):
        <input
          type="text"
          name="Name"
          value={formData.Name}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Start Month (required):
        <select
          name="StartMonth"
          value={formData.StartMonth}
          onChange={handleInputChange}
          required
        >
          <option value="">Select a month</option>
          <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      </label>

      <label>
        Fiscal Year (required):
        <input
          type="number"
          name="FiscalYear"
          value={formData.FiscalYear}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Approval Status (required):
        <select
          name="ApprovalStatus"
          value={formData.ApprovalStatus}
          onChange={handleInputChange}
          required
        >
          <option value="">Select a status</option>
          <option value="Draft">Draft</option>
          <option value="PendingApproval">Pending Approval</option>
          <option value="Approved">Approved</option>
        </select>
      </label>

      <label>
        Property Type (optional):
        <input
          type="text"
          name="PropertyType"
          value={formData.PropertyType}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Property ID (optional):
        <input
          type="number"
          name="PropertyId"
          value={formData.PropertyId}
          onChange={handleInputChange}
        />
      </label>

      <Button variant="contained" color="primary" size='large' type="submit" disabled={loading}>
        {loading ? "Submitting..." : "Add Budget"}
      </Button>
    </form>
  );
};

export default AddBudgetForm;
