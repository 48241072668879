import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChartOfAccountsTable from "./ChartOfAccountsTable";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";
import './ChartOfAccounts.css';
import { Button } from '@mui/material'; // Import Material UI components

const ChartOfAccounts = () => {
  const [chartOfAccounts, setChartOfAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChartOfAccounts = async () => {
      try {
        // Perform the secure fetch and log the response
        const response = await secureFetch('GET', API_ENDPOINTS.FinanceChartOfAccountChartOfAccounts);
        console.log("API Response:", response); // Check if it's an array directly

        // Since the response is already an array, we directly set it
        if (Array.isArray(response)) {
          setChartOfAccounts(response);
        } else {
          console.error("Unexpected response format:", response);
        }
      } catch (err) {
        console.error("Error fetching chart of accounts:", err);
        setError("Failed to load chart of accounts data.");
      } finally {
        setLoading(false);
      }
    };

    fetchChartOfAccounts();
  }, []);

  const handleAddAccount = () => {
    navigate('/finance/chart-of-accounts/add');
  };

  return (
    <div className="chart-of-accounts-container">
      <div className="chart-of-accounts-header">
        <h1>Chart of Accounts</h1>
        <Button variant="contained" color="primary" size='large' onClick={handleAddAccount}>
          Add Account
        </Button>
      </div>

      {loading ? (
        <p>Loading chart of accounts data...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ChartOfAccountsTable chartOfAccounts={chartOfAccounts} />
      )}
    </div>
  );
};

export default ChartOfAccounts;
