import React from 'react';
import { useParams } from 'react-router-dom';

const Application = () => {
  const { ApplicationId } = useParams();

  return (
    <div className="Application-container">
      <h1>Application ID: {ApplicationId}</h1>
    </div>
  );
};

export default Application;
