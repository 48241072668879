import React, { useEffect, useState } from "react";
import ViolationsTable from "./ViolationsTable";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";
import './Violations.css'; // Import the CSS file for styling

const Violations = () => {
  const [violations, setViolations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchViolations = async () => {
      try {
        // Fetch data using secureFetch
        const response = await secureFetch('GET', API_ENDPOINTS.AssociationViolationViolations);
        const parsedData = Array.isArray(response) ? response : JSON.parse(response.body);

        if (Array.isArray(parsedData)) {
          setViolations(parsedData);
        } else {
          console.error("Unexpected response format:", parsedData);
        }
      } catch (err) {
        console.error("Error fetching violations:", err);
        setError("Failed to load violations data.");
      } finally {
        setLoading(false);
      }
    };

    fetchViolations();
  }, []);

  return (
    <div>
      <h1>Violations</h1>

      {loading ? (
        <p>Loading violations data...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ViolationsTable violations={violations} />
      )}
    </div>
  );
};

export default Violations;
