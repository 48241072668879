import React, { useEffect, useState } from "react";
import RentRollTable from "./RentRollTable";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";
import './RentRoll.css'; // Import the CSS file for styling

const RentRoll = () => {
  const [rentRoll, setRentRoll] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRentRoll = async () => {
      try {
        const response = await secureFetch('GET', API_ENDPOINTS.RentalLeaseRentRoll);

        // Check if the response is an array and set it directly
        if (Array.isArray(response)) {
          setRentRoll(response);
        } else {
          throw new Error("Unexpected response format: Expected an array.");
        }
      } catch (err) {
        console.error("Error fetching rent roll data:", err);
        setError(err.message || "Failed to load rent roll data.");
      } finally {
        setLoading(false);
      }
    };

    fetchRentRoll();
  }, []);

  return (
    <div className="rent-roll">
      <h1>Rent Roll</h1>

      {loading ? (
        <p>Loading rent roll data...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <RentRollTable rentRoll={rentRoll} />
      )}
    </div>
  );
};

export default RentRoll;
