import React, { useState, useMemo, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, InputLabel, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const RentRollTable = ({ rentRoll }) => {
  const [filterText, setFilterText] = useState("");
  const [sortModel, setSortModel] = useState([{ field: "AddressTenant", sort: "asc" }]);
  const theme = useTheme();

  // Log rentRoll data to ensure it contains the expected values
  useEffect(() => {
    console.log("Rent Roll data:", rentRoll);
  }, [rentRoll]);

  // Filter rentRoll based on the input search text
  const filteredRentRoll = useMemo(() => {
    const lowerCaseFilter = filterText.toLowerCase();
    return rentRoll.filter((lease) =>
      Object.keys(lease).some((key) =>
        String(lease[key]).toLowerCase().includes(lowerCaseFilter)
      )
    );
  }, [filterText, rentRoll]);

  const columns = [
    {
      field: "AddressTenant",
      headerName: "Address | Tenant Name",
      flex: 2,
      renderCell: (params) => {
        const address = params.row?.AddressLine1 || "N/A";
        const tenantName =
          params.row?.FirstName && params.row?.LastName
            ? `${params.row.FirstName} ${params.row.LastName}`
            : "N/A";
        return (
          <a
            href={`/rental/lease/${params?.row?.Id}`}
            style={{ color: "#007bff", textDecoration: "none" }}
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
          >
            {`${address} | ${tenantName}`}
          </a>
        );
      },
    },
    {
      field: "LeaseStartDate",
      headerName: "Lease Start Date",
      flex: 1,
      renderCell: (params) => {
        return params.row?.LeaseStartDate
          ? new Date(params.row.LeaseStartDate).toLocaleDateString()
          : "N/A";
      },
    },
    {
      field: "LeaseEndDate",
      headerName: "Lease End Date",
      flex: 1,
      renderCell: (params) => {
        return params.row?.LeaseEndDate
          ? new Date(params.row.LeaseEndDate).toLocaleDateString()
          : "N/A";
      },
    },
    {
      field: "LeaseType",
      headerName: "Lease Type",
      flex: 1,
      renderCell: (params) => {
        return params.row?.LeaseType || "N/A";
      },
    },
    {
      field: "Notes",
      headerName: "Notes",
      flex: 1.5,
      renderCell: (params) => {
        return params.row?.Notes || "N/A";
      },
    },
  ];

  return (
    <Box sx={theme.containerSizes.xlarge}>
      <InputLabel htmlFor="filter-rent-roll" className="search-label" />
      <TextField
        id="filter-rent-roll"
        variant="outlined"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        fullWidth
        placeholder="Type to search rent roll..."
        className="search-bar"
      />
      <DataGrid
        rows={filteredRentRoll}
        columns={columns}
        rowHeight={40}
        sortingOrder={["asc", "desc"]}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        disableColumnFilter={false}
        disableColumnMenu={false}
        getRowId={(row) => row?.Id || Math.random()}
        autoHeight
        hideFooter
      />
    </Box>
  );
};

export default RentRollTable;
