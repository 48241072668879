import React from 'react';
import { useParams } from 'react-router-dom';

const ProductAndService = () => {
  const { ProductAndServiceId } = useParams();

  return (
    <div className="ProductAndService-container">
      <h1>ProductAndService ID: {ProductAndServiceId}</h1>
    </div>
  );
};

export default ProductAndService;
