import React from 'react';
import { useParams } from 'react-router-dom';

const Time = () => {
  const { TimeId } = useParams();

  return (
    <div className="Time-container">
      <h1>Time ID: {TimeId}</h1>
    </div>
  );
};

export default Time;
