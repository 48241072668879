// AuthorizeAPI.js
import BASE_URL from './APIEndPointURL';
import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

// Axios request interceptor to automatically add the idToken
axiosInstance.interceptors.request.use(
  (config) => {
    try {
      // Get the LastAuthUser from localStorage
      const lastAuthUserKey = 'CognitoIdentityServiceProvider.4bd6d9irn0ujughqq2sd98gdq6.LastAuthUser';
      const lastAuthUser = localStorage.getItem(lastAuthUserKey);

      if (lastAuthUser) {
        // Construct the idToken key for the current LastAuthUser
        const idTokenKey = `CognitoIdentityServiceProvider.4bd6d9irn0ujughqq2sd98gdq6.${lastAuthUser}.idToken`;

        // Retrieve the idToken from localStorage
        const idToken = localStorage.getItem(idTokenKey);

        if (idToken) {
          // Set the Authorization header with the idToken
          config.headers.Authorization = `Bearer ${idToken}`;
        } else {
          console.warn('idToken not found in localStorage');
        }
      } else {
        console.warn('LastAuthUser not found in localStorage');
      }
    } catch (error) {
      console.error('Error in request interceptor:', error); // Log any errors
    }

    return config;
  },
  (error) => {
    // Handle the error
    console.error('Request Interceptor Error:', error);
    return Promise.reject(error);
  }
);

// Example of a global API request function using the axiosInstance
export const secureFetch = async (method, url, data = null) => {
  try {

    const response = await axiosInstance({
      method: method,
      url: url,
      data: data,
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error('API request error:', error);
    throw error;
  }
};

export default axiosInstance;
