export const palette = {
    primary: {
      main: "#008ffd",
    },
    secondary: {
      main: "#13e697",
    },
    error: {
      main: "#f44336",
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
    text: {
      primary: "#333333",
      secondary: "#ffffff",
    },
  };
  