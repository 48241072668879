import React from 'react';
import { useParams } from 'react-router-dom';

const EmailCampaign = () => {
  const { EmailCampaignId } = useParams();

  return (
    <div className="EmailCampaign-container">
      <h1>EmailCampaign ID: {EmailCampaignId}</h1>
    </div>
  );
};

export default EmailCampaign;
