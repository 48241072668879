import React from 'react';

const CaseCategoriesTable = ({ categories }) => {
  return (
    <table className="case-categories-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Description</th>
          <th>Custom Fields</th>
          <th>Status Options</th>
        </tr>
      </thead>
      <tbody>
        {categories.length > 0 ? (
          categories.map((category) => (
            <tr key={category.Id}>
              <td>{category.Id}</td>
              <td>{category.Name}</td>
              <td>{category.Description}</td>
              <td>{JSON.stringify(category.CustomFields)}</td>
              <td>{category.StatusOptions.join(', ')}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5">No categories available.</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default CaseCategoriesTable;
