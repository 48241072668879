import React from 'react';
import { useParams } from 'react-router-dom';

const Inspections = () => {
  const { InspectionsId } = useParams();

  return (
    <div className="Inspections-container">
      <h1>Inspections ID: {InspectionsId}</h1>
    </div>
  );
};

export default Inspections;
