import React from 'react';
import { useParams } from 'react-router-dom';

const ReceivePayment = () => {
  const { leaseId } = useParams();

  return (
    <div className="ReceivePayment-container">
      <h1>Receive Payment for Lease ID: {leaseId}</h1>
    </div>
  );
};

export default ReceivePayment;
