import React from 'react';
import { useParams } from 'react-router-dom';

const Lockboxes = () => {
  const { LockboxesId } = useParams();

  return (
    <div className="Lockboxes-container">
      <h1>Lockboxes ID: {LockboxesId}</h1>
    </div>
  );
};

export default Lockboxes;
