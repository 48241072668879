import React from 'react';
import { useParams } from 'react-router-dom';

const Applicant = () => {
  const { ApplicantId } = useParams();

  return (
    <div className="Applicant-container">
      <h1>Applicant ID: {ApplicantId}</h1>
    </div>
  );
};

export default Applicant;
