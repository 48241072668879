import React, { useState, useMemo, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, InputLabel, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const BankAccountsTable = ({ bankAccounts }) => {
  const [filterText, setFilterText] = useState("");
  const [sortModel, setSortModel] = useState([{ field: "Name", sort: "asc" }]);
  const theme = useTheme();

  // Log bankAccounts data to ensure it contains the expected values
  useEffect(() => {
    console.log("Bank Accounts data:", bankAccounts);
  }, [bankAccounts]);

  // Filter bank accounts based on the input search text
  const filteredBankAccounts = useMemo(() => {
    const lowerCaseFilter = filterText.toLowerCase();
    return bankAccounts.filter((account) =>
      Object.keys(account).some((key) =>
        String(account[key]).toLowerCase().includes(lowerCaseFilter)
      )
    );
  }, [filterText, bankAccounts]);

  const columns = [
    {
      field: "Name",
      headerName: "Account Name",
      flex: 2,
      renderCell: (params) => (
        <a
          href={`/finance/bank-account/${params?.row?.Id}`}
          style={{ color: "#007bff", textDecoration: "none" }}
          onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
          onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
        >
          {params?.row?.Name || "N/A"}
        </a>
      ),
    },
    {
      field: "BankAccountType",
      headerName: "Bank Account Type",
      flex: 1.5,
      renderCell: (params) => params.row?.BankAccountType || "N/A",
    },
    {
      field: "Description",
      headerName: "Description",
      flex: 2,
      renderCell: (params) => params.row?.Description || "N/A",
    },
  ];

  return (
    <Box sx={theme.containerSizes.xlarge}>
      <InputLabel htmlFor="filter-bank-accounts" className="search-label" />
      <TextField
        id="filter-bank-accounts"
        variant="outlined"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        fullWidth
        placeholder="Type to search bank accounts..."
        className="search-bar"
      />
      <DataGrid
        rows={filteredBankAccounts}
        columns={columns}
        rowHeight={40}
        sortingOrder={["asc", "desc"]}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        disableColumnFilter={false}
        disableColumnMenu={false}
        getRowId={(row) => row?.Id || Math.random()}
        autoHeight
        hideFooter
      />
    </Box>
  );
};

export default BankAccountsTable;
