import React, { useState } from 'react';
import './AddChartOfAccountsForm.css';
import { Button } from '@mui/material'; // Import Material UI components

const AddChartOfAccountsForm = ({ parentAccounts, onSubmit, loading }) => {
  const [formData, setFormData] = useState({
    AccountNumber: '',
    AccountName: '',
    AccountType: '',
    ParentAccountId: '',
    Description: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Account Number (optional):
        <input
          type="text"
          name="AccountNumber"
          value={formData.AccountNumber}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Account Name (required):
        <input
          type="text"
          name="AccountName"
          value={formData.AccountName}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Account Type (required):
        <select
          name="AccountType"
          value={formData.AccountType}
          onChange={handleInputChange}
          required
        >
          <option value="">Select an Account Type</option>
          <option value="Current Asset">Current Asset</option>
          <option value="Fixed Asset">Fixed Asset</option>
          <option value="Current Liability">Current Liability</option>
          <option value="Long-Term Liability">Long-Term Liability</option>
          <option value="Equity">Equity</option>
          <option value="Income">Income</option>
          <option value="Non-Operating Income">Non-Operating Income</option>
          <option value="Operating Expense">Operating Expense</option>
          <option value="Non-Operating Expense">Non-Operating Expense</option>
        </select>
      </label>

      <label>
        Parent Account (optional):
        <select
          name="ParentAccountId"
          value={formData.ParentAccountId}
          onChange={handleInputChange}
        >
          <option value="">Select a Parent Account</option>
          {parentAccounts.map((account) => (
            <option key={account.Id} value={account.Id}>
              {account.AccountName}
            </option>
          ))}
        </select>
      </label>

      <label>
        Description (optional):
        <textarea
          name="Description"
          value={formData.Description}
          onChange={handleInputChange}
        />
      </label>

      <Button variant="contained" color="primary" size='large' type="submit" disabled={loading}>
        {loading ? 'Submitting...' : 'Add Chart of Accounts'}
      </Button>
    </form>
  );
};

export default AddChartOfAccountsForm;
