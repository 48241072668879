import React from 'react';
import { useParams } from 'react-router-dom';

const EditInspectionTemplate = () => {
  const { EditInspectionTemplateId } = useParams();

  return (
    <div className="EditInspectionTemplate-container">
      <h1>EditInspectionTemplate ID: {EditInspectionTemplateId}</h1>
    </div>
  );
};

export default EditInspectionTemplate;
