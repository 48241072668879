// src/Pages/Rental/Unit/UnitSummary.js
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const UnitSummary = ({ unit }) => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Unit Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" color="primary">
            Address
          </Typography>
          <Typography variant="body1">
            {unit.AddressLine1}, {unit.AddressLine2 ? unit.AddressLine2 + ', ' : ''}
            {unit.City}, {unit.State} {unit.ZipCode}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6" color="primary">
            Bedrooms
          </Typography>
          <Typography variant="body1">{unit.Bedrooms}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6" color="primary">
            Bathrooms
          </Typography>
          <Typography variant="body1">{unit.Bathrooms}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6" color="primary">
            Square Footage
          </Typography>
          <Typography variant="body1">{unit.Sqft} sq ft</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6" color="primary">
            Market Rent
          </Typography>
          <Typography variant="body1">${unit.MarketRent}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" color="primary">
            Description
          </Typography>
          <Typography variant="body1">{unit.Description || 'N/A'}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" color="primary">
            Amenities
          </Typography>
          <Typography variant="body1">
            {unit.Amenities ? unit.Amenities.replace(/[{}]/g, '') : 'N/A'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UnitSummary;
