import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import VendorsTable from './VendorsTable';
import API_ENDPOINTS from '../../../../APIEndPointURL';
import { secureFetch } from '../../../../AuthorizeAPI';
import './Vendors.css'; // Optional CSS for styling
import { Button } from '@mui/material'; // Import Material UI components

const Vendors = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate for routing

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await secureFetch('GET', API_ENDPOINTS.ConstructionVendorVendors);
        const parsedData = Array.isArray(response) ? response : JSON.parse(response.body);

        if (Array.isArray(parsedData)) {
          setVendors(parsedData);
        } else {
          console.error('Unexpected response format:', parsedData);
          setError('Unexpected response format');
        }
      } catch (err) {
        console.error('Error fetching vendors:', err);
        setError('Failed to load vendors data.');
      } finally {
        setLoading(false);
      }
    };

    fetchVendors();
  }, []);

  return (
    <div className="vendors-container">
      <div className="vendors-header">
        <h1>Vendors</h1>
        {/* Add Vendor Button */}
        <Button variant="contained" color="primary" size='large' onClick={() => navigate('/construction/vendor/add')}>
          Add Vendor
        </Button>
      </div>

      {loading ? (
        <p>Loading vendors...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <VendorsTable vendors={vendors} />
      )}
    </div>
  );
};

export default Vendors;
