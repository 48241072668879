import React, { useEffect, useState } from "react";
import ReceivablesTable from "./ReceivablesTable";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";
import './Receivables.css'; // Optional CSS file for styling

const Receivables = () => {
  const [receivables, setReceivables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReceivables = async () => {
      try {
        const response = await secureFetch('GET', API_ENDPOINTS.FinanceReceivableReceivables);

        // Directly use the response if it's already an array
        if (Array.isArray(response)) {
          setReceivables(response);
        } else {
          console.error("Unexpected response format:", response);
          setError("Unexpected response format.");
        }
      } catch (err) {
        console.error("Error fetching receivables:", err);
        setError("Failed to load receivables data.");
      } finally {
        setLoading(false);
      }
    };

    fetchReceivables();
  }, []);

  return (
    <div>
      <h1>Receivables</h1>

      {loading ? (
        <p>Loading receivables data...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ReceivablesTable receivables={receivables} />
      )}
    </div>
  );
};

export default Receivables;
