import React from "react";
import { Button } from '@mui/material'; // Import Material UI components

const AddBankAccountForm = ({ onSubmit, loading }) => {
  const [formData, setFormData] = React.useState({
    Name: '',
    Description: '',
    BankAccountType: '',
    RoutingNumber: '',
    AccountNumber: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData); // Call the onSubmit function passed from AddBankAccount.js
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Account Name (required):
        <input
          type="text"
          name="Name"
          value={formData.Name}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Description (optional):
        <textarea
          name="Description"
          value={formData.Description}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Bank Account Type (required):
        <input
          type="text"
          name="BankAccountType"
          value={formData.BankAccountType}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Routing Number (optional):
        <input
          type="text"
          name="RoutingNumber"
          value={formData.RoutingNumber}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Account Number (optional):
        <input
          type="text"
          name="AccountNumber"
          value={formData.AccountNumber}
          onChange={handleInputChange}
        />
      </label>

      <Button variant="contained" color="primary" size='large' type="submit" disabled={loading}>
        {loading ? 'Submitting...' : 'Add Bank Account'}
      </Button>
    </form>
  );
};

export default AddBankAccountForm;
