import React from 'react';
import { useParams } from 'react-router-dom';

const AddReceivable = () => {
  const { AddReceivableId } = useParams();

  return (
    <div className="AddReceivable-container">
      <h1>AddReceivable ID: {AddReceivableId}</h1>
    </div>
  );
};

export default AddReceivable;
