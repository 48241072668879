import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddUnitForm from './AddUnitForm';
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from '../../../../AuthorizeAPI'; // Import secureFetch for authenticated requests
import './AddUnit.css';

const AddUnit = () => {
  const { propertyId } = useParams(); // Extract propertyId from the URL
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    CompanyId: '',
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    State: '',
    ZipCode: '',
    Country: 'USA',
    Sqft: '',
    MarketRent: '',
    Bedrooms: '',
    Bathrooms: '',
    Description: '',
    Amenities: []
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchRentalPropertyData = async () => {
      try {
        console.log(`Fetching from: ${API_ENDPOINTS.RentalUnitAddUnit}?propertyId=${propertyId}`);
        
        const response = await secureFetch('GET', `${API_ENDPOINTS.RentalUnitAddUnit}?propertyId=${propertyId}`);
        
        // Log the full response to check if it's structured as expected
        console.log('Full response:', response);

        const data = response;

        if (data) {
          setFormData(prevFormData => ({
            ...prevFormData,
            AddressLine1: data.AddressLine1 || '',
            City: data.City || '',
            State: data.State || '',
            ZipCode: data.ZipCode || '',
            CompanyId: data.CompanyId || ''
          }));
        } else {
          console.error('No data returned for property');
        }
      } catch (error) {
        console.error('Error fetching property data:', error);
        setError(error.message);
      }
    };

    fetchRentalPropertyData();
  }, [propertyId]);

  const handleSubmit = async (payload) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
  
    try {
      console.log('Submitting payload:', payload);
      const response = await secureFetch('POST', API_ENDPOINTS.RentalUnitAddUnit, payload);
  
      // Check if the response contains the inserted data, and throw an error if it doesn't
      if (!response || !response.AddressLine1) {
        throw new Error("Failed to add rental unit");
      }
  
      setSuccess("Rental unit added successfully!");
      navigate(`/rental/property/${propertyId}`);
    } catch (error) {
      console.error('Error submitting unit:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div>
      <h1>Add Rental Unit</h1>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <AddUnitForm 
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleSubmit}
        loading={loading}
        propertyId={propertyId} // Pass propertyId to the form
      />
    </div>
  );
};

export default AddUnit;
