import React, { useState, useMemo, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, InputLabel, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const BudgetsTable = ({ budgets }) => {
  const [filterText, setFilterText] = useState("");
  const [sortModel, setSortModel] = useState([{ field: "Name", sort: "asc" }]);
  const theme = useTheme();

  // Log budgets data to ensure it contains the expected values
  useEffect(() => {
    console.log("Budgets data:", budgets);
  }, [budgets]);

  // Filter budgets based on the input search text
  const filteredBudgets = useMemo(() => {
    const lowerCaseFilter = filterText.toLowerCase();
    return budgets.filter((budget) =>
      Object.keys(budget).some((key) =>
        String(budget[key]).toLowerCase().includes(lowerCaseFilter)
      )
    );
  }, [filterText, budgets]);

  const columns = [
    {
      field: "Name",
      headerName: "Budget Name",
      flex: 2,
      renderCell: (params) => (
        <a
          href={`/finance/budget/${params?.row?.Id}`}
          style={{ color: "#007bff", textDecoration: "none" }}
          onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
          onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
        >
          {params?.row?.Name || "N/A"}
        </a>
      ),
    },
    {
      field: "StartMonth",
      headerName: "Start Month",
      flex: 1.5,
      renderCell: (params) => params.row?.StartMonth || "N/A",
    },
    {
      field: "FiscalYear",
      headerName: "Fiscal Year",
      flex: 1.5,
      renderCell: (params) => params.row?.FiscalYear || "N/A",
    },
    {
      field: "ApprovalStatus",
      headerName: "Approval Status",
      flex: 1.5,
      renderCell: (params) => params.row?.ApprovalStatus || "N/A",
    },
  ];

  return (
    <Box sx={theme.containerSizes.xlarge}>
      <InputLabel htmlFor="filter-budgets" className="search-label" />
      <TextField
        id="filter-budgets"
        variant="outlined"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        fullWidth
        placeholder="Type to search budgets..."
        className="search-bar"
      />
      <DataGrid
        rows={filteredBudgets}
        columns={columns}
        rowHeight={40}
        sortingOrder={["asc", "desc"]}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        disableColumnFilter={false}
        disableColumnMenu={false}
        getRowId={(row) => row?.Id || Math.random()}
        autoHeight
        hideFooter
      />
    </Box>
  );
};

export default BudgetsTable;
