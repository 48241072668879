import React from 'react';
import { useParams } from 'react-router-dom';

const EmailCampaigns = () => {
  const { EmailCampaignsId } = useParams();

  return (
    <div className="EmailCampaigns-container">
      <h1>EmailCampaigns ID: {EmailCampaignsId}</h1>
    </div>
  );
};

export default EmailCampaigns;
