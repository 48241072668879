import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import Box from '@mui/material/Box'; // Import MUI Box for containers
import Table from '@mui/material/Table'; // Import MUI Table components
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const PropertyInvestors = ({ investors }) => {
  const theme = useTheme(); // Access the theme

  return (
    <Box sx={theme.containerSizes.medium}> {/* Apply the small container */}
      <h3>Property Investors</h3>
      {investors.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Investor Name</TableCell>
              <TableCell>Ownership Percentage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {investors.map((investor) => (
              <TableRow key={investor.InvestorId}>
                <TableCell>
                  <Link to={`/rental/investor/${investor.InvestorId}`}>
                    {`${investor.FirstName} ${investor.LastName}`}
                  </Link>
                </TableCell>
                <TableCell>{investor.OwnershipPercentage}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p>No investors available for this property.</p>
      )}
    </Box>
  );
};

export default PropertyInvestors;
