import React from 'react';
import { useParams } from 'react-router-dom';

const Equipment = () => {
  const { EquipmentId } = useParams();

  return (
    <div className="Equipment-container">
      <h1>Equipment ID: {EquipmentId}</h1>
    </div>
  );
};

export default Equipment;
