import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import Box from '@mui/material/Box'; // Import MUI Box for containers
import Table from '@mui/material/Table'; // Import MUI Table components
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const PropertyUnits = ({ units }) => {
  const theme = useTheme(); // Access the theme

  return (
    <Box sx={theme.containerSizes.medium}> {/* Apply the small container */}
      <h3>Property Units</h3>
      {units.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Unit Number</TableCell>
              <TableCell>Bedrooms</TableCell>
              <TableCell>Bathrooms</TableCell>
              <TableCell>Market Rent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {units.map((unit) => (
              <TableRow key={unit.Id}>
                <TableCell>
                  <Link to={`/rental/unit/${unit.Id}`}>
                    {unit.AddressLine2 ? unit.AddressLine2 : 'N/A'}
                  </Link>
                </TableCell>
                <TableCell>{unit.Bedrooms || 'N/A'}</TableCell>
                <TableCell>{unit.Bathrooms || 'N/A'}</TableCell>
                <TableCell>{unit.MarketRent ? `$${unit.MarketRent}` : 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p>No units available for this property.</p>
      )}
    </Box>
  );
};

export default PropertyUnits;
