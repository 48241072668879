import React from 'react';
import { useParams } from 'react-router-dom';

const CaseCategory = () => {
  const { CaseCategoryId } = useParams();

  return (
    <div className="CaseCategory-container">
      <h1>CaseCategory ID: {CaseCategoryId}</h1>
    </div>
  );
};

export default CaseCategory;
