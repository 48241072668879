import React from 'react';
import { useParams } from 'react-router-dom';

const AddInspection = () => {
  const { AddInspectionId } = useParams();

  return (
    <div className="AddInspection-container">
      <h1>AddInspection ID: {AddInspectionId}</h1>
    </div>
  );
};

export default AddInspection;
