import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AssociationTable from "./AssociationTable";
import './Associations.css'; // Import the CSS file for styling
import API_ENDPOINTS from '../../../../APIEndPointURL';
import { secureFetch } from '../../../../AuthorizeAPI';
import { Button } from '@mui/material'; // Import Material UI components

const Associations = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleAddAssociation = () => {
    navigate('/association/add'); // Adjust the path as per your routes
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Use secureFetch to make the authenticated API call
        const response = await secureFetch('GET', API_ENDPOINTS.AssociationAssociationAssociations);

        // Parse the response if necessary (depends on API response format)
        const data = Array.isArray(response) ? response : JSON.parse(response.body);

        if (Array.isArray(data)) {
          setData(data);
        } else {
          console.error('Unexpected response format:', data);
        }
      } catch (error) {
        console.error('Error fetching associations:', error);
        setError('Failed to fetch associations.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="header-row">
        <h1>Associations</h1>
        <Button variant="contained" color="primary" size='large' onClick={handleAddAssociation}>
          Add Association
        </Button>
      </div>

      {loading ? (
        <p>Loading associations...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <AssociationTable data={data} />
      )}
    </div>
  );
};

export default Associations;
