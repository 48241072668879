import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import CasesTable from "./CasesTable";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";
import './Cases.css'; // Optional CSS file for styling
import { Button } from '@mui/material'; // Import Material UI components

const Cases = () => {
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate hook

  useEffect(() => {
    const fetchCases = async () => {
      try {
        // Fetch data using secureFetch
        const response = await secureFetch('GET', API_ENDPOINTS.CaseCaseCases);
        const parsedData = Array.isArray(response) ? response : JSON.parse(response.body);

        if (Array.isArray(parsedData)) {
          setCases(parsedData);
        } else {
          console.error("Unexpected response format:", parsedData);
        }
      } catch (err) {
        console.error("Error fetching cases:", err);
        setError("Failed to load cases data.");
      } finally {
        setLoading(false);
      }
    };

    fetchCases();
  }, []);

  return (
    <div className="all-cases-page">
      <div className="all-cases-header">
        <h1>All Cases</h1>
        <div className="button-group" style={{ display: 'flex', gap: '10px' }}>
          {/* Add Case Button */}
          <Button variant="contained" color="primary" size="large" onClick={() => navigate('/case/add')}>
            Add Case
          </Button>

          {/* Case Categories Button */}
          <Button variant="contained" color="primary" size="large" onClick={() => navigate('/case/categories')}>
            Case Categories
          </Button>
        </div>
      </div>

      {loading ? (
        <p>Loading cases data...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <CasesTable cases={cases} />
      )}
    </div>
  );
};

export default Cases;
