import React, { useState } from 'react';

const AddCaseCategoriesForm = ({ onSubmit, loading }) => {
  const [formData, setFormData] = useState({
    Name: '',
    Description: '',
    CustomFields: '',  // Custom fields as JSON string
    StatusOptions: ''  // Comma-separated status options
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <label>
          Name (required):
          <input 
            type="text" 
            name="Name" 
            value={formData.Name} 
            onChange={handleInputChange} 
            required 
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Description (optional):
          <textarea 
            name="Description" 
            value={formData.Description} 
            onChange={handleInputChange}
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Custom Fields (JSON format):
          <textarea 
            name="CustomFields" 
            value={formData.CustomFields} 
            onChange={handleInputChange} 
            placeholder='{"key": "value"}'
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Status Options (comma-separated):
          <input 
            type="text" 
            name="StatusOptions" 
            value={formData.StatusOptions} 
            onChange={handleInputChange} 
            placeholder="Open, In Progress, Closed" 
          />
        </label>
      </div>

      <button type="submit" disabled={loading}>
        {loading ? 'Submitting...' : 'Add Case Category'}
      </button>
    </form>
  );
};

export default AddCaseCategoriesForm;
