import React from 'react';
import { useParams } from 'react-router-dom';

const Meetings = () => {
  const { MeetingsId } = useParams();

  return (
    <div className="Meetings-container">
      <h1>Meetings ID: {MeetingsId}</h1>
    </div>
  );
};

export default Meetings;
