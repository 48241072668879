import React from 'react';
import { useParams } from 'react-router-dom';
import './OwnershipAccount.css';

const OwnershipAccount = () => {
  const { ownershipAccountId } = useParams();

  return (
    <div className="OwnershipAccount-container">
      <h1>OwnershipAccount ID: {ownershipAccountId}</h1>
    </div>
  );
};

export default OwnershipAccount;
