import React from 'react';
import { useParams } from 'react-router-dom';

const Meeting = () => {
  const { MeetingId } = useParams();

  return (
    <div className="Meeting-container">
      <h1>Meeting ID: {MeetingId}</h1>
    </div>
  );
};

export default Meeting;
