import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

const LeadsTable = ({ leads }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="leads table">
        <TableHead>
          <TableRow>
            <TableCell>Lead Name</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>City, State</TableCell>
            <TableCell>Lead Category</TableCell>
            <TableCell>Assignee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leads.map((lead) => {
            const cityState = lead.City && lead.State
              ? `${lead.City}, ${lead.State}`
              : "N/A";

            return (
              <TableRow key={lead.Id}>
                <TableCell>
                  <Link to={`/marketing/lead/${lead.Id}`}>
                    {lead.FirstName} {lead.LastName ? lead.LastName : ""}
                  </Link>
                </TableCell>
                <TableCell>{lead.Company ? lead.Company : "N/A"}</TableCell>
                <TableCell>{lead.PhoneNumber ? lead.PhoneNumber : "N/A"}</TableCell>
                <TableCell>{lead.Email ? lead.Email : "N/A"}</TableCell>
                <TableCell>{cityState}</TableCell>
                <TableCell>{lead.LeadCategory ? lead.LeadCategory : "N/A"}</TableCell>
                <TableCell>{lead.AssigneeFirstName && lead.AssigneeLastName 
                  ? `${lead.AssigneeFirstName} ${lead.AssigneeLastName}` 
                  : "Unassigned"}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LeadsTable;
