import React, { useEffect, useState } from "react";
import OwnersAndTenantsTable from "./OwnersAndTenantsTable";
import API_ENDPOINTS from "../../../APIEndPointURL";
import { secureFetch } from "../../../AuthorizeAPI";
import './AssociationOwnersAndTenants.css'; // Import the CSS file for styling

const AssociationOwnersAndTenants = () => {
  const [ownersAndTenants, setOwnersAndTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOwnersAndTenants = async () => {
      try {
        // Fetch data using secureFetch
        const response = await secureFetch('GET', API_ENDPOINTS.AssociationAssociationOwnersAndTenants);
        const parsedData = Array.isArray(response) ? response : JSON.parse(response.body);

        if (Array.isArray(parsedData)) {
          setOwnersAndTenants(parsedData);
        } else {
          console.error("Unexpected response format:", parsedData);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    fetchOwnersAndTenants();
  }, []);

  return (
    <div className="owners-tenants-section">
      <h1>Association Owners and Tenants</h1>

      {loading ? (
        <p>Loading data...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <OwnersAndTenantsTable ownersAndTenants={ownersAndTenants} />
      )}
    </div>
  );
};

export default AssociationOwnersAndTenants;
