import React, { useState } from "react"; 
import Select from 'react-select';
import './AddPropertyForm.css';
import { Button, Box } from '@mui/material'; // Import Material UI components
import { useTheme } from '@mui/material/styles'; // Import useTheme

const AddPropertyForm = ({ onSubmit, propertyData = {}, loading }) => {
  const theme = useTheme(); // Access the theme

  const [formData, setFormData] = useState({
    BankAccountId: '',
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    State: '',
    ZipCode: '',
    Country: 'USA',
    Description: '',
    RentalType: '',
    PropertyManager: '',
    Reserve: '',
    YearBuilt: '',
    IncludedInRent: [],
    PropertyAmenities: [],
    ManagementFeeMethod: '',
    ManagementFeeAmount: '',
    LateFeeDays: '',
    LateFeeMethod: '',
    LateFeeAmount: '',
    investors: [],
  });

  const [ownershipError, setOwnershipError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInvestorChange = (selectedOptions) => {
    const investors = selectedOptions
      ? selectedOptions.map(option => ({ InvestorId: option.value, OwnershipPercentage: 0 }))
      : [];
    setFormData({ ...formData, investors });
  };

  const handleOwnershipChange = (index, value) => {
    const updatedInvestors = [...formData.investors];
    updatedInvestors[index].OwnershipPercentage = parseFloat(value);
    setFormData({ ...formData, investors: updatedInvestors });
  };

  const validateOwnershipPercentages = () => {
    const totalOwnership = formData.investors.reduce((sum, inv) => sum + inv.OwnershipPercentage, 0);
    if (totalOwnership !== 100) {
      setOwnershipError("Total ownership percentage must equal 100%");
      return false;
    }
    setOwnershipError(null);
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateOwnershipPercentages()) {
      onSubmit(formData);
    }
  };

  return (
    <Box sx={theme.containerSizes.medium}> {/* Apply the medium container */}
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label>
            Bank Account (required):
            <select name="BankAccountId" value={formData.BankAccountId} onChange={handleInputChange} required>
              <option value="">Select a Bank Account</option>
              {propertyData.bankAccounts?.length > 0 ? (
                propertyData.bankAccounts.map((account) => (
                  <option key={account.Id} value={account.Id}>{account.Name}</option>
                ))
              ) : (
                <option disabled>Loading Bank Accounts...</option>
              )}
            </select>
          </label>
        </div>

        <div className="form-row">
          <label>Address Line 1 (required): <input type="text" name="AddressLine1" value={formData.AddressLine1} onChange={handleInputChange} required /></label>
          <label>Address Line 2 (optional): <input type="text" name="AddressLine2" value={formData.AddressLine2} onChange={handleInputChange} /></label>
        </div>

        <div className="form-row">
          <label>City (required): <input type="text" name="City" value={formData.City} onChange={handleInputChange} required /></label>
          <label>State (required): <input type="text" name="State" value={formData.State} onChange={handleInputChange} required /></label>
          <label>Zip Code (required): <input type="text" name="ZipCode" value={formData.ZipCode} onChange={handleInputChange} required /></label>
        </div>

        <label>
          Rental Type (required):
          <select name="RentalType" value={formData.RentalType} onChange={handleInputChange} required>
            <option value="">Select a Rental Type</option>
            {propertyData.rentalTypes?.length > 0 ? (
              propertyData.rentalTypes.map((type) => <option key={type} value={type}>{type}</option>)
            ) : (
              <option disabled>Loading Rental Types...</option>
            )}
          </select>
        </label>

        <label>Included in Rent (optional):
          <Select
            isMulti
            options={propertyData.includedInRentOptions?.map(item => ({ label: item, value: item })) || []}
            value={formData.IncludedInRent.map(item => ({ label: item, value: item }))}
            onChange={(selected) => setFormData({ ...formData, IncludedInRent: selected.map(s => s.value) })}
            closeMenuOnSelect={false}
          />
        </label>

        <label>Property Amenities (optional):
          <Select
            isMulti
            options={propertyData.propertyAmenitiesOptions?.map(item => ({ label: item, value: item })) || []}
            value={formData.PropertyAmenities.map(item => ({ label: item, value: item }))}
            onChange={(selected) => setFormData({ ...formData, PropertyAmenities: selected.map(s => s.value) })}
            closeMenuOnSelect={false}
          />
        </label>

        <label>Investors (required):
          <Select
            isMulti
            options={propertyData.investors?.map(investor => ({ label: investor.Name, value: investor.Id })) || []}
            onChange={handleInvestorChange}
          />
        </label>

        {formData.investors.length > 0 && (
          <div>
            <h4>Ownership Allocation</h4>
            {formData.investors.map((investor, index) => (
              <div key={investor.InvestorId} className="ownership-row">
                <span>{propertyData.investors.find(inv => inv.Id === investor.InvestorId)?.Name}</span>
                <input
                  type="number"
                  value={investor.OwnershipPercentage}
                  onChange={(e) => handleOwnershipChange(index, e.target.value)}
                  placeholder="Ownership %"
                  required
                  min="0"
                  max="100"
                />
              </div>
            ))}
          </div>
        )}

        {ownershipError && <p className="error">{ownershipError}</p>}

        <Button variant="contained" color="primary" size='large' type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Add Property'}
        </Button>
      </form>
    </Box>
  );
};

export default AddPropertyForm;
