import React from 'react';
import { useParams } from 'react-router-dom';

const AddLockbox = () => {
  const { AddLockboxId } = useParams();

  return (
    <div className="AddLockbox-container">
      <h1>AddLockbox ID: {AddLockboxId}</h1>
    </div>
  );
};

export default AddLockbox;
