import React from 'react';
import { useParams } from 'react-router-dom';

const Users = () => {
  const { UsersId } = useParams();

  return (
    <div className="Users-container">
      <h1>Users ID: {UsersId}</h1>
    </div>
  );
};

export default Users;
