import React from 'react';
import { useParams } from 'react-router-dom';  // Import useNavigate for navigation
import './Lead.css';

const Lead = () => {
  const { leadId } = useParams();

  return (
    <div className="Lead-container">
      <h1>Lead ID: {leadId}</h1>
    </div>
  );
};

export default Lead;
