import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab, Box } from '@mui/material';
import API_ENDPOINTS from '../../../../APIEndPointURL';
import { secureFetch } from '../../../../AuthorizeAPI';
import CaseSummary from './CaseSummary';
import FinancialSummary from './FinancialSummary';
import Time from './Time';

const Case = () => {
  const { caseId } = useParams(); // Get caseId from the URL
  const [caseData, setCaseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0); // State for handling active tab

  useEffect(() => {
    const fetchCaseData = async () => {
      try {
        const response = await secureFetch('GET', `${API_ENDPOINTS.CaseCaseCase}?caseId=${caseId}`);
        setCaseData(response); // Set the case data from the API
      } catch (err) {
        console.error('Error fetching case data:', err);
        setError(err.message || 'An error occurred while fetching case data.');
      } finally {
        setLoading(false);
      }
    };

    fetchCaseData();
  }, [caseId]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  if (loading) return <p>Loading case data...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="case-page">
      <h1>Case Overview</h1>

      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="case tabs">
        <Tab label="Summary" />
        <Tab label="Financials" />
        <Tab label="Time" />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <CaseSummary caseData={caseData.case} assigneeName={caseData.assigneeName} />
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <FinancialSummary transactions={caseData.transactions} />
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        <Time employeeTime={caseData.employeeTime} totalHoursWorked={caseData.totalHoursWorked} />
      </TabPanel>
    </div>
  );
};

function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default Case;
