import React from 'react';
import { useParams } from 'react-router-dom';

const AddInvestorBankAccount = () => {
  const { AddInvestorBankAccountId } = useParams();

  return (
    <div className="AddInvestorBankAccount-container">
      <h1>AddInvestorBankAccount ID: {AddInvestorBankAccountId}</h1>
    </div>
  );
};

export default AddInvestorBankAccount;
