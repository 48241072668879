import React from 'react';
import { useParams } from 'react-router-dom';

const AddService = () => {
  const { AddServiceId } = useParams();

  return (
    <div className="AddService-container">
      <h1>AddService ID: {AddServiceId}</h1>
    </div>
  );
};

export default AddService;
