// URL Routing
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Pages
//// Association
import AddAssociation from './Pages/Association/Association/AddAssociation/AddAssociation';
import Association from './Pages/Association/Association/Association/Association';
import Associations from './Pages/Association/Association/Associations/Associations';

import AssociationOwnersAndTenants from './Pages/Association/AssociationOwnersAndTenants/AssociationOwnersAndTenants';

import AddMeeting from './Pages/Association/Meeting/AddMeeting/AddMeeting';
import Meeting from './Pages/Association/Meeting/Meeting/Meeting';
import Meetings from './Pages/Association/Meeting/Meetings/Meetings';

import OutstandingBalances from './Pages/Association/OutstandingBalances/OutstandingBalances';

import AddOwner from './Pages/Association/Owner/AddOwner/AddOwner';
import Owner from './Pages/Association/Owner/Owner/Owner';

import AddOwnershipAccount from './Pages/Association/OwnershipAccount/AddOwnershipAccount/AddOwnershipAccount';
import OwnershipAccount from './Pages/Association/OwnershipAccount/OwnershipAccount/OwnershipAccount';
import OwnershipAccounts from './Pages/Association/OwnershipAccount/OwnershipAccounts/OwnershipAccounts';

import AddTenant from './Pages/Association/Tenant/AddTenant/AddTenant';
import AssociationTenant from './Pages/Association/Tenant/Tenant/Tenant';

import AddAssociationUnit from './Pages/Association/Unit/AddUnit/AddUnit';
import AssociationUnit from './Pages/Association/Unit/Unit/Unit';

import AddViolation from './Pages/Association/Violation/AddViolation/AddViolation';
import Violation from './Pages/Association/Violation/Violation/Violation';
import Violations from './Pages/Association/Violation/Violations/Violations';


//// Calendar
import Day from './Pages/Calendar/Day/Day';
import Month from './Pages/Calendar/Month/Month';
import Week from './Pages/Calendar/Week/Week';
import Year from './Pages/Calendar/Year/Year';


//// Case
import AddCase from './Pages/Case/Case/AddCase/AddCase';
import Case from './Pages/Case/Case/Case/Case';
import Cases from './Pages/Case/Case/Cases/Cases';

import AddCaseCategories from './Pages/Case/CaseCategory/AddCaseCategories/AddCaseCategories';
import CaseCategories from './Pages/Case/CaseCategory/CaseCategories/CaseCategories';
import CaseCategory from './Pages/Case/CaseCategory/CaseCategory/CaseCategory';

import CaseDashboard from './Pages/Case/CaseDashboard/CaseDashboard';

import AddEstimate from './Pages/Case/Estimate/AddEstimate/AddEstimate';
import Estimate from './Pages/Case/Estimate/Estimate/Estimate';
import Estimates from './Pages/Case/Estimate/Estimates/Estimates';

import AddTime from './Pages/Case/Time/AddTime/AddTime';
import Time from './Pages/Case/Time/Time/Time';
import Times from './Pages/Case/Time/Times/Times';


//// Company
import AddEquipment from './Pages/Company/Equipment/AddEquipment/AddEquipment';
import AllEquipment from './Pages/Company/Equipment/AllEquipment/AllEquipment';
import Equipment from './Pages/Company/Equipment/Equipment/Equipment';

import AddEquipmentServiceRecord from './Pages/Company/EquipmentServiceRecord/AddEquipmentServiceRecord/AddEquipmentServiceRecord';
import EquipmentServiceRecord from './Pages/Company/EquipmentServiceRecord/EquipmentServiceRecord/EquipmentServiceRecord';

import Settings from './Pages/Company/Settings/Settings';

import AddUser from './Pages/Company/User/AddUser/AddUser';
import User from './Pages/Company/User/User/User';
import Users from './Pages/Company/User/Users/Users';


//// Construction
import Marketplace from './Pages/Construction/Marketplace/Marketplace';

import Projects from './Pages/Construction/Projects/Projects';

import AddService from './Pages/Construction/Service/AddService/AddService';
import Service from './Pages/Construction/Service/Service/Service';
import Services from './Pages/Construction/Service/Services/Services';

import AddServiceArea from './Pages/Construction/ServiceArea/AddServiceArea/AddServiceArea';
import ServiceArea from './Pages/Construction/ServiceArea/ServiceArea/ServiceArea';

import AddVendor from './Pages/Construction/Vendor/AddVendor/AddVendor';
import Vendor from './Pages/Construction/Vendor/Vendor/Vendor';
import Vendors from './Pages/Construction/Vendor/Vendors/Vendors';

import AddVendorStaff from './Pages/Construction/VendorStaff/AddVendorStaff/AddVendorStaff';
import VendorStaff from './Pages/Construction/VendorStaff/VendorStaff/VendorStaff';
import AllVendorStaff from './Pages/Construction/VendorStaff/AllVendorStaff/AllVendorStaff';


//// Dashboard
import Dashboard from './Pages/Dashboard/Dashboard';


//// Finance
import AddBankAccount from './Pages/Finance/BankAccount/AddBankAccount/AddBankAccount';
import BankAccount from './Pages/Finance/BankAccount/BankAccount/BankAccount';
import BankAccounts from './Pages/Finance/BankAccount/BankAccounts/BankAccounts';

import AddBudget from './Pages/Finance/Budget/AddBudget/AddBudget';
import Budget from './Pages/Finance/Budget/Budget/Budget';
import Budgets from './Pages/Finance/Budget/Budgets/Budgets';

import AddChartOfAccounts from './Pages/Finance/ChartOfAccount/AddChartOfAccounts/AddChartOfAccounts';
import ChartOfAccounts from './Pages/Finance/ChartOfAccount/ChartOfAccounts/ChartOfAccounts';

import AddPayable from './Pages/Finance/Payable/AddPayable/AddPayable';
import Payable from './Pages/Finance/Payable/Payable/Payable';
import Payables from './Pages/Finance/Payable/Payables/Payables';

import AddProductAndService from './Pages/Finance/ProductAndService/AddProductAndService/AddProductAndService';
import ProductAndService from './Pages/Finance/ProductAndService/ProductAndService/ProductAndService';
import ProductAndServices from './Pages/Finance/ProductAndService/ProductAndServices/ProductAndServices';

import AddReceivable from './Pages/Finance/Receivable/AddReceivable/AddReceivable';
import Receivable from './Pages/Finance/Receivable/Receivable/Receivable';
import Receivables from './Pages/Finance/Receivable/Receivables/Receivables';

import AddReconciliation from './Pages/Finance/Reconciliation/AddReconciliation/AddReconciliation';
import Reconciliation from './Pages/Finance/Reconciliation/Reconciliation/Reconciliation';
import Reconciliations from './Pages/Finance/Reconciliation/Reconciliations/Reconciliations';


//// Marketing
import AddEmailCampaign from './Pages/Marketing/EmailCampaign/AddEmailCampaign/AddEmailCampaign';
import EmailCampaign from './Pages/Marketing/EmailCampaign/EmailCampaign/EmailCampaign';
import EmailCampaigns from './Pages/Marketing/EmailCampaign/EmailCampaigns/EmailCampaigns';

import AddLead from './Pages/Marketing/Lead/AddLead/AddLead';
import Lead from './Pages/Marketing/Lead/Lead/Lead';
import Leads from './Pages/Marketing/Lead/Leads/Leads';

import AddLeadCategory from './Pages/Marketing/LeadCategory/AddLeadCategory/AddLeadCategory';
import LeadCategories from './Pages/Marketing/LeadCategory/LeadCategories/LeadCategories';


//// Misc
import AddInspection from './Pages/Misc/Inspection/AddInspection/AddInspection';
import Inspection from './Pages/Misc/Inspection/Inspection/Inspection';
import Inspections from './Pages/Misc/Inspection/Inspections/Inspections';

import EditInspectionTemplate from './Pages/Misc/InspectionTemplate/EditInspectionTemplate/EditInspectionTemplate';
import InspectionTemplate from './Pages/Misc/InspectionTemplate/InspectionTemplate/InspectionTemplate';

import AddLockbox from './Pages/Misc/Lockbox/AddLockbox/AddLockbox';
import Lockbox from './Pages/Misc/Lockbox/Lockbox/Lockbox';
import Lockboxes from './Pages/Misc/Lockbox/Lockboxes/Lockboxes';


//// Realty
import AddAgent from './Pages/Realty/Agent/AddAgent/AddAgent';
import Agent from './Pages/Realty/Agent/Agent/Agent';
import Agents from './Pages/Realty/Agent/Agents/Agents';

import AddDeal from './Pages/Realty/Deal/AddDeal/AddDeal';
import Deal from './Pages/Realty/Deal/Deal/Deal';
import Deals from './Pages/Realty/Deal/Deals/Deals';

import AddListing from './Pages/Realty/Listing/AddListing/AddListing';
import Listing from './Pages/Realty/Listing/Listing/Listing';
import Listings from './Pages/Realty/Listing/Listings/Listings';

import AddOffer from './Pages/Realty/Offer/AddOffer/AddOffer';
import Offer from './Pages/Realty/Offer/Offer/Offer';
import Offers from './Pages/Realty/Offer/Offers/Offers';


//// Rental
import AddApplicant from './Pages/Rental/Applicant/AddApplicant/AddApplicant';
import Applicant from './Pages/Rental/Applicant/Applicant/Applicant';
import Applicants from './Pages/Rental/Applicant/Applicants/Applicants';

import AddApplicantGroup from './Pages/Rental/ApplicantGroup/AddApplicantGroup/AddApplicantGroup';
import ApplicantGroup from './Pages/Rental/ApplicantGroup/ApplicantGroup/ApplicantGroup';
import ApplicantGroups from './Pages/Rental/ApplicantGroup/AddApplicantGroup/AddApplicantGroup';

import AddApplication from './Pages/Rental/Application/AddApplication/AddApplication';
import Application from './Pages/Rental/Application/Application/Application';
import Applications from './Pages/Rental/Application/Applications/Applications';

import ApplicationTemplate from './Pages/Rental/ApplicationTemplate/ApplicationTemplate/ApplicationTemplate';
import EditApplicationTemplate from './Pages/Rental/ApplicationTemplate/EditApplicationTemplate/EditApplicationTemplate';

import CollectManagementFees from './Pages/Rental/CollectManagementFees/CollectManagementFees';

import AddInvestor from './Pages/Rental/Investor/AddInvestor/AddInvestor';
import Investor from './Pages/Rental/Investor/Investor/Investor';
import Investors from './Pages/Rental/Investor/Investors/Investors';

import AddInvestorBankAccount from './Pages/Rental/InvestorBankAccount/AddInvestorBankAccount/AddInvestorBankAccount';
import InvestorBankAccount from './Pages/Rental/InvestorBankAccount/InvestorBankAccount/InvestorBankAccount';

import AddCharge from './Pages/Rental/Lease/AddCharge/AddCharge';
import AddLease from './Pages/Rental/Lease/AddLease/AddLease';
import Lease from './Pages/Rental/Lease/Lease/Lease';
import ReceivePayment from './Pages/Rental/Lease/ReceivePayment/ReceivePayment';
import RentRoll from './Pages/Rental/Lease/RentRoll/RentRoll';

import LeaseRenewals from './Pages/Rental/LeaseRenewals/LeaseRenewals';

import TenantOutstandingBalances from './Pages/Rental/OutstandingBalances/OutstandingBalances';

import AddProperty from './Pages/Rental/Property/AddProperty/AddProperty';
import Properties from './Pages/Rental/Property/Properties/Properties';
import Property from './Pages/Rental/Property/Property/Property';

import AddRentalListing from './Pages/Rental/RentalListing/AddRentalListing/AddRentalListing';
import RentalListing from './Pages/Rental/RentalListing/RentalListing/RentalListing';
import RentalListings from './Pages/Rental/RentalListing/RentalListings/RentalListings';

import AddRentalTenant from './Pages/Rental/Tenant/AddTenant/AddTenant';
import Tenant from './Pages/Rental/Tenant/Tenant/Tenant';
import Tenants from './Pages/Rental/Tenant/Tenants/Tenants';

import AddUnit from './Pages/Rental/Unit/AddUnit/AddUnit';
import RentalUnit from './Pages/Rental/Unit/Unit/Unit';


//// Report
import Reports from './Pages/Report/Reports';


//// Error Page
import ErrorPage from './Pages/ErrorPage';


//// Navbar and Footer
import Navbar from "./Components/Navbar/Navbar";
import Footer from './Components/Footer/Footer';


// Routes
export const RoutesFile = () => {
    return (
        <BrowserRouter>
            <div className="App">
                <Navbar />
                <div className="App-content">
                    <Routes>

                        {/* Association */}
                        <Route path="/association/add" element={<AddAssociation />} />
                        <Route path="/association/:associationId" element={<Association />} />
                        <Route path="/association/associations" element={<Associations />} />

                        <Route path="/association/owners-and-tenants" element={<AssociationOwnersAndTenants />} />

                        <Route path="/association/meeting/add" element={<AddMeeting />} />
                        <Route path="/association/meeting/:meetingId" element={<Meeting />} />
                        <Route path="/association/meetings" element={<Meetings />} />

                        <Route path="/association/outstanding-balances" element={<OutstandingBalances />} />

                        <Route path="/association/owner/add" element={<AddOwner />} />
                        <Route path="/association/owner/:ownerId" element={<Owner />} />

                        <Route path="/association/ownership-account/add" element={<AddOwnershipAccount />} />
                        <Route path="/association/ownership-account/:ownershipAccountId" element={<OwnershipAccount />} />
                        <Route path="/association/ownership-accounts" element={<OwnershipAccounts />} />

                        <Route path="/association/tenant/add" element={<AddTenant />} />
                        <Route path="/association/tenant/:tenantId" element={<AssociationTenant />} />

                        <Route path="/association/unit/add" element={<AddAssociationUnit />} />
                        <Route path="/association/unit/:unitId" element={<AssociationUnit />} />

                        <Route path="/association/violation/add" element={<AddViolation />} />
                        <Route path="/association/violation/:violationId" element={<Violation />} />
                        <Route path="/association/violations" element={<Violations />} />


                        {/* Calendar */}
                        <Route path="/calendar/day" element={<Day />} />
                        <Route path="/calendar/month" element={<Month />} />
                        <Route path="/calendar/week" element={<Week />} />
                        <Route path="/calendar/year" element={<Year />} />


                        {/* Case */}
                        <Route path="/case/add" element={<AddCase />} />
                        <Route path="/case/:caseId" element={<Case />} />
                        <Route path="/case/cases" element={<Cases />} />

                        <Route path="/case/categories/add" element={<AddCaseCategories />} />
                        <Route path="/case/categories" element={<CaseCategories />} />
                        <Route path="/case/category/:categoryId" element={<CaseCategory />} />

                        <Route path="/case/dashboard" element={<CaseDashboard />} />

                        <Route path="/case/estimate/add" element={<AddEstimate />} />
                        <Route path="/case/estimate/:estimateId" element={<Estimate />} />
                        <Route path="/case/estimates" element={<Estimates />} />

                        <Route path="/case/time/add" element={<AddTime />} />
                        <Route path="/case/time/:timeId" element={<Time />} />
                        <Route path="/case/times" element={<Times />} />


                        {/* Company */}
                        <Route path="/company/equipment/add" element={<AddEquipment />} />
                        <Route path="/company/equipment/:equipmentId" element={<Equipment />} />
                        <Route path="/company/equipment" element={<AllEquipment />} />

                        <Route path="/company/equipment-service-record/add" element={<AddEquipmentServiceRecord />} />
                        <Route path="/company/equipment-service-record/:equipmentServiceRecordId" element={<EquipmentServiceRecord />} />

                        <Route path="/company/settings" element={<Settings />} />

                        <Route path="/company/user/add" element={<AddUser />} />
                        <Route path="/company/user/:userId" element={<User />} />
                        <Route path="/company/users" element={<Users />} />


                        {/* Construction */}
                        <Route path="/construction/marketplace" element={<Marketplace />} />

                        <Route path="/construction/projects" element={<Projects />} />
                        
                        <Route path="/construction/service/add" element={<AddService />} />
                        <Route path="/construction/service/:serviceId" element={<Service />} />
                        <Route path="/construction/services" element={<Services />} />

                        <Route path="/construction/service-area/add" element={<AddServiceArea />} />
                        <Route path="/construction/service-area/:serviceAreaId" element={<ServiceArea />} />

                        <Route path="/construction/vendor/add" element={<AddVendor />} />
                        <Route path="/construction/vendor/:vendorId" element={<Vendor />} />
                        <Route path="/construction/vendors/" element={<Vendors />} />

                        <Route path="/construction/vendor-staff/add" element={<AddVendorStaff />} />
                        <Route path="/construction/vendor-staff/:vendorStaffId" element={<VendorStaff />} />
                        <Route path="/construction/vendor-staff" element={<AllVendorStaff />} />


                        {/* Home/Dashboard */}
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/dashboard" element={<Dashboard />} />

                        
                        {/* Finance */}
                        <Route path="/finance/bank-account/add" element={<AddBankAccount />} />
                        <Route path="/finance/bank-account/:bankAccountId" element={<BankAccount />} />
                        <Route path="/finance/bank-accounts" element={<BankAccounts />} />

                        <Route path="/finance/budget/add" element={<AddBudget />} />
                        <Route path="/finance/budget/:budgetId" element={<Budget />} />
                        <Route path="/finance/budgets" element={<Budgets />} />

                        <Route path="/finance/chart-of-accounts/add" element={<AddChartOfAccounts />} />
                        <Route path="/finance/chart-of-accounts" element={<ChartOfAccounts />} />

                        <Route path="/finance/payable/add" element={<AddPayable />} />
                        <Route path="/finance/payable/:payableId" element={<Payable />} />
                        <Route path="/finance/payables" element={<Payables />} />

                        <Route path="/finance/product-and-service/add" element={<AddProductAndService />} />
                        <Route path="/finance/product-and-service/:productAndServiceId" element={<ProductAndService />} />
                        <Route path="/finance/product-and-services" element={<ProductAndServices />} />

                        <Route path="/finance/receivable/add" element={<AddReceivable />} />
                        <Route path="/finance/receivable/:receivableId" element={<Receivable />} />
                        <Route path="/finance/receivables" element={<Receivables />} />

                        <Route path="/finance/reconciliation/add" element={<AddReconciliation />} />
                        <Route path="/finance/reconciliation/:reconciliationId" element={<Reconciliation />} />
                        <Route path="/finance/reconciliations" element={<Reconciliations />} />


                        {/* Marketing */}
                        <Route path="/marketing/email-campaign/add" element={<AddEmailCampaign />} />
                        <Route path="/marketing/email-campaign/:emailCampaignId" element={<EmailCampaign />} />
                        <Route path="/marketing/email-campaigns" element={<EmailCampaigns />} />

                        <Route path="/marketing/lead/add" element={<AddLead />} />
                        <Route path="/marketing/lead/:leadId" element={<Lead />} />
                        <Route path="/marketing/leads" element={<Leads />} />

                        <Route path="/marketing/lead-category/add" element={<AddLeadCategory />} />
                        <Route path="/marketing/lead-categories" element={<LeadCategories />} />


                        {/* Misc */}
                        <Route path="/misc/inspection/add" element={<AddInspection />} />
                        <Route path="/misc/inspection/:inspectionId" element={<Inspection />} />
                        <Route path="/misc/inspections" element={<Inspections />} />

                        <Route path="/misc/inspection-template/edit" element={<EditInspectionTemplate />} />
                        <Route path="/misc/inspection-template" element={<InspectionTemplate />} />

                        <Route path="/misc/lockbox/add" element={<AddLockbox />} />
                        <Route path="/misc/lockbox/:lockboxId" element={<Lockbox />} />
                        <Route path="/misc/lockboxes" element={<Lockboxes />} />


                        {/* Realty */}
                        <Route path="/realty/agent/add" element={<AddAgent />} />
                        <Route path="/realty/agent/:agentId" element={<Agent />} />
                        <Route path="/realty/agents" element={<Agents />} />

                        <Route path="/realty/deal/add" element={<AddDeal />} />
                        <Route path="/realty/deal/:dealId" element={<Deal />} />
                        <Route path="/realty/deals" element={<Deals />} />

                        <Route path="/realty/listing/add" element={<AddListing />} />
                        <Route path="/realty/listing/:listingId" element={<Listing />} />
                        <Route path="/realty/listings" element={<Listings />} />

                        <Route path="/realty/offer/add" element={<AddOffer />} />
                        <Route path="/realty/offer/:offerId" element={<Offer />} />
                        <Route path="/realty/offers" element={<Offers />} />


                        {/* Rental */}
                        <Route path="/rental/applicant/add" element={<AddApplicant />} />
                        <Route path="/rental/applicant/:applicantId" element={<Applicant />} />
                        <Route path="/rental/applicants" element={<Applicants />} />

                        <Route path="/rental/applicant-group/add" element={<AddApplicantGroup />} />
                        <Route path="/rental/applicant-group/:applicantGroupId" element={<ApplicantGroup />} />
                        <Route path="/rental/applicant-groups" element={<ApplicantGroups />} />

                        <Route path="/rental/application/add" element={<AddApplication />} />
                        <Route path="/rental/application/:applicationId" element={<Application />} />
                        <Route path="/rental/applications" element={<Applications />} />

                        <Route path="/rental/application-template" element={<ApplicationTemplate />} />
                        <Route path="/rental/application-template/edit" element={<EditApplicationTemplate />} />

                        <Route path="/rental/collect-management-fees" element={<CollectManagementFees />} />

                        <Route path="/rental/investor/add" element={<AddInvestor />} />
                        <Route path="/rental/investor/:investorId" element={<Investor />} />
                        <Route path="/rental/investors" element={<Investors />} />

                        <Route path="/rental/investor-bank-account/add" element={<AddInvestorBankAccount />} />
                        <Route path="/rental/investor-bank-account/:investorBankAccountId" element={<InvestorBankAccount />} />

                        <Route path="/rental/unit/:unitId/lease/add" element={<AddLease />} />
                        <Route path="/rental/lease/:leaseId/charge/add" element={<AddCharge />} />
                        <Route path="/rental/lease/:leaseId" element={<Lease />} />
                        <Route path="/rental/lease/:leaseId/payment/receive" element={<ReceivePayment />} />
                        <Route path="/rental/rent-roll" element={<RentRoll />} />

                        <Route path="/rental/lease-renewals" element={<LeaseRenewals />} />

                        <Route path="/rental/outstanding-balances" element={<TenantOutstandingBalances />} />

                        <Route path="/rental/property/add" element={<AddProperty />} />
                        <Route path="/rental/properties" element={<Properties />} />
                        <Route path="/rental/property/:propertyId" element={<Property />} />

                        <Route path="/rental/rental-listing/add" element={<AddRentalListing />} />
                        <Route path="/rental/rental-listing/:rentalListingId" element={<RentalListing />} />
                        <Route path="/rental/rental-listings" element={<RentalListings />} />
                        
                        <Route path="/rental/tenant/add" element={<AddRentalTenant />} />
                        <Route path="/rental/tenant/:tenantId" element={<Tenant />} />
                        <Route path="/rental/tenants" element={<Tenants />} />

                        <Route path="/rental/property/:propertyId/unit/add" element={<AddUnit />} />
                        <Route path="/rental/unit/:unitId" element={<RentalUnit />} />


                        {/* Report */}
                        <Route path="/reports" element={<Reports />} />


                        {/* Error Page */}
                        <Route path="*" element={<ErrorPage />} />

                    </Routes>
                </div>
                <Footer />
            </div>
        </BrowserRouter>

    )
}