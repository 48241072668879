import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddCaseCategoriesForm from './AddCaseCategoriesForm';  // Import the form component
import API_ENDPOINTS from '../../../../APIEndPointURL';          // Import API endpoint constants
import { secureFetch } from '../../../../AuthorizeAPI';          // Import secureFetch function

const AddCaseCategories = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const payload = {
        Name: formData.Name,
        Description: formData.Description || null,
        CustomFields: formData.CustomFields ? JSON.parse(formData.CustomFields) : {},
        StatusOptions: formData.StatusOptions.split(',').map(status => status.trim())
      };

      // Make API call to add a new case category
      const response = await secureFetch('POST', API_ENDPOINTS.CaseCaseCategoryCaseCategories, JSON.stringify(payload));

      // Check if the response was successful
      if (response.statusCode === 200 || response.Id) {
        setSuccess('Case category added successfully!');
        navigate('/case/categories');  // Redirect to the list of categories
      } else {
        throw new Error('Failed to add case category');
      }
    } catch (error) {
      console.error('Error adding case category:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-case-category-page">
      <h1>Add Case Category</h1>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <AddCaseCategoriesForm onSubmit={handleSubmit} loading={loading} />
    </div>
  );
};

export default AddCaseCategories;
