import React, { useState } from "react";
import Select from 'react-select';
import './AddLeadForm.css'; // Custom CSS for styling

const AddLeadForm = ({ onSubmit, leadData = {}, loading }) => {
  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    Company: '',
    PhoneNumber: '',
    Email: '',
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    State: '',
    ZipCode: '',
    Country: 'USA',
    LeadCategoryId: '',
    AssigneeId: '',
    Notes: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData); // Pass form data to the parent component
  };

  return (
    <form onSubmit={handleSubmit} className="add-lead-form">
      <div className="form-row">
        <label>
          First Name (required):
          <input
            type="text"
            name="FirstName"
            value={formData.FirstName}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Last Name (optional):
          <input
            type="text"
            name="LastName"
            value={formData.LastName}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Company (optional):
          <input
            type="text"
            name="Company"
            value={formData.Company}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Phone Number (optional):
          <input
            type="text"
            name="PhoneNumber"
            value={formData.PhoneNumber}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Email (optional):
          <input
            type="email"
            name="Email"
            value={formData.Email}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Address Line 1 (optional):
          <input
            type="text"
            name="AddressLine1"
            value={formData.AddressLine1}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Address Line 2 (optional):
          <input
            type="text"
            name="AddressLine2"
            value={formData.AddressLine2}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          City (optional):
          <input
            type="text"
            name="City"
            value={formData.City}
            onChange={handleInputChange}
          />
        </label>
        <label>
          State (optional):
          <input
            type="text"
            name="State"
            value={formData.State}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Zip Code (optional):
          <input
            type="text"
            name="ZipCode"
            value={formData.ZipCode}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Country (optional):
          <input
            type="text"
            name="Country"
            value={formData.Country}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <label>
        Lead Category (optional):
        <Select
          name="LeadCategoryId"
          options={leadData.leadCategories?.map(category => ({ label: category.Name, value: category.Id })) || []}
          onChange={selected => setFormData({ ...formData, LeadCategoryId: selected?.value || '' })}
          isClearable
        />
      </label>

      <label>
        Assignee (optional):
        <Select
          name="AssigneeId"
          options={leadData.companyUsers?.map(assignee => ({ label: `${assignee.Name}`, value: assignee.Id })) || []}
          onChange={selected => setFormData({ ...formData, AssigneeId: selected?.value || '' })}
          isClearable
        />
      </label>

      <label>
        Notes (optional):
        <textarea
          name="Notes"
          value={formData.Notes}
          onChange={handleInputChange}
        />
      </label>

      <button type="submit" disabled={loading}>
        {loading ? 'Submitting...' : 'Add Lead'}
      </button>
    </form>
  );
};

export default AddLeadForm;
