import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

const VendorsTable = ({ vendors }) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="vendors table">
                <TableHead>
                    <TableRow>
                        <TableCell>Vendor Name</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Website</TableCell>
                        <TableCell>City, State</TableCell>
                        <TableCell>ZIP Code</TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell>Active</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {vendors.map((vendor) => {
                        const cityState = vendor.City && vendor.State ? `${vendor.City}, ${vendor.State}` : 'N/A';

                        return (
                            <TableRow key={vendor.Id}>

                                <TableCell>
                                    <Link to={`/construction/vendor/${vendor.Id}`}>
                                        {vendor.Name}
                                    </Link>
                                </TableCell>
                                <TableCell>{vendor.Category || 'N/A'}</TableCell>
                                <TableCell>{vendor.PhoneNumber || 'N/A'}</TableCell>
                                <TableCell>{vendor.Email || 'N/A'}</TableCell>
                                <TableCell>{vendor.Website || 'N/A'}</TableCell>
                                <TableCell>{cityState}</TableCell>
                                <TableCell>{vendor.ZipCode || 'N/A'}</TableCell>
                                <TableCell>{vendor.Country || 'N/A'}</TableCell>
                                <TableCell>{vendor.IsActive ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default VendorsTable;
