import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { useTheme } from "@mui/material/styles";

const FinancialSummary = ({ transactions }) => {
    const theme = useTheme(); // Access the theme
    const columns = [
        { field: 'Id', headerName: 'Transaction ID', width: 150 },
        {
            field: 'Description',
            headerName: 'Description',
            flex: 1.5,
            renderCell: (params) => params.row.Description || 'N/A',
        },
        {
            field: 'Memo',
            headerName: 'Memo',
            flex: 1,
            renderCell: (params) => params.row.Memo || 'N/A',
        },
        {
            field: 'Amount',
            headerName: 'Amount ($)',
            width: 120,
            renderCell: (params) => {
                const amount = params.row.LineItems?.[0]?.Amount;
                return amount ? `$${amount.toFixed(2)}` : '$0.00';
            },
        },
        {
            field: 'Quantity',
            headerName: 'Quantity',
            width: 120,
            renderCell: (params) => {
                const quantity = params.row.LineItems?.[0]?.Quantity;
                return quantity ? quantity : 'N/A';
            },
        },
        {
            field: 'TransactionType',
            headerName: 'Transaction Type',
            flex: 1,
            renderCell: (params) => params.row.TransactionType || 'N/A',
        },
        {
            field: 'Status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => params.row.Status || 'N/A',
        },
    ];

    return (
        <Box sx={theme.containerSizes.xlarge}>
            <h2>Financial Summary</h2>
            {transactions.length > 0 ? (
                <DataGrid
                    rows={transactions}
                    columns={columns}
                    rowHeight={40}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    getRowId={(row) => row.Id} // Ensure proper row ID
                    autoHeight
                    hideFooter // Hide the footer, including pagination and row count
                    disableColumnMenu
                />
            ) : (
                <p>No transactions available.</p>
            )}
        </Box>
    );
};

export default FinancialSummary;
