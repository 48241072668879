import React from 'react';
import { useParams } from 'react-router-dom';

const AddRentalListing = () => {
  const { AddRentalListingId } = useParams();

  return (
    <div className="AddRentalListing-container">
      <h1>AddRentalListing ID: {AddRentalListingId}</h1>
    </div>
  );
};

export default AddRentalListing;
