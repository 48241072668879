import React, { useState } from "react";
import './AddInvestorForm.css'; // Import the CSS file for styling
import { Button } from '@mui/material'; // Import Material UI components

const AddInvestorForm = ({ onSubmit, loading }) => {
  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    IsCompany: false,
    CompanyName: '',
    PhoneNumber: '',
    Email: '',
    AgreementStartDate: '',
    AgreementEndDate: '',
    TIN: '',
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    State: '',
    ZipCode: '',
    Country: '',
    RealtorId: '',
    AccountantId: '',
    AttorneyId: '',
    DateOfBirth: '',
    Notes: ''
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData); // Call the onSubmit function passed from AddInvestor.js
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        First Name (required):
        <input
          type="text"
          name="FirstName"
          value={formData.FirstName}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Last Name (required):
        <input
          type="text"
          name="LastName"
          value={formData.LastName}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Is Company:
        <input
          type="checkbox"
          name="IsCompany"
          checked={formData.IsCompany}
          onChange={handleInputChange}
        />
      </label>

      {formData.IsCompany && (
        <label>
          Company Name:
          <input
            type="text"
            name="CompanyName"
            value={formData.CompanyName}
            onChange={handleInputChange}
          />
        </label>
      )}

      <label>
        Phone Number (optional):
        <input
          type="text"
          name="PhoneNumber"
          value={formData.PhoneNumber}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Email (optional):
        <input
          type="email"
          name="Email"
          value={formData.Email}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Agreement Start Date (optional):
        <input
          type="date"
          name="AgreementStartDate"
          value={formData.AgreementStartDate}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Agreement End Date (optional):
        <input
          type="date"
          name="AgreementEndDate"
          value={formData.AgreementEndDate}
          onChange={handleInputChange}
        />
      </label>

      <label>
        TIN (optional):
        <input
          type="text"
          name="TIN"
          value={formData.TIN}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Address Line 1 (required):
        <input
          type="text"
          name="AddressLine1"
          value={formData.AddressLine1}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Address Line 2 (optional):
        <input
          type="text"
          name="AddressLine2"
          value={formData.AddressLine2}
          onChange={handleInputChange}
        />
      </label>

      <label>
        City (required):
        <input
          type="text"
          name="City"
          value={formData.City}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        State (required):
        <input
          type="text"
          name="State"
          value={formData.State}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Zip Code (required):
        <input
          type="text"
          name="ZipCode"
          value={formData.ZipCode}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Country (required):
        <input
          type="text"
          name="Country"
          value={formData.Country}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Date of Birth (optional):
        <input
          type="date"
          name="DateOfBirth"
          value={formData.DateOfBirth}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Notes (optional):
        <textarea
          name="Notes"
          value={formData.Notes}
          onChange={handleInputChange}
        />
      </label>

      <Button variant="contained" color="primary" size='large' type="submit" disabled={loading}>
        {loading ? 'Submitting...' : 'Add Investor'}
      </Button>
    </form>
  );
};

export default AddInvestorForm;
