import React from 'react';
import { useParams } from 'react-router-dom';

const Applicants = () => {
  const { ApplicantsId } = useParams();

  return (
    <div className="Applicants-container">
      <h1>Applicants ID: {ApplicantsId}</h1>
    </div>
  );
};

export default Applicants;
