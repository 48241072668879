import React, { useState } from 'react';
import './AddVendorForm.css'; // Optional CSS for styling
import { Button } from '@mui/material'; // Import Material UI components

const AddVendorForm = ({ onSubmit, loading }) => {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    PhoneNumber: '',
    Category: '',
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    State: '',
    ZipCode: '',
    Country: 'USA',
    Website: '',
    Notes: '',
    TIN: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="add-vendor-form">
      <div className="form-row">
        <label>
          Vendor Name (required):
          <input
            type="text"
            name="Name"
            value={formData.Name}
            onChange={handleInputChange}
            required
          />
        </label>

        <label>
          Email (optional):
          <input
            type="email"
            name="Email"
            value={formData.Email}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Phone Number (optional):
          <input
            type="text"
            name="PhoneNumber"
            value={formData.PhoneNumber}
            onChange={handleInputChange}
          />
        </label>

        <label>
          Website (optional):
          <input
            type="url"
            name="Website"
            value={formData.Website}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Category (optional):
          <input
            type="text"
            name="Category"
            value={formData.Category}
            onChange={handleInputChange}
          />
        </label>

        <label>
          TIN (optional):
          <input
            type="text"
            name="TIN"
            value={formData.TIN}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Address Line 1 (optional):
          <input
            type="text"
            name="AddressLine1"
            value={formData.AddressLine1}
            onChange={handleInputChange}
          />
        </label>

        <label>
          Address Line 2 (optional):
          <input
            type="text"
            name="AddressLine2"
            value={formData.AddressLine2}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          City (optional):
          <input
            type="text"
            name="City"
            value={formData.City}
            onChange={handleInputChange}
          />
        </label>

        <label>
          State (optional):
          <input
            type="text"
            name="State"
            value={formData.State}
            onChange={handleInputChange}
          />
        </label>

        <label>
          Zip Code (optional):
          <input
            type="text"
            name="ZipCode"
            value={formData.ZipCode}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Country (optional):
          <input
            type="text"
            name="Country"
            value={formData.Country}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <label>
        Notes (optional):
        <textarea
          name="Notes"
          value={formData.Notes}
          onChange={handleInputChange}
        />
      </label>

      <Button variant="contained" color="primary" size='large' type="submit" disabled={loading}>
        {loading ? 'Submitting...' : 'Add Vendor'}
      </Button>
    </form>
  );
};

export default AddVendorForm;
