import React from 'react';
import { useParams } from 'react-router-dom';

const AddPayable = () => {
  const { AddPayableId } = useParams();

  return (
    <div className="AddPayable-container">
      <h1>AddPayable ID: {AddPayableId}</h1>
    </div>
  );
};

export default AddPayable;
