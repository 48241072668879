import React, { useState, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, InputLabel, Box } from "@mui/material";
import "./PropertyTable.css"; // Import the CSS file
import { useTheme } from "@mui/material/styles"; // Import useTheme

const PropertyTable = ({ properties }) => {
  const [filterText, setFilterText] = useState("");
  const [sortModel, setSortModel] = useState([
    { field: "AddressLine1", sort: "asc" },
  ]);
  const theme = useTheme(); // Access the theme

  // Remove duplicate properties by checking unique Ids
  const uniqueProperties = useMemo(() => {
    const seen = new Set();
    return properties.filter((property) => {
      if (seen.has(property.Id)) {
        return false;
      }
      seen.add(property.Id);
      return true;
    });
  }, [properties]);

  // Filter the properties across all relevant fields
  const filteredProperties = useMemo(() => {
    const lowerCaseFilter = filterText.toLowerCase();
    return uniqueProperties.filter((property) => {
      // Convert all relevant property fields to strings and check for matching substring
      return Object.keys(property).some((key) =>
        String(property[key]).toLowerCase().includes(lowerCaseFilter)
      );
    });
  }, [filterText, uniqueProperties]);

  // Event handler for sorting changes
  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  // Prepare columns for DataGrid using 'flex' for relative widths
  const columns = [
    {
      field: "AddressLine1",
      headerName: "Address",
      flex: 2, // Flex ratio for relative width
      renderCell: (params) => (
        <a
          href={`/rental/property/${params?.row?.Id}`}
          style={{
            color: "#007bff", // Blue color for the link
            textDecoration: "none", // Remove underline
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.textDecoration = "underline")
          }
          onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
        >
          {params?.row?.AddressLine1 || "N/A"}
        </a>
      ),
    },
    {
      field: "CityStateZip",
      headerName: "City, State",
      flex: 1.5, // Relative width smaller than the address field
      renderCell: (params) => {
        const city = params.row?.City || "N/A";
        const state = params.row?.State || "N/A";
        return `${city}, ${state}`;
      },
    },
    { field: "RentalType", headerName: "Type", flex: 1 }, // Relative width
    { field: "InvestorName", headerName: "Investor", flex: 1 },
    { field: "BankAccountName", headerName: "Bank Account", flex: 1 },
  ];

  return (
    <div>
      <Box sx={theme.containerSizes.xlarge}>
        {" "}
        <InputLabel htmlFor="filter-properties" className="search-label" />
        <TextField
          id="filter-properties"
          variant="outlined"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          fullWidth
          placeholder="Type to search..."
          className="search-bar"
        />
        <DataGrid
          rows={filteredProperties} // Only the filtered and unique rows are passed
          columns={columns}
          rowHeight={40}
          sortingOrder={["asc", "desc"]}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          disableColumnFilter={false}
          disableColumnMenu={false}
          getRowId={(row) => row?.Id} // Use the 'Id' field directly to identify unique rows
          autoHeight // Automatically adjust height to fit all rows
          hideFooter // Hide the footer, including pagination and row count
        />
      </Box>
    </div>
  );
};

export default PropertyTable;
