export const MuiButton = {
    styleOverrides: {
        // Applies to all buttons
        root: {
            borderRadius: 6, // Standardize border radius for all buttons
            textTransform: "none", // Disable uppercase by default
        },
        // Button Size
        sizeSmall: {
            padding: "4px 12px", // Adjust padding for small buttons
            fontSize: "1rem", // Smaller font size for small buttons
            width: "5vw", // Allow small buttons to grow
        },
        sizeLarge: {
            padding: "8px 16px", // Larger padding for large buttons
            fontSize: "1rem", // Larger font size for large buttons
            width: "8vw", // Allow small buttons to grow
        },
        // Filled Background
        containedPrimary: {
            backgroundColor: "#008ffd", // Primary button background color
            color: "#ffffff", // White text color for primary buttons
            "&:hover": {
                backgroundColor: "#0481d1", // Darker blue on hover
            },
        },
        containedSecondary: {
            backgroundColor: "#13e697", // Secondary button background color
            color: "#ffffff", // White text color for secondary buttons
            "&:hover": {
                backgroundColor: "#1cd18d",
            },
        },
        // Transparent Background
        outlinedPrimary: {
            borderColor: "#008ffd", // Outline color for primary buttons
            color: "#008ffd", // Text color for outlined primary buttons
            "&:hover": {
                backgroundColor: "rgba(25, 118, 210, 0.1)", // Light blue background on hover
            },
        },
        outlinedSecondary: {
            borderColor: "#13e697", // Outline color for secondary buttons
            color: "#13e697", // Text color for outlined secondary buttons
            "&:hover": {
                backgroundColor: "rgba(35, 173, 121, 0.1)", // Light pink background on hover
            },
        },
    },
};
