import { createTheme } from "@mui/material/styles";

// Import individual component overrides
import { MuiButton } from './components/MuiButton';
import { MuiDataGrid } from './components/MuiDataGrid';
import { MuiInputLabel } from './components/MuiInputLabel';
import { MuiModal } from './components/MuiModal';
import { MuiPaper } from './components/MuiPaper';
import { MuiTable, MuiTableCell, MuiTableRow } from './components/MuiTable';
import { MuiTabs, MuiTab } from './components/MuiTabs';
import { MuiTextField } from './components/MuiTextField';

// Import Styles
import { breakpoints } from './styles/breakpoints';
import { containerSizes } from './styles/containerSizes';
import { palette } from './styles/palette';
import { shadows } from './styles/shadows';
import { shape } from './styles/shape';
import { typography } from './styles/typography';


const theme = createTheme({
  components: {
    MuiButton,
    MuiDataGrid,
    MuiInputLabel,
    MuiModal,
    MuiPaper,
    MuiTable,
    MuiTableCell,
    MuiTableRow,
    MuiTabs,
    MuiTab,
    MuiTextField,
  },
  breakpoints,
  containerSizes,
  palette,
  shadows,
  shape,
  typography,
});

export default theme;
