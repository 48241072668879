import React from 'react';
import { useParams } from 'react-router-dom';

const Times = () => {
  const { TimesId } = useParams();

  return (
    <div className="Times-container">
      <h1>Times ID: {TimesId}</h1>
    </div>
  );
};

export default Times;
