export const MuiTextField = {
    styleOverrides: {
        root: {
            backgroundColor: "#ffffff", // Background color of the text field
            borderRadius: "8px", // Rounded corners
            marginBottom: "10px", // Standard bottom margin of 10px
            width: "25vw", // Set width to 15% of the viewport width
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderColor: "#cccccc", // Default border color
                },
                "&:hover fieldset": {
                    borderColor: "#007bff", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                    borderColor: "#007bff", // Border color when focused
                    borderWidth: "2px", // Increase border width when focused
                },
                "&.Mui-disabled fieldset": {
                    borderColor: "#e0e0e0", // Border color when disabled
                },
            },
            "& input": {
                padding: "12px 14px", // Padding inside the input field
                fontSize: "1rem", // Font size of the input text
            },
            "& .MuiInputAdornment-root": {
                color: "#555555", // Adornment color (like icons in the text field)
            },
        },
    },
};
