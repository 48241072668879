import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

const OwnershipAccountTable = ({ ownershipAccounts }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="ownership account table">
        <TableHead>
          <TableRow>
            <TableCell>Owner Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Date of Purchase</TableCell>
            <TableCell>Association Fee</TableCell>
            <TableCell>Recurring Frequency</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ownershipAccounts.map((account) => {
            const ownerName = account.FirstName && account.LastName
              ? `${account.FirstName} ${account.LastName}`
              : "N/A";

            const address = account.AddressLine1 && account.AddressLine2
              ? `${account.AddressLine1} - ${account.AddressLine2}`
              : account.AddressLine1 || account.AddressLine2 || "N/A";

            return (
              <TableRow key={account.Id}>
                <TableCell>
                  <Link to={`/association/ownership-account/${account.Id}`}>
                    {ownerName}
                  </Link>
                </TableCell>
                <TableCell>{address}</TableCell>
                <TableCell>
                  {account.DateOfPurchase
                    ? new Date(account.DateOfPurchase).toLocaleDateString()
                    : "N/A"}
                </TableCell>
                <TableCell>{account.AssociationFee ? `$${account.AssociationFee}` : "N/A"}</TableCell>
                <TableCell>{account.RecurringFrequency ? account.RecurringFrequency : "N/A"}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OwnershipAccountTable;
