import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Oops! Something Went Wrong</h1>
      <p style={styles.message}>
        It seems like we've taken a wrong turn. The page you're looking for is
        either lost or doesn't exist anymore.
      </p>
      <div style={styles.emoji}>🔍🛠️</div>
      <p style={styles.suggestion}>
        But don't worry! You can either try refreshing, or head back to the{' '}
        <Link to="/" style={styles.link}>
          homepage
        </Link>{' '}
        to get back on track.
      </p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    padding: '50px',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '36px',
    color: '#FF6B6B',
  },
  message: {
    fontSize: '18px',
    color: '#333',
  },
  emoji: {
    fontSize: '48px',
    margin: '20px 0',
  },
  suggestion: {
    fontSize: '16px',
    color: '#666',
  },
  link: {
    color: '#007BFF',
    textDecoration: 'none',
  },
};

export default ErrorPage;
