import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import LeadCategoriesTable from './LeadCategoriesTable';
import API_ENDPOINTS from '../../../../APIEndPointURL';
import { secureFetch } from '../../../../AuthorizeAPI';
import './LeadCategories.css'; // Optional CSS file for styling
import { Button } from '@mui/material'; // Import Material UI components

const LeadCategories = () => {
  const [leadCategories, setLeadCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate hook

  useEffect(() => {
    const fetchLeadCategories = async () => {
      try {
        // Fetch lead categories from the API using secureFetch
        const response = await secureFetch('GET', API_ENDPOINTS.MarketingLeadCategoryLeadCategory);
        const parsedData = Array.isArray(response) ? response : JSON.parse(response.body);

        // Ensure parsed data is an array and set it to state
        if (Array.isArray(parsedData)) {
          setLeadCategories(parsedData);
        } else {
          console.error('Unexpected response format:', parsedData);
          setError('Unexpected response format');
        }
      } catch (err) {
        console.error('Error fetching lead categories:', err);
        setError('Failed to load lead categories data.');
      } finally {
        setLoading(false);
      }
    };

    fetchLeadCategories();
  }, []);

  return (
    <div className="lead-categories-container">
      <div className="lead-categories-header">
        <h1>Lead Categories</h1>
        {/* Add Lead Category Button */}
        <Button variant="contained" color="primary" size='large' onClick={() => navigate('/marketing/lead-category/add')}>
          Add Lead Category
        </Button>
      </div>

      {loading ? (
        <p>Loading lead categories...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <LeadCategoriesTable leadCategories={leadCategories} />
      )}
    </div>
  );
};

export default LeadCategories;
