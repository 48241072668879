import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Helper function to format date
const formatDate = (date) => {
  return new Date(date).toLocaleDateString();
};

const LeaseTenants = ({ tenants }) => {
  const theme = useTheme(); // Access the theme for consistent styling

  return (
    <Box sx={theme.containerSizes.large}>
      <h3>Tenants</h3>
      {tenants && tenants.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tenants.map((tenant) => (
              <TableRow key={tenant.Id}>
                <TableCell>{tenant.FirstName}</TableCell>
                <TableCell>{tenant.LastName}</TableCell>
                <TableCell>{tenant.Email}</TableCell>
                <TableCell>{tenant.PhoneNumber}</TableCell>
                <TableCell>{formatDate(tenant.DateOfBirth)}</TableCell>
                <TableCell>{tenant.Notes || 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p>No tenants found for this lease.</p>
      )}
    </Box>
  );
};

export default LeaseTenants;
