import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import LeadsTable from "./LeadsTable";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";
import './Leads.css';
import { Button } from '@mui/material'; // Import Material UI components

const Leads = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate hook

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        // Fetch the leads data using secureFetch
        const response = await secureFetch('GET', API_ENDPOINTS.MarketingLeadLeads);
        const parsedData = Array.isArray(response) ? response : JSON.parse(response.body);

        // Check if the parsed data is an array and set it in the state
        if (Array.isArray(parsedData)) {
          setLeads(parsedData);
        } else {
          console.error("Unexpected response format:", parsedData);
        }
      } catch (err) {
        console.error("Error fetching leads:", err);
        setError("Failed to load leads data.");
      } finally {
        setLoading(false);
      }
    };

    fetchLeads();
  }, []);

  return (
    <div className="leads-container">
      <div className="leads-header">
        <h1>Leads</h1>
        <div className="button-group">
          {/* Add Lead Button */}
          <Button variant="contained" color="primary" size='large' onClick={() => navigate('/marketing/lead/add')}>
            Add Lead
          </Button>

          {/* Lead Categories Button */}
          <Button variant="contained" color="primary" size='large' onClick={() => navigate('/marketing/lead-categories')}>
            Lead Categories
          </Button>
        </div>
      </div>

      {loading ? (
        <p>Loading leads data...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <LeadsTable leads={leads} />
      )}
    </div>
  );
};

export default Leads;
