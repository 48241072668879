import React from 'react';
import { useParams } from 'react-router-dom';

const Listing = () => {
  const { ListingId } = useParams();

  return (
    <div className="Listing-container">
      <h1>Listing ID: {ListingId}</h1>
    </div>
  );
};

export default Listing;
