import React, { useState } from "react";
import './AddLeadCategoryForm.css'; // Optional CSS file for styling
import { Button } from '@mui/material'; // Import Material UI components

const AddLeadCategoryForm = ({ onSubmit, loading }) => {
  const [formData, setFormData] = useState({
    Name: '',
    Description: '',
    CustomFields: {},
    StatusOptions: []
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCustomFieldsChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      CustomFields: { ...formData.CustomFields, [name]: value }
    });
  };

  const handleStatusOptionsChange = (e) => {
    const { value } = e.target;
    const statusOptions = value.split(',').map(option => option.trim());
    setFormData({
      ...formData,
      StatusOptions: statusOptions
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData); // Pass the form data to the parent component
  };

  return (
    <form onSubmit={handleSubmit} className="add-lead-category-form">
      <div className="form-row">
        <label>
          Name (required):
          <input
            type="text"
            name="Name"
            value={formData.Name}
            onChange={handleInputChange}
            required
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Description (optional):
          <textarea
            name="Description"
            value={formData.Description}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Custom Fields (optional):
          <input
            type="text"
            name="CustomField1"
            placeholder="Enter custom field"
            value={formData.CustomFields.CustomField1 || ''}
            onChange={handleCustomFieldsChange}
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Status Options (comma separated):
          <input
            type="text"
            name="StatusOptions"
            placeholder="Enter status options (e.g., New, Contacted, Closed)"
            value={formData.StatusOptions.join(', ')}
            onChange={handleStatusOptionsChange}
          />
        </label>
      </div>

      <Button variant="contained" color="primary" size='large' type="submit" disabled={loading}>
        {loading ? 'Submitting...' : 'Add Lead Category'}
      </Button>

    </form>
  );
};

export default AddLeadCategoryForm;
