import React, { useEffect, useState } from "react";
import InvestorTable from "./InvestorTable";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";
import { useNavigate } from "react-router-dom"; // Import navigate for navigation
import './Investors.css'; // Import the CSS file for styling
import { Button } from '@mui/material'; // Import Material UI components

const Investors = () => {
  const [investors, setInvestors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate for routing

  useEffect(() => {
    const fetchInvestors = async () => {
      try {
        const response = await secureFetch('GET', API_ENDPOINTS.RentalInvestorInvestors);

        // Ensure that the response body is valid and handle it accordingly
        const parsedData = response.body ? JSON.parse(response.body) : response;

        if (Array.isArray(parsedData)) {
          setInvestors(parsedData);
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (err) {
        console.error("Error fetching investors:", err);
        setError("Failed to load investors data.");
      } finally {
        setLoading(false);
      }
    };

    fetchInvestors();
  }, []);

  return (
    <div className="investors-page">
      <div className="header-row">
        <h1>Investors</h1>
        <Button variant="contained" color="primary" size='large' onClick={() => navigate('/rental/investor/add')}          >
          Add Investor
        </Button>
      </div>

      {loading ? (
        <p>Loading investors data...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <InvestorTable investors={investors} />
      )}
    </div>
  );
};

export default Investors;
