import React from 'react';
import { useParams } from 'react-router-dom';

const AddApplication = () => {
  const { AddApplicationId } = useParams();

  return (
    <div className="AddApplication-container">
      <h1>AddApplication ID: {AddApplicationId}</h1>
    </div>
  );
};

export default AddApplication;
