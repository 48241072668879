import React from 'react';
import { useParams } from 'react-router-dom';

const Month = () => {
  const { MonthId } = useParams();

  return (
    <div className="Month-container">
      <h1>Month ID: {MonthId}</h1>
    </div>
  );
};

export default Month;
