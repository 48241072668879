import React from 'react';
import { useParams } from 'react-router-dom';
import './Association.css';

const Association = () => {
  const { associationId } = useParams();

  return (
    <div className="Association-container">
      <h1>Association ID: {associationId}</h1>
    </div>
  );
};

export default Association;
