import React from 'react';
import { useParams } from 'react-router-dom';

const RentalListings = () => {
  const { RentalListingsId } = useParams();

  return (
    <div className="RentalListings-container">
      <h1>RentalListings ID: {RentalListingsId}</h1>
    </div>
  );
};

export default RentalListings;
