import React from 'react';
import { useParams } from 'react-router-dom';

const RentalListing = () => {
  const { RentalListingId } = useParams();

  return (
    <div className="RentalListing-container">
      <h1>RentalListing ID: {RentalListingId}</h1>
    </div>
  );
};

export default RentalListing;
