import React from 'react';
import { useParams } from 'react-router-dom';
import './BankAccount.css';

const BankAccount = () => {
  const { bankAccountId } = useParams();

  return (
    <div className="BankAccount-container">
      <h1>BankAccount ID: {bankAccountId}</h1>
    </div>
  );
};

export default BankAccount;
