import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const OwnersAndTenantsTable = ({ ownersAndTenants }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="owners and tenants table">
        <TableHead>
          <TableRow>
            <TableCell>Owner/Tenant Name</TableCell>
            <TableCell>Association Name - Unit Address</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Association Fee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ownersAndTenants.map((item) => {
            const ownerTenantName = item.FirstName && item.LastName
              ? `${item.FirstName} ${item.LastName}`
              : "N/A";

            const associationAndAddress = item.Name && item.AddressLine2
              ? `${item.Name} - ${item.AddressLine2}`
              : "N/A";

            return (
              <TableRow key={item.Id}>
                <TableCell>{ownerTenantName}</TableCell>
                <TableCell>{associationAndAddress}</TableCell>
                <TableCell>{item.Phone ? item.Phone : "N/A"}</TableCell>
                <TableCell>{item.Email ? item.Email : "N/A"}</TableCell>
                <TableCell>{item.AssociationFee ? `$${item.AssociationFee}` : "N/A"}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OwnersAndTenantsTable;
