import React from 'react';
import { useParams } from 'react-router-dom';

const Services = () => {
  const { ServicesId } = useParams();

  return (
    <div className="Services-container">
      <h1>Services ID: {ServicesId}</h1>
    </div>
  );
};

export default Services;
