import React from 'react';
import { useParams } from 'react-router-dom';

const InvestorBankAccount = () => {
  const { InvestorBankAccountId } = useParams();

  return (
    <div className="InvestorBankAccount-container">
      <h1>InvestorBankAccount ID: {InvestorBankAccountId}</h1>
    </div>
  );
};

export default InvestorBankAccount;
