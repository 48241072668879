import React from 'react';
import { useParams } from 'react-router-dom';

const AddDeal = () => {
  const { AddDealId } = useParams();

  return (
    <div className="AddDeal-container">
      <h1>AddDeal ID: {AddDealId}</h1>
    </div>
  );
};

export default AddDeal;
