import React from 'react';
import { useParams } from 'react-router-dom';

const Offers = () => {
  const { OffersId } = useParams();

  return (
    <div className="Offers-container">
      <h1>Offers ID: {OffersId}</h1>
    </div>
  );
};

export default Offers;
