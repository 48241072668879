import React from 'react';
import { useParams } from 'react-router-dom';

const AddApplicantGroup = () => {
  const { AddApplicantGroupId } = useParams();

  return (
    <div className="AddApplicantGroup-container">
      <h1>AddApplicantGroup ID: {AddApplicantGroupId}</h1>
    </div>
  );
};

export default AddApplicantGroup;
