import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { useTheme } from "@mui/material/styles";

const Time = ({ employeeTime = [], totalHoursWorked = 0 }) => {
    const theme = useTheme(); // Access the theme

    // Define columns for the DataGrid
    const columns = [
        {
            field: 'employeeName',
            headerName: 'Employee Name',
            flex: 2,
            renderCell: (params) => params.row?.employeeName || 'N/A',
        }, 
        {
            field: 'StartDateTime',
            headerName: 'Start Time',
            flex: 2,
            renderCell: (params) => {
                const startDate = params.row?.StartDateTime;
                return startDate ? new Date(startDate).toLocaleString() : 'N/A';
            },
        },
        {
            field: 'EndDateTime',
            headerName: 'End Time',
            flex: 2,
            renderCell: (params) => {
                const endDate = params.row?.EndDateTime;
                return endDate ? new Date(endDate).toLocaleString() : 'N/A';
            },
        },
        {
            field: 'IsBillable',
            headerName: 'Is Billable',
            flex: 1,
            renderCell: (params) => (params.row?.IsBillable ? 'Yes' : 'No'),
        },
        {
            field: 'hoursWorked',
            headerName: 'Hours Worked',
            flex: 1,
            renderCell: (params) => {
                const startTime = params.row?.StartDateTime ? new Date(params.row.StartDateTime) : null;
                const endTime = params.row?.EndDateTime ? new Date(params.row.EndDateTime) : null;

                if (startTime && endTime) {
                    const hours = (endTime - startTime) / (1000 * 60 * 60); // Convert milliseconds to hours
                    return hours.toFixed(2); // Display hours with two decimal places
                }
                return 'N/A';
            },
        },
    ];

    return (
        <div>
            <Box sx={theme.containerSizes.xlarge}>
                <h2>Time Summary</h2>
                {/* DataGrid to display employee time records */}
                <DataGrid
                    rows={employeeTime}
                    columns={columns}
                    rowHeight={40}
                    hideFooter // Hide the footer, including pagination and row count
                    autoHeight
                    pageSize={5}
                    rowsPerPageOptions={[5, 10]}
                    getRowId={(row) => row.Id}
                    disableSelectionOnClick
                />
            </Box>
        </div>
    );
};

export default Time;
