import React from 'react';
import { useParams } from 'react-router-dom';

const VendorStaff = () => {
  const { VendorStaffId } = useParams();

  return (
    <div className="VendorStaff-container">
      <h1>VendorStaff ID: {VendorStaffId}</h1>
    </div>
  );
};

export default VendorStaff;
