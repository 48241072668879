import React from 'react';
import { useParams } from 'react-router-dom';

const Reconciliations = () => {
  const { ReconciliationsId } = useParams();

  return (
    <div className="Reconciliations-container">
      <h1>Reconciliations ID: {ReconciliationsId}</h1>
    </div>
  );
};

export default Reconciliations;
