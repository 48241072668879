import React from 'react';
import { useParams } from 'react-router-dom';

const Inspection = () => {
  const { InspectionId } = useParams();

  return (
    <div className="Inspection-container">
      <h1>Inspection ID: {InspectionId}</h1>
    </div>
  );
};

export default Inspection;
