import React, { useEffect, useState } from "react";
import TenantTable from "./TenantTable";
import API_ENDPOINTS from "../../../../APIEndPointURL";
import { secureFetch } from "../../../../AuthorizeAPI";
import './Tenants.css'; // Import the CSS file for styling

const Tenants = () => {
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        // No need to parse 'response.body', use response directly
        const response = await secureFetch('GET', API_ENDPOINTS.RentalTenantTenants);
        
        console.log('API Response:', response); // Directly logs the array
        
        if (Array.isArray(response)) {
          setTenants(response); // Update tenants if it's an array
        } else {
          console.error("Unexpected response format:", response);
          setError("Invalid tenant data format.");
        }
      } catch (err) {
        console.error("Error fetching tenants:", err);
        setError("Failed to load tenant data.");
      } finally {
        setLoading(false);
      }
    };

    fetchTenants();
  }, []);

  return (
    <div className="tenants-page">
      <h1>Tenants</h1>

      {loading ? (
        <p>Loading tenant data...</p>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <TenantTable tenants={tenants} />
      )}
    </div>
  );
};

export default Tenants;
