import React, { useState } from 'react';
import Select from 'react-select';  // For dropdowns
import './AddLeaseForm.css';
import { Button } from '@mui/material'; // Import Material UI components

const AddLeaseForm = ({ leaseData, handleSubmit }) => {
  const [formData, setFormData] = useState({
    tenantId: '',
    startDate: '',
    endDate: '',
    rentAmount: '',
    leaseTerm: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (name, option) => {
    setFormData({
      ...formData,
      [name]: option.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);  // Pass the form data back to AddLease.js
  };

  return (
    <form onSubmit={onSubmit} className="AddLeaseForm">
      <div className="form-row">
        <label>
          Tenant:
          <Select
            name="tenantId"
            options={leaseData.tenants.map(tenant => ({
              value: tenant.Id,
              label: `${tenant.FirstName} ${tenant.LastName}`,
            }))}
            onChange={(option) => handleSelectChange('tenantId', option)}
            required
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Lease Term:
          <Select
            name="leaseTerm"
            options={leaseData.leaseTerms.map(term => ({
              value: term.Id,
              label: `${term.Description} - ${term.Duration}`,
            }))}
            onChange={(option) => handleSelectChange('leaseTerm', option)}
            required
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Rent Amount:
          <input
            type="number"
            name="rentAmount"
            value={formData.rentAmount}
            onChange={handleInputChange}
            required
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          Start Date:
          <input
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleInputChange}
            required
          />
        </label>
      </div>

      <div className="form-row">
        <label>
          End Date:
          <input
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleInputChange}
            required
          />
        </label>
      </div>

      <Button variant="contained" color="primary" size='large' type="submit">
        Add Lease
      </Button>
    </form>
  );
};

export default AddLeaseForm;
