// src/Pages/Rental/Unit/UnitLeases.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import Box from '@mui/material/Box'; // Import MUI Box for containers
import Table from '@mui/material/Table'; // Import MUI Table components
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const UnitLeases = ({ leases }) => {
  const theme = useTheme(); // Access the theme

  return (
    <Box sx={theme.containerSizes.medium}>
      <h3>Unit Leases</h3>
      {leases.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Lease Start Date</TableCell>
              <TableCell>Lease End Date</TableCell>
              <TableCell>Tenants</TableCell>
              <TableCell>Lease Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leases.map((lease) => (
              <TableRow key={lease.Id}>
                {/* Link Lease Start Date */}
                <TableCell>
                  <Link to={`/rental/lease/${lease.Id}`}>
                    {new Date(lease.LeaseStartDate).toLocaleDateString()}
                  </Link>
                </TableCell>

                {/* Link Lease End Date */}
                <TableCell>
                  <Link to={`/rental/lease/${lease.Id}`}>
                    {new Date(lease.LeaseEndDate).toLocaleDateString()}
                  </Link>
                </TableCell>

                {/* Display tenants and link each tenant's name */}
                <TableCell>
                  {lease.tenants.length > 0 ? (
                    lease.tenants.map((tenant, index) => (
                      <div key={tenant.Id}>
                        <Link to={`/rental/tenant/${tenant.Id}`}>
                          {tenant.FirstName} {tenant.LastName}
                        </Link>
                        {index < lease.tenants.length - 1 && ', '}
                      </div>
                    ))
                  ) : (
                    <span>No tenants</span>
                  )}
                </TableCell>

                {/* Lease Type */}
                <TableCell>{lease.LeaseType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p>No leases available for this unit.</p>
      )}
    </Box>
  );
};

export default UnitLeases;
