import React from 'react';
import { useParams } from 'react-router-dom';

const ApplicantGroup = () => {
  const { ApplicantGroupId } = useParams();

  return (
    <div className="ApplicantGroup-container">
      <h1>ApplicantGroup ID: {ApplicantGroupId}</h1>
    </div>
  );
};

export default ApplicantGroup;
