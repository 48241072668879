import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

const ReceivablesTable = ({ receivables }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="receivables table">
        <TableHead>
          <TableRow>
            <TableCell>Due Date</TableCell>
            <TableCell>Owner Name</TableCell>
            <TableCell>Case Title</TableCell>
            <TableCell>Total Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {receivables.map((receivable) => (
            <TableRow key={receivable.Id}>
              <TableCell>
                {receivable.DueDate
                  ? new Date(receivable.DueDate).toLocaleDateString()
                  : "N/A"}
              </TableCell>
              <TableCell>{receivable.ownername ? receivable.ownername : "N/A"}</TableCell>
              <TableCell>{receivable.casetitle ? receivable.casetitle : "N/A"}</TableCell>
              <TableCell>
                <Link to={`/finance/receivable/${receivable.Id}`}>
                  {receivable.totalamount ? `$${receivable.totalamount}` : "$0.00"}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReceivablesTable;
