import React from 'react';
import { useParams } from 'react-router-dom';
import './Violation.css';

const Violation = () => {
  const { violationId } = useParams();

  return (
    <div className="Violation-container">
      <h1>Violation ID: {violationId}</h1>
    </div>
  );
};

export default Violation;
