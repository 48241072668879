import React from 'react';
import { useParams } from 'react-router-dom';

const AllVendorStaff = () => {
  const { AllVendorStaffId } = useParams();

  return (
    <div className="AllVendorStaff-container">
      <h1>AllVendorStaff ID: {AllVendorStaffId}</h1>
    </div>
  );
};

export default AllVendorStaff;
