import React from 'react';
import { useParams } from 'react-router-dom';

const AddCharge = () => {
  const { leaseId } = useParams();

  return (
    <div className="AddCharge-container">
      <h1>Add Charge for Lease ID: {leaseId}</h1>
    </div>
  );
};

export default AddCharge;
