import React from 'react';
import { useParams } from 'react-router-dom';

const ProductAndServices = () => {
  const { ProductAndServicesId } = useParams();

  return (
    <div className="ProductAndServices-container">
      <h1>ProductAndServices ID: {ProductAndServicesId}</h1>
    </div>
  );
};

export default ProductAndServices;
